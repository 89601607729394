import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

export const StyledAppBar = styled(AppBar)`
  height: 5rem;
  top: auto;
  bottom: 0;
  background: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
`;

export const InnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  max-width: 65rem;
  padding: 0 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    max-width: 50rem;
    padding: 0 3rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    max-width: inherit;
    padding: 0 1rem;
  }
`;

export const PaymentInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    flex-direction: column;
    gap: 0;
    font-size: 0.8rem;
  }
`;

export const PaymentSumWrapper = styled.div`
  font-size: 2rem;

  
  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    font-size: 1.5rem;
  }
`;

export const ConfirmQuoteButton = styled.button`
  width: 12rem;
  padding: 0.5rem 0;  
  background: ${({ theme }) => theme.color.lightOrange};
  color: ${({ theme }) => theme.color.black};
  text-align: center;
  border-radius: 0.5rem;
  cursor: pointer;
  font: inherit;
  border: 0;

  :hover {
    background: ${({ theme }) => theme.color.lighterOrange};
  }

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    width: 8rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    width: 5rem;
  }
`;

export const ButtonNormalText = styled.span`
  display: inherit;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    display: none;
  }
`;

export const ButtonShortText = styled.span`
  display: none;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    display: inherit;
  }
`;

export const PaymentSelectorRadioGroup = styled(RadioGroup)`
`;

export const PaymentSelectorRadioButton = styled(Radio)`
  &.MuiRadio-root {
    padding: 0.3rem;
    margin-top: -0.1rem;
    margin-bottom: -0.1rem;

    &:hover {
      background: ${(props) => `${props.theme.color.lightOrange}10`};
    }
    &.Mui-checked {
      color: ${(props) => props.theme.color.orange};
    }
  }
`;

export const PaymentSelectorLabel = styled.span`
  font-size: 1rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    font-size: 0.8rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    font-size: 0.7rem;
  }
`;
