import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import LinkIcon from '@mui/icons-material/Link';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EmailIcon from '@mui/icons-material/Email';
import DownloadIcon from '@mui/icons-material/GetApp';
import PrintIcon from '@mui/icons-material/Print';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { Marker, TileLayer, ZoomControl } from 'react-leaflet';
import { useAuth0 } from '@auth0/auth0-react';
import { SettingsContext } from '../../../lib/Settings';
import exportQuotesXlsxFile from '../../../lib/xlsxFile/exportQuotesXlsxFile';
import roofTypesMap from './roofTypeMapping.json';
import SendOfferPopup from './SendOfferPopup';
import * as S from './styles';

const DashboardButton = ({ title, icon, onClick, href, disabled }) => (
  <Tooltip title={title}>
    {
      href
        ? <S.StandardButton href={href} target="_blank" width="5rem">{icon}</S.StandardButton>
        : <S.StandardButton onClick={disabled ? () => {} : onClick} width="5rem" enabled={(!disabled).toString()}>{icon}</S.StandardButton>
    }
  </Tooltip>
);

DashboardButton.defaultProps = {
  title: '',
  icon: null,
  onClick: () => {},
  href: '',
  disabled: false,
};

DashboardButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.string,
  ]),
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
};

const updateLockedStatus = async (lockedStatus, uuid, accessToken) => {
  const body = {
    locked: !lockedStatus,
  };
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  };
  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${uuid}/changelockedstatus`;
  const result = await fetch(url, requestOptions).catch(console.error);
  const { locked } = await result.json();
  return locked;
};

const DashboardQuotePageUpperPanel = ({ quote, setQuote, merchantList, emailTemples, onPrint, mapHeight }) => {
  const settings = useContext(SettingsContext);
  const [lockLoading, setLockLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { merchant_domain: merchantDomain = '' } = useMemo(
    () => merchantList.find((merchant) => merchant.merchant_id === quote.merchant_id) || {},
    [merchantList?.length, quote?.merchant_id],
  );

  if (!settings || !quote) return null;

  const onUpdateLockedStatus = async () => {
    setLockLoading(true);
    const updatedLockedStatus = await updateLockedStatus(quote.locked, quote.uuid, await getAccessTokenSilently());
    setQuote((prevState) => ({ ...prevState, locked: updatedLockedStatus }));
    setLockLoading(false);
  };

  const deleteQuote = async () => {
    if (!window.confirm('Vil du slette beregning?')) return;
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
    };

    const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${quote.uuid}`;
    const res = await fetch(url, requestOptions).catch(console.error);

    if (res.status === 200) {
      window.location.href = '/quotes';
    }
  };

  return (
    <S.UpperPanel>
      <S.UpperLeftPanel>
        <div style={{ fontSize: '2.5rem', lineHeight: 0.7 }}><b>{quote.name}</b></div>
        <div style={{ marginTop: '20px' }}><b>{`Opprettet: ${moment(quote.timestamp).format('DD.MM.YYYY HH:mm')}`}</b></div>
        <div>{`Oppdatert: ${moment(quote.updated_at).format('DD.MM.YYYY HH:mm')}`}</div>
        <div style={{ marginTop: '20px' }}>{quote.address}</div>
        <div>{`${quote.postal_code} ${quote.city}`}</div>
        <div style={{ marginTop: '20px' }}>{`T: ${quote.telephone}`}</div>
        <div>{`M: ${quote.email}`}</div>
        <S.ButtonWrapper>
          <DashboardButton
            onClick={() => exportQuotesXlsxFile([quote], roofTypesMap, settings, merchantList)}
            title="Last ned"
            icon={<DownloadIcon />}
          />
          <DashboardButton
            href={merchantDomain ? `${merchantDomain}/quote/${quote.uuid}` : `${process.env.REACT_APP_PAGE_URL}/quote/${quote.uuid}`}
            title="Gå til kundens bregning"
            icon={<LinkIcon />}
          />
          <DashboardButton
            title={quote.locked ? 'Lås opp beregning' : 'Lås beregning'}
            icon={quote.locked ? <LockIcon /> : <LockOpenIcon />}
            onClick={onUpdateLockedStatus}
            disabled={lockLoading}
          />
          {
            process.env.REACT_APP_SANITY_DATASET === 'develop' // this is only available in local and test
              ? (
                <DashboardButton
                  onClick={() => setShowPopup(true)}
                  title="Tilbud e-post"
                  icon={<EmailIcon />}
                />
              ) : null
          }
          <DashboardButton
            onClick={onPrint}
            title="Skriv ut"
            icon={<PrintIcon />}
          />
          <DashboardButton
            onClick={deleteQuote}
            title="Slett beregning"
            icon={<DeleteForeverIcon />}
          />
        </S.ButtonWrapper>
      </S.UpperLeftPanel>
      <S.UpperRightPanel>
        <S.MapContainer
          center={[quote.map_position.y, quote.map_position.x]}
          zoomControl={false}
          zoom={10}
          maxZoom={20}
          scrollWheelZoom={false}
          tap={false}
          preferCanvas
          height={mapHeight}
        >
          <TileLayer
            maxZoom={20}
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-vektor/wa_grid/{z}/{x}/{y}.png?api_key=680ae64b-8423-4a3c-b303-39f32adcea41"
          />
          <Marker position={[quote.map_position.y, quote.map_position.x]} />
          <ZoomControl position="bottomleft" />
        </S.MapContainer>
      </S.UpperRightPanel>
      {
        showPopup ? (
          <SendOfferPopup
            quote={quote}
            isOpen={showPopup}
            handleClose={() => setShowPopup(false)}
            emailTemplates={emailTemples}
            emailAddress={quote.email}
          />
        ) : null
      }
    </S.UpperPanel>
  );
};

DashboardQuotePageUpperPanel.defaultProps = {
  quote: undefined,
  merchantList: [],
  emailTemples: [],
  onPrint: () => {},
  setQuote: () => {},
  mapHeight: '20rem',
};

DashboardQuotePageUpperPanel.propTypes = {
  quote: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postal_code: PropTypes.string,
    telephone: PropTypes.string,
    timestamp: PropTypes.string,
    business_type: PropTypes.string,
    locked: PropTypes.bool,
    merchant_id: PropTypes.string,
    calculations: PropTypes.shape({
      cost: PropTypes.shape({
        total: PropTypes.number,
        totalIncludingVatAndEnovaFunding: PropTypes.number,
      }),
    }),
    roofs: PropTypes.arrayOf(PropTypes.shape({})),
    map_position: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }),
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    merchant_id: PropTypes.string,
    name: PropTypes.string,
  })),
  emailTemples: PropTypes.arrayOf(PropTypes.shape({
    emailSubject: PropTypes.string,
    templateName: PropTypes.string,
    html: PropTypes.string,
    key: PropTypes.string.isRequired,
  })),
  onPrint: PropTypes.func,
  setQuote: PropTypes.func,
  mapHeight: PropTypes.string,
};

export default DashboardQuotePageUpperPanel;
