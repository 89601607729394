/* eslint-disable react/prop-types */
import React, { useContext, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';
import { LanguageContext } from './Language';

const NumberFormatCustomComp = ({ onChange, ...props }, ref) => {
  const { language } = useContext(LanguageContext);
  const decimalSeparator = new Intl.NumberFormat(language).format(1.1).slice(1, 2);

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      name={props.name}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      decimalSeparator={decimalSeparator}
      isNumericString
      thousandSeparator={false}
    />
  );
};

const NumberFormatCustom = forwardRef(NumberFormatCustomComp);

const NumberInputField = (props) => (
  <TextField
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    InputProps={{
      inputComponent: NumberFormatCustom,
    }}
  />
);

export default NumberInputField;
