import styled, { keyframes } from 'styled-components';
import WbSunnyRoundedIconBase from '@mui/icons-material/WbSunnyRounded';

export const Body = styled.div`
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.color.primaryDark};
  background: ${({ theme }) => theme.color.primaryLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg) translateY(0.08rem);
  }
  to {
    transform: rotate(360deg) translateY(0.08rem);
  }
`;

export const WbSunnyRoundedIcon = styled(WbSunnyRoundedIconBase)`
  font-size: 5rem;
  animation: ${rotate} 2s linear infinite;
`;
