import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { SettingsContext } from '../../lib/Settings';
import { QuoteRequestForm, RoofSelector, RoofSelectorTakbytte } from '../../components';

const getNewCustomRoof = (settings = {}) => ({
  id: nanoid(),
  isSelected: true,
  length: 0,
  width: 0,
  slope: 0,
  azimuth: 0,
  eavesHeight: 0,
  ridgeTilesLines: [{ length: 0 }],
  gutterLines: [{ length: 0 }],
  pvgisAnnualProductionPerKwp: undefined,
  area3d: 0,
  area2d: 0,
  panelCount: settings.solar.solarPanelCountMinValue,
  panelCountMin: settings.solar.solarPanelCountMinValue,
  panelCountMax: settings.solar.solarPanelCountMinValue,
  isCustomRoof: true,
});

const AddressPageComponent = ({ addressData, roofs, setRoofs, merchantId }) => {
  const settings = useContext(SettingsContext);
  const [newRoofs, setNewRoofs] = useState([]);

  const setRoofSlider = (updatedRoofs = []) => {
    if (!updatedRoofs.length) return;
    if (updatedRoofs[0].isCustomRoof) {
      setNewRoofs(updatedRoofs);
    } else {
      setRoofs(updatedRoofs);
    }
  };

  const addNewRoofHandler = () => setNewRoofs((array) => [...array, getNewCustomRoof(settings)]);
  return (
    addressData.AdresseId
      ? (
        <>
          {
            process.env.REACT_APP_SITE === 'TAKBYTTE'
              ? (
                <RoofSelectorTakbytte
                  mapPosition={
                    addressData
                      ? {
                        srs: addressData.Posisjon.SRS,
                        x: addressData.Posisjon.X,
                        y: addressData.Posisjon.Y,
                      } : null
                  }
                  roofs={roofs}
                  setRoofs={setRoofSlider}
                  newRoofs={newRoofs}
                  setNewRoofs={setNewRoofs}
                  addRoofButtonInMap
                  onAddNewRoof={addNewRoofHandler}
                />
              ) : (
                <RoofSelector
                  mapPosition={
                    addressData
                      ? {
                        srs: addressData.Posisjon.SRS,
                        x: addressData.Posisjon.X,
                        y: addressData.Posisjon.Y,
                      } : null
                  }
                  roofs={roofs}
                  setRoofs={setRoofSlider}
                  newRoofs={newRoofs}
                  setNewRoofs={setNewRoofs}
                  solarPanel={settings.solar.solarPanels[0]}
                  addRoofButtonInMap
                  onAddNewRoof={addNewRoofHandler}
                />
              )
          }

          <QuoteRequestForm
            addressData={addressData}
            merchantId={merchantId}
            roofs={[
              ...roofs,
              ...newRoofs.filter((newRoof) => (
                process.env.REACT_APP_SITE === 'TAKBYTTE' || newRoof.area3d >= settings.solar.manuallyAddedRoofMinValue
              )),
            ]}
          />
        </>
      ) : null
  );
};

AddressPageComponent.defaultProps = {
  addressData: {},
  roofs: [],
  setRoofs: () => {},
  merchantId: undefined,
};

AddressPageComponent.propTypes = {
  addressData: PropTypes.shape({
    AdresseMatrikkelenhetId: PropTypes.string,
    AdresseHusNummer: PropTypes.number,
    AdresseBokstav: PropTypes.string,
    GateId: PropTypes.string,
    GateNavn: PropTypes.string,
    KommuneNavn: PropTypes.string,
    KommuneNummer: PropTypes.number,
    FylkeNavn: PropTypes.string,
    FylkeNummer: PropTypes.number,
    AdresseAlternativtNavn: PropTypes.string,
    AdresseKoordinatKvalitetKode: PropTypes.number,
    Bruksenheter: PropTypes.arrayOf(PropTypes.shape({
      BygningNummer: PropTypes.number,
      ByggId: PropTypes.string,
      BruksenhetId: PropTypes.number,
      MatrikkelenhetId: PropTypes.string,
      UnikMatrikkelenhetId: PropTypes.number,
    })),
    Text: PropTypes.string,
    AdresseId: PropTypes.string,
    UnikAdresseId: PropTypes.number,
    VegAdresseId: PropTypes.string,
    Posisjon: PropTypes.shape({
      X: PropTypes.number,
      Y: PropTypes.number,
      SRS: PropTypes.number,
    }),
    PostNummer: PropTypes.string,
    PostSted: PropTypes.string,
  }),
  roofs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    objectType: PropTypes.string,
    buildingId: PropTypes.string,
    buildingType: PropTypes.string,
    slope: PropTypes.number,
    azimuth: PropTypes.number,
    length: PropTypes.number,
    width: PropTypes.number,
    area2d: PropTypes.number,
    area3d: PropTypes.number,
    availableArea: PropTypes.number,
    stdDeviation: PropTypes.number,
    eavesHeight: PropTypes.number,
    isSelected: PropTypes.bool,
    geometry: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
    }),
  })),
  setRoofs: PropTypes.func,
  merchantId: PropTypes.string,
};

export default AddressPageComponent;
