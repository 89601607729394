import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, subDays, subMonths } from 'date-fns';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import nb from 'date-fns/locale/nb';
import * as S from './styles';

const PERIOD_ENUM = {
  CURRENT_WEEK: 'current_week',
  CURRENT_MONTH: 'current_month',
  PREVIOUS_MONTH: 'previous_month',
  LAST_30_DAYS: 'last_30_days',
  CUSTOM: 'custom',
};
const DATE_PERIOD_INITIAL_VALUE = { start: subDays(new Date(), 30), end: new Date(), period: PERIOD_ENUM.LAST_30_DAYS };

const getStartDate = ({ period, start }) => {
  switch (period) {
    case PERIOD_ENUM.CURRENT_WEEK:
      return startOfWeek(new Date(), { weekStartsOn: 1 });
    case PERIOD_ENUM.CURRENT_MONTH:
      return startOfMonth(new Date());
    case PERIOD_ENUM.PREVIOUS_MONTH:
      return startOfMonth(subMonths(new Date(), 1));
    case PERIOD_ENUM.LAST_30_DAYS:
      return subDays(new Date(), 30);
    case PERIOD_ENUM.CUSTOM:
      return start;
    default:
      return null;
  }
};

const getEndDate = ({ period, end }) => {
  switch (period) {
    case PERIOD_ENUM.CURRENT_WEEK:
      return endOfWeek(new Date(), { weekStartsOn: 1 });
    case PERIOD_ENUM.CURRENT_MONTH:
      return endOfMonth(new Date());
    case PERIOD_ENUM.PREVIOUS_MONTH:
      return endOfMonth(subMonths(new Date(), 1));
    case PERIOD_ENUM.LAST_30_DAYS:
      return new Date();
    case PERIOD_ENUM.CUSTOM:
      return end;
    default:
      return null;
  }
};

const getPeriod = (datePeriod) => ({
  startDate: getStartDate(datePeriod).toISOString(),
  endDate: getEndDate(datePeriod).toISOString(),
});

export const DEFAULT_PERIOD = getPeriod(DATE_PERIOD_INITIAL_VALUE);

const QuotesPageDatePicker = ({ changeUrlQuery }) => {
  const [datePeriod, setDatePeriod] = useState(DATE_PERIOD_INITIAL_VALUE);

  const onDateChange = useCallback((date, name) => {
    setDatePeriod((prev) => {
      const newDatePeriod = { ...prev, [name]: date };
      changeUrlQuery(getPeriod(newDatePeriod), { debounce: false });
      return newDatePeriod;
    });
  }, [setDatePeriod]);

  return (
    <>
      <div>
        <FormControl variant="filled">
          <InputLabel>PERIODE</InputLabel>
          <S.PeriodSelect
            name="period"
            value={datePeriod.period}
            onChange={({ target }) => onDateChange(target.value, target.name)}
          >
            <MenuItem value={PERIOD_ENUM.CURRENT_WEEK}>Denne uken</MenuItem>
            <MenuItem value={PERIOD_ENUM.CURRENT_MONTH}>Denne måneden</MenuItem>
            <MenuItem value={PERIOD_ENUM.PREVIOUS_MONTH}>Forrige måned</MenuItem>
            <MenuItem value={PERIOD_ENUM.LAST_30_DAYS}>Siste 30 dager</MenuItem>
            <MenuItem value={PERIOD_ENUM.CUSTOM}>Egendefinert</MenuItem>
          </S.PeriodSelect>
        </FormControl>
      </div>
      {
        datePeriod.period === PERIOD_ENUM.CUSTOM
          ? (
            <>
              <S.DatePickerWrapper>
                <S.DatePickerLabel>FRA</S.DatePickerLabel>
                <S.DatePicker
                  calendarStartDay={1}
                  dateFormat="dd.MM.yyyy"
                  selected={datePeriod.start}
                  locale={nb}
                  onChange={(date) => onDateChange(date, 'start')}
                />
              </S.DatePickerWrapper>
              <S.DatePickerWrapper>
                <S.DatePickerLabel>TIL</S.DatePickerLabel>
                <S.DatePicker
                  calendarStartDay={1}
                  dateFormat="dd.MM.yyyy"
                  selected={datePeriod.end}
                  locale={nb}
                  onChange={(date) => onDateChange(date, 'end')}
                />
              </S.DatePickerWrapper>
            </>
          )
          : null
      }
    </>
  );
};

QuotesPageDatePicker.defaultProps = {
  changeUrlQuery: () => {},
};

QuotesPageDatePicker.propTypes = {
  changeUrlQuery: PropTypes.func,
};

export default QuotesPageDatePicker;
