const EMPTY_STRING = ' ';

export const insertInToTable = (children, width = 500) => `
  <table style="border-collapse: collapse; margin: 0; width: ${width}px; background-color: #FFFFFF; display: block">
    <tbody style="width: ${width}px; background-color: #ffffff; display: block">
      ${children}
    </tbody>
  </table>
`;

export const insertInToRow = (children = EMPTY_STRING, width = 500) => (`
    <tr style="width: ${width}px; margin: 0; background-color: #FFFFFF;">${children}</tr>
`);

export const insertInToCell = (children = EMPTY_STRING, width = 500) => (
  Array.isArray(children)
    ? children.map((child) => `<td style="width: ${width}px; margin: 0;">${child}</td>`).join('')
    : `<td style="width: ${width}px; margin: 0;">${children}</td>`
);

export const insertInToHtmlDoc = (children) => `
<!doctype html>
<html lang="no" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <title>Tilbud på Solcelleanlegg!</title>
    <!--[if mso]>
      <noscript>
          <xml>
              <o:OfficeDocumentSettings>
                  <o:PixelsPerInch>96</o:PixelsPerInch>
              </o:OfficeDocumentSettings>
          </xml>
      </noscript>
    <![endif]-->
    <style type="text/css">
      /* Font Definitions */
      @font-face {
        font-family: "Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;
      }
      @font-face {
        font-family: Calibri;
        panose-1:2 15 5 2 2 2 4 3 2 4;
      }
    </style>
  <body style="margin: auto; width: 100%; font-family: 'Calibri', sans-serif">
    <table style="border-collapse: collapse; margin: auto; width: 100%; background-color: #f0f0f0;">
      <tr>
        <th align="center">
          <table style="border-collapse: collapse; margin: auto; width: 540px; background-color: #ffffff;">
            <tr>
              <th align="center" style="font-weight: normal">
                ${children}
              </th>
            </tr>
          </table>
        </th>
      </tr>
    </table>
  </body>
</html>`;
