import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FeatureGroup,
  LayersControl,
  Polygon,
  Polyline,
  Popup,
  TileLayer,
  WMSTileLayer,
  ZoomControl,
} from 'react-leaflet';
import MapHomeButton from '../../../components/maphomebutton/MapHomeButton';
import { LanguageContext } from '../../../lib/Language';
import * as S from './styles';

const getRidgeTilesSum = (ridgeTilesLines = []) => ridgeTilesLines.map((e) => e.length).reduce((a, b) => (a + b), 0);
const getGutterLinesSum = (gutterLines = []) => gutterLines.map((e) => e.length).reduce((a, b) => (a + b), 0);

const pointsToLines = (points) => points.map((point, index) => ([point, points[index + 1]])).slice(0, -1);
const comparePoints = ([lonA = 1, latA = 1], [lonB = 1, latB = 1]) => (lonA * latA) - (lonB * latB);
const isEqualLines = (lineA, lineB) => lineA.sort(comparePoints).toString() === lineB.sort(comparePoints).toString();

const getLinesWithColor = ({ geometry = {}, ridgeTilesLines = [], gutterLines = [] }) => {
  const ridgeTilesLinesCoords = ridgeTilesLines.map(({ coordinates = [] }) => coordinates) ?? [];
  const gutterLinesCoords = gutterLines.map(({ coordinates = [] }) => coordinates) ?? [];
  const roofCoordinates = geometry.coordinates?.[0] ?? [];
  const lines = pointsToLines(roofCoordinates);

  return lines.map((line) => {
    if (ridgeTilesLinesCoords.some((ridgeTilesLine) => isEqualLines(line, ridgeTilesLine))) return { color: 'red', line };
    if (gutterLinesCoords.some((ridgeTilesLine) => isEqualLines(line, ridgeTilesLine))) return { color: 'purple', line };
    return { color: 'blue', line };
  });
};

const DashboardQuotePageLowerPanel = ({ quote }) => {
  const { language } = useContext(LanguageContext);
  const [selectedRoofId, setSelectedRoofId] = useState();
  if (!quote) return null;

  return (
    <>
      <S.LowerMapPanelHeader>Klikk på takene i kartet for detaljer</S.LowerMapPanelHeader>

      <S.LowerMapPanel style={{ marginBottom: '20px' }}>
        <S.MapContainer
          center={[quote.map_position.y, quote.map_position.x]}
          zoomControl={false}
          zoom={20}
          maxZoom={20}
          scrollWheelZoom={false}
          tap={false}
          preferCanvas
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Flyfoto (med navn)">
              <TileLayer
                maxZoom={20}
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-hybrid/wa_grid/{z}/{x}/{y}.jpeg?api_key=680ae64b-8423-4a3c-b303-39f32adcea41"
              />
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Flyfoto (uten navn)">
              <TileLayer
                maxZoom={20}
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://waapi.webatlas.no/maptiles/tiles/webatlas-orto-newup/wa_grid/{z}/{x}/{y}.jpeg?api_key=680ae64b-8423-4a3c-b303-39f32adcea41"
              />
            </LayersControl.BaseLayer>

            <LayersControl.Overlay checked name="Takflater">
              <WMSTileLayer
                url="https://waapi.webatlas.no/WMS-Takhelning/?service=WMS&request=GetMap&api_key=680ae64b-8423-4a3c-b303-39f32adcea41"
                layers="takhelning"
                styles="takflate-solcelle-norkart"
                transparent="true"
                format="image/png"
                maxZoom={20}
              />
            </LayersControl.Overlay>

            <LayersControl.Overlay checked name="Polygoner">
              <FeatureGroup>
                {quote.roofs.filter(({ geometry, isSelected } = {}) => (geometry && isSelected)).map((roof = {}, index) => {
                  const coords = roof.geometry.coordinates[0].map((coord) => (
                    [coord[1], coord[0]]
                  ));
                  return (
                    <Polygon
                      key={roof.id}
                      pathOptions={{
                        color: 'blue',
                        weight: process.env.REACT_APP_SITE === 'TAKBYTTE' ? 0 : 3,
                        fillColor: 'blue',
                        opacity: 1,
                      }}
                      positions={coords}
                      eventHandlers={{
                        click: () => {
                          setSelectedRoofId(roof.id);
                        },
                      }}
                    >
                      <Popup
                        onClose={() => setSelectedRoofId(null)}
                        onOpen={() => setSelectedRoofId(roof.id)}
                      >
                        <div style={{ fontSize: '1.1rem' }}><b>{` Tak ${index + 1}`}</b></div>
                        <div>
                          <div>{`Lengde: ${new Intl.NumberFormat(language).format(Math.round(roof.length * 10) / 10)} m`}</div>
                          <div>{`Bredde: ${new Intl.NumberFormat(language).format(Math.round(roof.width * 10) / 10)} m`}</div>
                          <div>{`Areal: ${new Intl.NumberFormat(language).format(Math.round(roof.area3d * 10) / 10)} m²`}</div>
                          <div>{`Grunnflate: ${new Intl.NumberFormat(language).format(Math.round(roof.area2d * 10) / 10)} m²`}</div>
                          <div>{`Helning: ${new Intl.NumberFormat(language).format(Math.round(roof.slope * 10) / 10)} grader`}</div>
                          {
                            process.env.REACT_APP_SITE === 'TAKBYTTE'
                              ? (
                                <>
                                  <div>{`ID: ${roof.id}`}</div>
                                  <div>{`Takfot høyde: ${new Intl.NumberFormat(language).format(Math.round(roof.eavesHeight * 10) / 10)} m`}</div>
                                  <div>{`Gavelstein lengde: ${new Intl.NumberFormat(language).format(Math.round(getRidgeTilesSum(roof.ridgeTilesLines) * 10) / 10)} m`}</div>
                                  <div>{`Takrenne lengde: ${new Intl.NumberFormat(language).format(Math.round(getGutterLinesSum(roof.gutterLines) * 10) / 10)} m`}</div>
                                  <div>Side lengde:</div>
                                  <div>
                                    {
                                      (roof.roofLines || []).map((lineLength) => (
                                        `${new Intl.NumberFormat(language).format(Math.round(lineLength * 10) / 10)}m`
                                      )).join(', ')
                                    }
                                  </div>
                                </>
                              )
                              : (
                                <>
                                  <div>{`Tilgjengelig areal: ${new Intl.NumberFormat(language).format(Math.round(roof.availableSpace * 10) / 10)} m²`}</div>
                                  <div>{`Maks antall panel: ${roof.panelCountMax}`}</div>
                                  <div>{`Retning: ${new Intl.NumberFormat(language).format(Math.round(roof.azimuth * 10) / 10)} grader`}</div>
                                  <div>{`Årlig produksjon: ${new Intl.NumberFormat(language).format(Math.round(roof.estimatedAnnualProduction * 10) / 10)} kWh`}</div>
                                </>
                              )
                          }
                        </div>
                      </Popup>
                    </Polygon>
                  );
                })}

                {
                  process.env.REACT_APP_SITE === 'TAKBYTTE'
                    ? (
                      quote.roofs
                        .filter(({ geometry, isSelected } = {}) => (geometry && isSelected))
                        .map((roof = {}) => getLinesWithColor(roof).map(({ line, color }) => (
                          <Polyline
                            style={{ zIndex: 5 }}
                            pathOptions={{ color, opacity: 1 }}
                            positions={line.map((c) => c.slice(0, 2).reverse())}
                            key={`${roof.id}-${line.toString()}`}
                          />
                        )))
                    )
                    : null
                }
              </FeatureGroup>
            </LayersControl.Overlay>

            <MapHomeButton
              mapPosition={quote.map_position}
              zoom={20}
            />
          </LayersControl>
          <ZoomControl position="bottomleft" />
        </S.MapContainer>
      </S.LowerMapPanel>
      {process.env.REACT_APP_SITE === 'TAKBYTTE' ? null
        : (
          <S.LowerTable>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead style={{ textAlign: 'left', backgroundColor: '#3d444e', color: '#fefefe' }}>
                <S.LowerMapTableRow>
                  <th style={{ padding: '10px 10px 6px' }}>&nbsp;</th>
                  <th style={{ padding: '10px 10px 6px' }}>Størrelse</th>
                  <th style={{ padding: '10px 10px 6px' }}>Produksjon</th>
                  <th style={{ padding: '10px 10px 6px' }}>Antall paneler</th>
                  <th style={{ padding: '10px 10px 6px' }}>Maks for taket</th>
                </S.LowerMapTableRow>
              </thead>
              <tbody>
                {
            quote.roofs.filter((ro) => ro.isSelected).map((r, index) => (
              <tr key={r.id} style={{ backgroundColor: selectedRoofId && selectedRoofId === r.id ? '#fedfb2' : 'transparent' }}>
                <S.LowerMapTableColumn>{index + 1}</S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{`${new Intl.NumberFormat(language).format(r.area3d.toFixed(1))} m²`}</S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{`${new Intl.NumberFormat(language).format(Math.round(r.estimatedAnnualProduction * 10) / 10)} kWh`}</S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{`${r.panelCount} paneler`}</S.LowerMapTableColumn>
                <S.LowerMapTableColumn>{`${r.panelCountMax} paneler`}</S.LowerMapTableColumn>
              </tr>
            ))
          }
              </tbody>
            </table>
          </S.LowerTable>
        )}
    </>
  );
};

export default DashboardQuotePageLowerPanel;

DashboardQuotePageLowerPanel.defaultProps = {
  quote: undefined,
};

DashboardQuotePageLowerPanel.propTypes = {
  quote: PropTypes.shape({
    roofs: PropTypes.arrayOf(PropTypes.shape({})),
    map_position: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  }),
};
