import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';

import * as S from './styles';

const QuoteProgressDialog = ({ open, message }) => (
  <Dialog fullScreen open={open}>
    <S.Body>
      <S.WbSunnyRoundedIcon />
      <h1>{message}</h1>
    </S.Body>
  </Dialog>
);

QuoteProgressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default QuoteProgressDialog;
