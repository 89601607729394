import React, { useContext, useEffect, useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../../lib/sanityClient';

import { LanguageContext } from '../../lib/Language';
import Page from '../../components/Page';

import * as S from './styles';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => builder.image(source);

const serializers = {
  types: {
    // eslint-disable-next-line react/prop-types
    image: ({ node }) => (
      <S.ImageWrapper>
        <S.Image
          src={urlFor(node)}
          // eslint-disable-next-line react/prop-types
          alt={node.alt}
        />
      </S.ImageWrapper>
    ),
  },
  marks: {
    // eslint-disable-next-line react/prop-types
    color: ({ children, mark }) => (
      <S.TextColorWrapper
        // eslint-disable-next-line react/prop-types
        $red={mark.rgb.r}
        // eslint-disable-next-line react/prop-types
        $green={mark.rgb.g}
        // eslint-disable-next-line react/prop-types
        $blue={mark.rgb.b}
        // eslint-disable-next-line react/prop-types
        $alpha={mark.rgb.a}
      >
        {children[0]}
      </S.TextColorWrapper>
    ),
  },
};

const FinancingPage = () => {
  const [pageData, setPageData] = useState(null);
  const { language } = useContext(LanguageContext);
  const getBlock = (id) => pageData.blocks.find(({ slug }) => slug.current === id);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "page" && slug.current == "financing"]{
        title,
        blocks
      }`)
      .then((data) => {
        setPageData((data.length === 0) ? {} : data[0]);
      })
      .catch(console.error);
  }, []);

  if (!pageData) return <div>Loading...</div>;
  if (Object.keys(pageData).length === 0) return <div>Page data not found...</div>;

  return (
    <Page title={pageData.title[language]}>
      <S.BodyWrapper>
        <BlockContent
          blocks={getBlock('body')?.blockContent[language]}
          serializers={serializers}
          projectId={sanityClient.config().projectId}
          dataset={sanityClient.config().dataset}
        />
      </S.BodyWrapper>
    </Page>
  );
};

export default FinancingPage;
