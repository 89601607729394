import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../../lib/Settings';
import { Alert, AddressSearchInput } from '../../components';
import * as S from './styles';

const WhiteLabelPage = () => {
  const { merchantId } = useParams();
  const settings = useContext(SettingsContext);
  const [alertMessage, setAlertMessage] = useState(undefined);

  if (!merchantId) return <h1>No merchant ID defined</h1>;
  if (!settings) return null;

  return (
    <S.Container>
      <AddressSearchInput merchantId={merchantId} onAlert={setAlertMessage} />
      <Alert
        alertOpen={!!alertMessage}
        onClose={() => setAlertMessage(undefined)}
        message={alertMessage}
      />
    </S.Container>
  );
};

export default WhiteLabelPage;
