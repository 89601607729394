import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as S from './styles';
import Page from '../../components/Page';
import sanityClient from '../../lib/sanityClient';
import { LanguageContext } from '../../lib/Language';

const getEmail = (email, language = 'no') => {
  const { string: emailSubjectObj = {} } = email?.find(({ slug = {} }) => slug.current === 'email-subject') || {};
  const { heading = {}, text = {} } = email?.find(({ slug = {} }) => slug.current === 'email-text') || {};
  const { string: linkTextObj = {} } = email?.find(({ slug = {} }) => slug.current === 'link-text') || {};

  return {
    subject: emailSubjectObj[language],
    text: text[language],
    heading: heading[language],
    linkText: linkTextObj[language],
  };
};

const getSanityData = async (language) => {
  const { emailData = [], page = {} } = await sanityClient.fetch(`
  {
    "page": *[_type == "page" && slug.current == "order-confirmation"][0]{
      title,
      blocks
    },
    "emailData": *[_type == "component" && slug.current == "order-confirmation-e-mail"][0].blocks
  }
  `)
    .catch(console.error);

  return {
    emailData: getEmail(emailData, language),
    page,
  };
};

const getQuoteData = async (uuid) => {
  const quoteUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${uuid}`;
  const res = await fetch(quoteUrl);
  return res.json();
};

const sendEmail = async (uuid, customerEmailData) => {
  const sendEmailUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${uuid}/send_emails`;

  const res = await fetch(sendEmailUrl, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      customerEmailData,
      pageUrl: process.env.REACT_APP_PAGE_URL,
      dashboardUrl: process.env.REACT_APP_DASHBOARD_URL,
    }),
  });

  return res.json();
};

const ConfirmationPage = () => {
  const { uuid } = useParams();
  const [pageData, setPageData] = useState();
  const [quote, setQuote] = useState();
  const { language } = useContext(LanguageContext);
  const getBlock = (id) => pageData.blocks.find(({ slug }) => slug.current === id);

  useEffect(() => {
    getSanityData(language).then(({ emailData, page }) => {
      sendEmail(uuid, emailData).catch(console.error);
      setPageData(page);
    }).catch(console.error);

    getQuoteData(uuid).then(setQuote).catch(console.error);
  }, []);

  if (!pageData || !quote) return <div>Loading...</div>;

  return (
    <Page title={pageData.title[language]}>
      <S.BodyWrapper>
        <div>
          <h1>{getBlock('thank-you').string[language]}</h1>
        </div>
        <div>
          <h5 style={{ fontWeight: 300 }}>
            {getBlock('you-will-be-contacted').string[language]}
          </h5>
        </div>
        <div style={{ marginTop: '20px' }}>
          <div><b>{`${quote.name}`}</b></div>
          <div><b>{`${quote.email}`}</b></div>
          <div><b>{`${quote.telephone}`}</b></div>
        </div>

        <S.DetailsHeader>{getBlock('details').string[language]}</S.DetailsHeader>
        <S.DetailsWrapper>
          <div>
            <S.DetailsRow>
              <S.DetailsString>{`${getBlock('personal').string[language]} / ${getBlock('business').string[language]}:`}</S.DetailsString>
              <S.DetailsString>
                {quote.business_type === 'business'
                  ? getBlock('business').string[language]
                  : getBlock('personal').string[language]}
              </S.DetailsString>
            </S.DetailsRow>
            <S.DetailsRow>
              <S.DetailsString>{`${getBlock('financing').string[language]}:`}</S.DetailsString>
              <S.DetailsString>
                {quote.options.paymentMethod === 'loan'
                  ? getBlock('yes').string[language]
                  : getBlock('no').string[language]}
              </S.DetailsString>
            </S.DetailsRow>
          </div>
          <S.VerticalLine />
          {
            process.env.REACT_APP_SITE === 'TAKBYTTE'
              ? (
                <div>
                  <S.DetailsRow>
                    <S.DetailsString>{`${getBlock('number-of-roofs').string[language]}:`}</S.DetailsString>
                    <S.DetailsString>
                      {quote.calculations.roofCount}
                    </S.DetailsString>
                  </S.DetailsRow>
                  <S.DetailsRow>
                    <S.DetailsString>{`${getBlock('roof-area').string[language]}:`}</S.DetailsString>
                    <S.DetailsString>
                      {`${new Intl.NumberFormat(language).format(quote.calculations.roofArea)} m²`}
                    </S.DetailsString>
                  </S.DetailsRow>
                  <S.DetailsRow>
                    <S.DetailsString>{`${getBlock('roof-type').string[language]}:`}</S.DetailsString>
                    <S.DetailsString>
                      {quote.options.roofingType.title?.[language] || quote.options.roofingType.productName}
                    </S.DetailsString>
                  </S.DetailsRow>
                </div>
              ) : (
                <div>
                  <S.DetailsRow>
                    <S.DetailsString>{`${getBlock('peak-power').string[language]}:`}</S.DetailsString>
                    <S.DetailsString>
                      {`${new Intl.NumberFormat(language).format(quote.calculations.production.peakPower)} kWp`}
                    </S.DetailsString>
                  </S.DetailsRow>
                  <S.DetailsRow>
                    <S.DetailsString>{`${getBlock('roof-area').string[language]}:`}</S.DetailsString>
                    <S.DetailsString>
                      {`${new Intl.NumberFormat(language).format(quote.calculations.production.roofArea)} m²`}
                    </S.DetailsString>
                  </S.DetailsRow>
                  <S.DetailsRow>
                    <S.DetailsString>{`${getBlock('panels').string[language]}:`}</S.DetailsString>
                    <S.DetailsString>
                      {quote.calculations.production.panelCount}
                    </S.DetailsString>
                  </S.DetailsRow>
                  <S.DetailsRow>
                    <S.DetailsString>{`${getBlock('number-of-roofs').string[language]}:`}</S.DetailsString>
                    <S.DetailsString>
                      {quote.calculations.production.roofCount}
                    </S.DetailsString>
                  </S.DetailsRow>
                  <S.DetailsRow>
                    <S.DetailsString>{`${getBlock('roof-type').string[language]}:`}</S.DetailsString>
                    <S.DetailsString>
                      {getBlock(quote.options.roofing)?.string[language] || getBlock('other')?.string[language]}
                    </S.DetailsString>
                  </S.DetailsRow>
                </div>
              )
          }
        </S.DetailsWrapper>
      </S.BodyWrapper>
    </Page>
  );
};

export default ConfirmationPage;
