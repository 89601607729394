import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSanity } from '../../lib/sanityClient';
import { LanguageContext } from '../../lib/Language';
import useDebounce from '../../hooks/useDebounce';
import * as S from './styles';

export const addressInputSanityQuery = `
  *[_type == "component" && slug.current == "address-search-input"][0]{
    blocks
  }
`;

const fetchAddresses = async (query) => {
  const url = `https://www.webatlas.no/WAAPI-FritekstSok/suggest/matrikkel/adresse/gateadresse?Size=10&Query=${query}&api_key=${process.env.REACT_APP_NORKART_API_KEY}&format=json`;
  const res = await fetch(url);
  const { Options } = await res.json();
  return Options;
};

const AddressSearchInput = ({ onFocus, className, merchantId, onAlert }) => {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const { getBlock, loading: sanityDataLoading } = useSanity(addressInputSanityQuery);

  const [searchInputAddress, setSearchInputAddress] = useState('');
  const [searchResultAddresses, setSearchResultAddresses] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const debouncedFetchAddress = useDebounce(async (query) => {
    const result = await fetchAddresses(query).catch((e) => {
      console.error(e);
      onAlert([
        getBlock('address-search-failed-error-text')?.string[language || 'no'],
        searchInputAddress,
      ]);
    });

    setSearchLoading(false);
    setSearchResultAddresses(result ?? []);
  }, 300);

  const onChange = async ({ target }) => {
    const { value = '' } = target;
    setSearchInputAddress(value);
    if (value.length > 1) {
      debouncedFetchAddress(value);
      setSearchLoading(true);
    } else {
      setSearchResultAddresses([]);
    }
  };

  const onAddressSelected = ({ target }) => {
    const { addressId, roadAddressId } = target.dataset ?? {};
    history.push(`/whitelabel/address/${addressId}-${roadAddressId}-${merchantId}`);
  };

  if (sanityDataLoading) return null;

  return (
    <S.Container className={className}>
      <S.AddressSearchWrapper>
        <S.AddressSearchInputWrapper>
          <S.AddressSearchInput
            placeholder={getBlock('address-search-field-input-placeholder')?.string?.[language] ?? ''}
            value={searchInputAddress}
            onClick={(event) => {
              event.target.select();
              onFocus(event);
            }}
            onChange={onChange}
          />
          <S.AddressSearchButtonIconWrapper>
            {
              searchLoading
                ? <S.AddressSearchButtonProgressIcon size="100%" color="inherit" />
                : <S.AddressSearchButtonArrowLeftIcon />
            }
          </S.AddressSearchButtonIconWrapper>
        </S.AddressSearchInputWrapper>
        <S.AddressSuggestionsWrapper>
          {
            searchResultAddresses.map(({ PayLoad: address }) => (
              <S.AddressSuggestion
                key={address.AdresseId}
                onClick={onAddressSelected}
                data-address-id={address.AdresseId}
                data-road-address-id={address.VegAdresseId}
              >
                {address.Text}
              </S.AddressSuggestion>
            ))
          }
        </S.AddressSuggestionsWrapper>
      </S.AddressSearchWrapper>
    </S.Container>
  );
};

AddressSearchInput.defaultProps = {
  onFocus: () => {},
  className: '',
  merchantId: '',
  onAlert: () => {},
};

AddressSearchInput.propTypes = {
  onFocus: PropTypes.func,
  className: PropTypes.string,
  merchantId: PropTypes.string,
  onAlert: PropTypes.func,
};

export default AddressSearchInput;
