import React, {
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import sanityClient from './sanityClient';

export const SettingsContext = createContext(undefined);

const settingQuery = `
{
  "general": *[_type == "generalSettings"][0],
  "solar": *[_type == "solarSettings"][0],
  "environment": *[_type == "environmentSettings"][0],
  "prices": *[_type == "prices"][0],
  "priceDifferentiation": *[_type == "priceDifferentiation"][0],
  "saleStatus": *[_type == "saleStatus"][0],
  "roofing": *[_type == "roofingSettings"][0].roofing,
  "additional": *[_type == "additionalOptions"][0].additional,
}
`;

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState();
  const isLoaded = useRef(true);

  useEffect(() => {
    sanityClient.fetch(settingQuery)
      .then((data) => {
        if (isLoaded.current) setSettings(data);
      })
      .catch(alert.error);

    return () => {
      isLoaded.current = false;
    };
  }, []);

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
