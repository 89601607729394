import Intl from 'intl';
import { format } from 'date-fns';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const getNumberOfDaysString = (timestampObj) => {
  const milliseconds = new Date().getTime() - new Date(timestampObj).getTime();
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(seconds / 3600);
  const days = Math.floor(seconds / 86400);
  if (days > 0) { return `${days} ${days === 1 ? 'dag' : 'dager'} siden`; }
  if (hours > 0) { return `${hours} timer siden`; }
  return `${minutes} minutter siden`;
};

const DateString = ({ timestamp }) => {
  const timestampObj = new Date(timestamp);
  const getTimestampString = () => {
    let weekday = new Intl.DateTimeFormat('nb', { weekday: 'short' }).format(timestampObj);
    weekday = weekday[0].toUpperCase() + weekday.slice(1);
    return `${weekday} ${format(timestampObj, 'dd.MM.yyyy HH:mm:ss')}`;
  };

  const [output, setOutput] = useState(getNumberOfDaysString(timestampObj));
  return (
    <span
      onMouseOver={() => setOutput(getTimestampString())}
      onFocus={() => setOutput(getTimestampString())}
      onMouseOut={() => setOutput(getNumberOfDaysString(timestampObj))}
      onBlur={() => setOutput(getNumberOfDaysString(timestampObj))}
    >
      {output}
    </span>
  );
};

DateString.propTypes = {
  timestamp: PropTypes.string.isRequired,
};

export default DateString;
