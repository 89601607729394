import React, { useEffect, useState } from 'react';
import sanityClient from '../../lib/sanityClient';
import { Page } from '../../components';
import ProgressWithBackDrop from '../../components/progressWithBackDrop/ProgressWithBackDrop';
import CustomTable from './CustomTable';
import * as S from './style';

const MAP_SANITY_PROJECT_ID_TO_PROJECT_NAME = {
  npfjlg28: 'Solcellekraft',
  vu2v93vf: 'Takbytte',
};

const { clientConfig = {} } = sanityClient || {};

const getLocalDate = (date) => (
  date === '%%BUILD_TIME%%'
    ? 'No build date'
    : new Date(date).toLocaleString('nb-no')
);

const Version = () => {
  const [backendVersion, setBackendVersion] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/version`)
      .then(async (res) => {
        setBackendVersion(await res.json());
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  return (
    <Page title="Version">
      <ProgressWithBackDrop loading={loading} style={{ left: 0, width: '100vw' }}>
        <S.Container>
          <CustomTable
            title="Front-end"
            rows={[
              { title: 'Build time', value: getLocalDate(window.BUILD_TIME), isUrl: false },
              { title: 'Site name', value: process.env.REACT_APP_SITE, isUrl: false },
              { title: 'Backend URL', value: `${process.env.REACT_APP_BACKEND_URL}/ping`, isUrl: true },
              { title: 'Auth0 Domain', value: (process.env.REACT_APP_AUTH0_DOMAIN ?? '').split('.')?.[0] ?? '', isUrl: false },
            ]}
          />

          <CustomTable
            title="Sanity"
            rows={[
              { title: 'Project name', value: MAP_SANITY_PROJECT_ID_TO_PROJECT_NAME[clientConfig.projectId] ?? clientConfig.projectId, isUrl: false },
              { title: 'Project ID', value: clientConfig.projectId, isUrl: false },
              { title: 'Dataset', value: clientConfig.dataset, isUrl: false },
              { title: 'Api version', value: clientConfig.apiVersion, isUrl: false },
            ]}
          />

          <CustomTable
            title="Back-end"
            rows={[
              { title: 'Build time', value: getLocalDate(backendVersion.buildTime), isUrl: false },
              { title: 'Node version', value: backendVersion.node ?? '', isUrl: false },
              { title: 'V8 version', value: backendVersion.v8 ?? '', isUrl: false },
              { title: 'Application name', value: backendVersion.applicationName ?? '', isUrl: false },
              { title: 'Database name', value: backendVersion.dbName ?? '', isUrl: false },
              { title: 'Auth0 Domain', value: backendVersion.auth0Domain ?? '', isUrl: false },
            ]}
          />
        </S.Container>
      </ProgressWithBackDrop>
    </Page>
  );
};

export default Version;
