import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledText = styled.div`
  font-size: ${(props) => props.theme.fontSize.large[props.$fontSize]};
  text-align: ${({ $textAlign }) => $textAlign};
  font-weight: ${({ $bold }) => ($bold ? 700 : 400)};
  margin: ${({ $marginTop }) => $marginTop} ${({ $marginRight }) => $marginRight} ${({ $marginBottom }) => $marginBottom} ${({ $marginLeft }) => $marginLeft};

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    font-size: ${(props) => props.theme.fontSize.medium[props.$fontSize]};
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    font-size: ${(props) => props.theme.fontSize.small[props.$fontSize]};
  }
`;

const Text = ({
  fontSize,
  textAlign,
  bold,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  children,
  className,
}) => (
  <StyledText
    $fontSize={fontSize}
    $textAlign={textAlign}
    $bold={bold}
    $marginBottom={marginBottom}
    $marginTop={marginTop}
    $marginLeft={marginLeft}
    $marginRight={marginRight}
    className={className}
  >
    {children}
  </StyledText>
);

Text.propTypes = {
  fontSize: PropTypes.oneOf(['xxxs', 'xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  bold: PropTypes.bool,
  marginBottom: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  marginTop: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  marginLeft: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  marginRight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.object,
    ])),
  ]).isRequired,
  className: PropTypes.string,
};

Text.defaultProps = {
  fontSize: 'md',
  textAlign: 'left',
  bold: false,
  marginBottom: 0,
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  className: '',
};

export default Text;
