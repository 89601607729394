import styled from 'styled-components';
import { CircularProgress } from '@mui/material';
import { ReactComponent as AddressSearchButtonArrowLeft } from '../../assets/AddressSearchButtonArrowLeft.svg';
import { ReactComponent as AddressSearchButtonArrowRight } from '../../assets/AddressSearchButtonArrowRight.svg';

export const Container = styled.div`
  width: 100%;
  display: flex;  
  justify-content: center;
`;

export const AddressSearchWrapper = styled.div`
  width: 100%;
  max-width: 40rem;
  display: flex;
  white-space: nowrap;
  justify-content: center;
  position: relative;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    max-width: 35rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    max-width: initial;
  }
`;

export const AddressSearchInputWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    max-width: none;
  }
`;

export const AddressSearchInput = styled.input`
  height: 6rem;
  width: 100%;
  border: 3px solid ${({ theme }) => theme.color.primaryBorder};
  border-radius: 1rem;
  padding: 0 2rem;
  font-size: ${({ theme }) => theme.fontSize.large.lg};

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    height: 5rem;
    font-size: ${({ theme }) => theme.fontSize.medium.lg};
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    height: 4rem;
    font-size: ${({ theme }) => theme.fontSize.small.lg};
  }
`;

export const AddressSearchButtonIconWrapper = styled.div`
  height: 3.5rem;
  width: 3.5rem;
  position: absolute;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  border-radius: 50%;
  background: ${({ theme }) => theme.color.lightOrange};
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    height: 3rem;
    width: 3rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    height: 2.5rem;
    width: 2.5rem;
  }
`;

export const AddressSearchButtonArrowLeftIcon = styled(AddressSearchButtonArrowLeft)`
  height: 100%;
  width: 100%;
  padding: 0.5rem;
`;

export const AddressSearchButtonArrowRightIcon = styled(AddressSearchButtonArrowRight)`
  height: 100%;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
`;

export const AddressSearchButtonProgressIcon = styled(CircularProgress)`
  padding: 0.5rem;
`;

export const AddressSuggestionsWrapper = styled.div`
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.color.orange};
  color: white;
  border-radius: 1rem;
  overflow: hidden;
  z-index: 1;
`;

export const AddressSuggestion = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large.sm};
  color: white;
  text-align: left;
  padding: 0.5rem 1rem;
  cursor: pointer;
  :hover {
    background: ${({ theme }) => theme.color.lightOrange};
  }
  transition: all ease-out .10s;
`;
