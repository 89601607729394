import styled from 'styled-components';

import BulbIconBase from '@mui/icons-material/EmojiObjectsOutlined';
import CarIconBase from '@mui/icons-material/DriveEta';
import BusIconBase from '@mui/icons-material/DirectionsBus';
import Button from '@mui/material/Button';
import TextFieldBase from '@mui/material/TextField';

export const BodyWrapper = styled.div`
  ${({ theme }) => theme.style.basePageStyle}
  margin-top: 8rem;
  margin-bottom: 4rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    padding-left: 1rem;
    padding-right: 1rem;  
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;  
  }
`;

export const SummaryBanner = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.lightOrange};
  display: grid;
  grid-template: auto / 1fr 1fr;
  grid-gap: 1rem;
  padding: 2rem;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-template: auto auto / auto;
  }
`;

export const AdditionalPrices = styled.div`
  background-color: #F0ECE8;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2rem auto;
  border-radius: 10px;
  
  h3 {
    width: calc(100% - 5rem);
    line-height: 2;
  }
`;

export const AdditionalPricesTable = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  border-radius: 10px;
  width: calc(100% - 4rem);
  padding: 1rem 2rem;
`;

export const AdditionalRow = styled.div`
  width: 100%;
  padding: 0 1rem 0;
  line-height: 2.2;
  display: inline-grid;
  grid-template-columns: auto 10rem;
  ${({ bold }) => (bold ? 'font-weight: bold;' : '')}

:not(:last-child) {
    border-bottom: 2px solid #F0ECE8;
  }

  span:last-child {
    text-align: right;
  }
`;

export const SummaryText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentMethodSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const PaymentMethodActiveSelectButton = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.lighterGray};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  text-align: center;
  padding: 1rem 0;
  letter-spacing: 0;
`;

export const PaymentMethodInactiveSelectButton = styled.button`
  width: 100%;
  background: ${({ theme }) => theme.color.gray};
  text-align: center;
  padding: 0.8rem 0;
  margin-top: 0.4rem;
  cursor: pointer;
  border: 0;
  font: inherit;
  letter-spacing: 0;

  :first-child {
    border-top-left-radius: 0.5rem;
  }

  :last-child {
    border-top-right-radius: 0.5rem;
  }

  :hover {
    background: ${({ theme }) => theme.color.lightGray};
  }

  transition: all 0.2s ease-out;
`;

export const CostWrapper = styled.div`
  background: ${({ theme }) => theme.color.beige};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`;

export const HorizontalFlex = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.color.orange};
  margin: 1rem 0;
`;

export const LoanInformationLink = styled.a`
  color: ${({ theme }) => theme.color.darkOrange};
  text-decoration: underline;

  :hover {
    color: ${({ theme }) => theme.color.orange};
  }
  
  transition: all 0.2s ease-out;
`;

export const RoofTypeWrapper = styled.div`
  margin-top: 1rem;
  background-color: #F0ECE8;
  height: 170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: .5rem 4rem 1rem;
  gap: 1rem;
  border-radius: 10px;
`;

export const Select = styled(TextFieldBase)`
  background: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  font-weight: 700;
  text-align: left;
  width: 100%;

  & fieldset {
    border-radius: 1rem;
    border: ${({ theme }) => `2px solid ${theme.color.black}`};
  }

  & select {
    background: ${({ theme }) => theme.color.white};
  }
  
  .MuiSelect-selectMenu {
    background: ${({ theme }) => theme.color.white};
    border-radius: 1rem;
  }
`;

export const KeyValueBanner = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.color.primaryLight};
  display: grid;
  grid-gap: 1rem;
  grid-template: 1fr / 1fr 1fr 1fr;
  padding: 5rem 2rem;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-template: 1fr 1fr 1fr / 1fr;
    grid-gap: 2rem;
    padding: 3rem 0;
  }
`;

export const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BulbIcon = styled(BulbIconBase)`
  font-size: 3rem;
`;

export const CarIcon = styled(CarIconBase)`
  font-size: 3rem;
`;

export const BusIcon = styled(BusIconBase)`
  font-size: 3rem;
`;

export const SellingPointWrapper = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.color.beige};
  grid-template: 1fr / 1fr 1fr;
  padding: 4rem 2rem;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    gap: 1rem;
  }
`;

export const SellingPointHeader = styled.h3`
  margin-bottom: 1rem;
`;

export const OrderInformationWrapper = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.color.beige};
  grid-template: 1fr / 1fr 1fr;
  padding: 4rem 2rem;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    gap: 1rem;
  }
`;

export const OrderInformationHeader = styled.h3`
  margin-bottom: 1rem;
`;

export const ConfirmButton = styled(Button)`
  width: 12rem;
  background-color: ${({ theme }) => theme.color.lightOrange};
  color: ${({ theme }) => theme.color.white};
  margin-right: 1rem;
`;
