import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import AlertBase from '@mui/material/Alert';
import PropTypes from 'prop-types';

const Alert = ({ alertOpen, onClose, message }) => (
  <Snackbar
    open={alertOpen}
    autoHideDuration={4000}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
  >
    <AlertBase
      onClose={onClose}
      severity="error"
      variant="filled"
      width="150%"
      style={{ alignItems: 'center' }}
    >
      {
        (Array.isArray(message) ? message : [message]).map((messageLine) => (
          <p style={{ margin: 0 }} key={messageLine ?? 'no_key'}>{messageLine}</p>
        ))
      }
    </AlertBase>
  </Snackbar>
);

Alert.defaultProps = {
  message: undefined,
  alertOpen: false,
};

Alert.propTypes = {
  alertOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
  ]),
};

export default Alert;
