import styled from 'styled-components';
import FormControlBase from '@mui/material/FormControl';
import SelectBase from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputLabelBase from '@mui/material/InputLabel';

export const MerchantForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
`;

export const FormControl = styled(FormControlBase)`
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  > * {
    width: 32%;
    min-width: 15rem;
    height: 3.5rem;
  }
`;

export const StandardSelect = styled(SelectBase)`
  // background: ${({ theme }) => theme.color.beige};
  font-family: 'BrandonText', serif;

`;

export const StandardTextField = styled(TextField)`
  //background: ${({ theme, disabled }) => (disabled ? 'white' : theme.color.beige)};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  .Mui-disabled, &:disabled {
    color: black;
    border: none;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: right;
  margin: 1rem 0;
  gap: 1rem;
`;

export const WarningButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.red};
  width: 8rem;
  color: white;
`;

export const StandardButton = styled(Button)`
  background-color: ${({ theme }) => theme.color.lightOrange};
  width: 8rem;
  color: white;
  
  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}60`};
  }
`;

export const InputLabel = styled(InputLabelBase)`
  padding-left: 0.8rem;
`;

export const Container = styled.div`
  background: white;
`;

export const TableGroupHeading = styled.h6`
  text-align: left;
  margin-top: 0.5rem;
`;

export const InputTextField = styled(TextField)`
  max-width: 5rem;
`;

export const BottomRow = styled.div`
  width: calc(100% - 2rem);
  margin: 0 1rem 1rem 1rem;
  font-size: 1rem;
`;
