import styled from 'styled-components';

export const AddressSearchSummaryWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.beige};
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AddressSearchSummaryText = styled.div`
  max-width: 40rem;
`;

export const AddressSearchChangeLink = styled.div`
  cursor: pointer;
  margin: 1rem 0;
  color: #f7a618;
  width: fit-content;
  :hover { 
    color: ${(props) => props.theme.color.darkOrange}
  }
  transition: all 0.2s ease-out;
`;

export const WhitelabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
