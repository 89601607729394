import styled from 'styled-components';

export const BodyWrapper = styled.div`
  ${({ theme }) => theme.style.basePageStyle}
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  background: ${({ theme }) => theme.color.beige};
  padding: 0.5rem;
`;

export const Image = styled.img`
  width: 100%;
  margin: 5rem 0;

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    margin: 3rem 0;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    margin: 1rem 0;
  }
`;

export const TextColorWrapper = styled.span`
  color: ${({
    $red,
    $green,
    $blue,
    $alpha,
  }) => `rgba(${$red}, ${$green}, ${$blue}, ${$alpha})`};
`;
