import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LanguageContext } from '../../../lib/Language';
import FreeTextField from './FreeTextField';
import * as S from './styles';

/**
 * @param amount {number}
 * @param language {string}
 * @returns {string}
 */
const toFormattedAmount = (amount = 0, language = 'no') => (
  `${new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'NOK',
    maximumFractionDigits: 1,
  }).format(Math.round(amount)).slice(0, -1)}-`
);

const TopListRow = ({ title, value, show, bold }) => (
  show
    ? (
      <S.TopListRow bold={bold}>
        <span>{title}</span>
        <span>{value}</span>
      </S.TopListRow>
    ) : (
      <S.TopListRow>&nbsp;</S.TopListRow>
    )
);

TopListRow.defaultProps = {
  title: '',
  value: '',
  show: true,
  bold: false,
};

TopListRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  show: PropTypes.bool,
  bold: PropTypes.bool,
};

const DashboardQuotePageMiddlePanelTakbytte = ({ quote, onUpdateFreeTextValues, saleStatus, isMerchantUser }) => {
  const { language } = useContext(LanguageContext);

  if (!quote) return null;
  const { calculations, merchant_id: merchantId, options, business_type: businessType } = quote;
  const { freeTextValues, paymentMethod } = options || {};
  // const { freeTextValues, paymentMethod, roofing } = options || {};
  const { cost } = calculations;
  // const { cost, production } = calculations;
  const additionalKeys = Object.keys(cost.additional ?? {}).filter((key) => typeof cost.additional[key] === 'object');
  const isQuoteFromMerchant = !!merchantId;
  const isBusiness = businessType === 'business';

  const taxStyle = { visibility: isBusiness ? 'collapse' : 'visible' };

  return (
    <S.MiddlePanel>
      <S.MiddlePanelHeader>
        Viser beregning for
        <b>{` ${calculations.roofCount} tak `}</b>
        på over
        <b>{` ${new Intl.NumberFormat(language).format(calculations.roofArea)} m²`}</b>
      </S.MiddlePanelHeader>
      <S.MiddlePanelMain>
        <S.TopList>
          <S.TopListSide>
            <TopListRow title="Utstyr" value={toFormattedAmount(isBusiness ? cost.equipment.total : cost.equipment.totalIncludingVat)} />
            <TopListRow title="Takmontering" value={toFormattedAmount(isBusiness ? cost.installation.total : cost.installation.totalIncludingVat)} />
            <TopListRow
              title="Tillegg"
              value={toFormattedAmount(isBusiness ? cost.sumAddition : cost.sumAdditionIncludingVat || 0)}
            />
            <TopListRow title="Prosjekt pris" bold value={toFormattedAmount(isBusiness ? cost.projectPrice : cost.projectPriceIncludingVat)} />
            <TopListRow
              title="Fratrekk"
              value={toFormattedAmount(isBusiness ? cost.sumDeduction : cost.sumDeductionIncludingVat || 0)}
            />
            <TopListRow title="Total pris" bold value={toFormattedAmount(isBusiness ? cost.total : cost.totalIncludingVat)} />
          </S.TopListSide>
          <S.TopListSide>
            <TopListRow title="Privat / bedrift:" value={isBusiness ? 'Bedrift' : 'Privat'} />
            <TopListRow title="Ønsker finansiering:" value={paymentMethod === 'loan' ? 'Ja' : 'Nei'} />
            <TopListRow title="Status:" value={saleStatus} />
            <TopListRow title="Størrelse:" value={`${new Intl.NumberFormat(language).format(calculations.roofArea)} m²`} />
            <TopListRow title="Antall tak:" value={`${calculations.roofCount}`} />
            <TopListRow title="Taktype:" value={`${options.roofingType.productName}`} />
          </S.TopListSide>
        </S.TopList>
        <div style={{ marginTop: '41px' }}>
          <S.CalcDetailTableRow>
            <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Utstyr</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Forhandlerpris</S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Per stk.</S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Antall</S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>Eks. mva.</S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>Inkl. mva.</S.CalcDetailColumn>
          </S.CalcDetailTableRow>
          <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
            <div style={{ flex: '1' }}>{options.roofingType.productName}</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.roofingType.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.roofingType.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.roofingType.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.roofingType.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.roofingType.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Gavelstein</div>
            {isQuoteFromMerchant && !isMerchantUser && (
              <S.CalcDetailColumn>
                {toFormattedAmount(cost.equipment.ridgeTiles.totalMerchantPurchasePrice)}
              </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.ridgeTiles.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.ridgeTiles.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.ridgeTiles.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.ridgeTiles.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Lekter</div>
            {isQuoteFromMerchant && !isMerchantUser && (
              <S.CalcDetailColumn>
                {toFormattedAmount(cost.equipment.battens.totalMerchantPurchasePrice)}
              </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.battens.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.battens.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.battens.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.battens.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Rekter/Rekker</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.battensRows.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.battensRows.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.battensRows.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.battensRows.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.battensRows.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Klips</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.clips.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.clips.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.clips.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.clips.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.clips.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Undertak</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.roofCovering.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.roofCovering.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.roofCovering.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.roofCovering.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.roofCovering.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Festemateriell</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.fasteningMaterial.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.fasteningMaterial.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.fasteningMaterial.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.fasteningMaterial.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.fasteningMaterial.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Takrenne</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.gutter.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.gutter.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.gutter.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.gutter.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.gutter.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Nedløp</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.drain.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.drain.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.drain.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.drain.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.drain.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Stilas</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.scaffolding.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.scaffolding.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.scaffolding.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.scaffolding.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.scaffolding.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Konteiner</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.container.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.container.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.container.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.container.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.container.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Diverse</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.others.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.others.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.equipment.others.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.equipment.others.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.equipment.others.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>&nbsp;</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
              {toFormattedAmount(cost.equipment.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn />
            <S.CalcDetailColumn />
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
              {toFormattedAmount(cost.equipment.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
              {toFormattedAmount(cost.equipment.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
            <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Takmontering</div>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Ekstra kostnad takhelling over 35°</div>
            {isQuoteFromMerchant && !isMerchantUser && (
              <S.CalcDetailColumn>
                {toFormattedAmount(cost.installation.extraCostHighPitch.totalMerchantPurchasePrice)}
              </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.extraCostHighPitch.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.installation.extraCostHighPitch.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.extraCostHighPitch.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.installation.extraCostHighPitch.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Oppstart</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.startupCost.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.startupCost.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.installation.startupCost.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.startupCost.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.installation.startupCost.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow style={{ paddingTop: '5px' }}>
            <div style={{ flex: '1' }}>Ekstra kostnad per tak</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.extraCostPerRoof.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.extraCostPerRoof.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.installation.extraCostPerRoof.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.extraCostPerRoof.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.installation.extraCostPerRoof.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Arbeid</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.roofingWork.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.roofingWork.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.installation.roofingWork.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.roofingWork.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.installation.roofingWork.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Arbeid per tak</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.roofingWorkPerRoof.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.roofingWorkPerRoof.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.installation.roofingWorkPerRoof.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.roofingWorkPerRoof.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.installation.roofingWorkPerRoof.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Prosjektering fastpris</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.engineeringCostPerInstallation.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.engineeringCostPerInstallation.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.installation.engineeringCostPerInstallation.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.engineeringCostPerInstallation.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.installation.engineeringCostPerInstallation.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>Prosjektering per tak</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.engineeringCostPerRoof.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.engineeringCostPerRoof.netPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {cost.installation.engineeringCostPerRoof.quantity}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn>
              {toFormattedAmount(cost.installation.engineeringCostPerRoof.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={taxStyle}>
              {toFormattedAmount(cost.installation.engineeringCostPerRoof.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow>
            <div style={{ flex: '1' }}>&nbsp;</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
              {toFormattedAmount(cost.installation.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn />
            <S.CalcDetailColumn />
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
              {toFormattedAmount(cost.installation.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
              {toFormattedAmount(cost.installation.totalIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
            <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Ekstra tilvalg</div>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          {additionalKeys?.map((key) => (
            <S.CalcDetailTableRow key={key}>
              <div style={{ flex: '1' }}>{cost.additional[key].productName}</div>
              {isQuoteFromMerchant && !isMerchantUser && (
                <S.CalcDetailColumn>
                  {toFormattedAmount(cost.additional[key].totalMerchantPurchasePrice)}
                </S.CalcDetailColumn>
              )}
              <S.CalcDetailColumn>
                {toFormattedAmount(cost.additional[key].netPrice)}
              </S.CalcDetailColumn>
              <S.CalcDetailColumn>
                {cost.additional[key].quantity}
              </S.CalcDetailColumn>
              <S.CalcDetailColumn>
                {toFormattedAmount(cost.additional[key].total)}
              </S.CalcDetailColumn>
              <S.CalcDetailColumn style={taxStyle}>
                {toFormattedAmount(cost.additional[key].totalIncludingVat)}
              </S.CalcDetailColumn>
            </S.CalcDetailTableRow>
          ))}

          <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
            <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Tillegg</div>
          </S.CalcDetailTableRow>
          <FreeTextField index={0} freeTextValues={freeTextValues} onSubmit={onUpdateFreeTextValues} isBusiness={isBusiness} />
          <FreeTextField index={1} freeTextValues={freeTextValues} onSubmit={onUpdateFreeTextValues} isBusiness={isBusiness} />
          <FreeTextField index={2} freeTextValues={freeTextValues} onSubmit={onUpdateFreeTextValues} isBusiness={isBusiness} />
          <S.CalcDetailTableRow style={{ justifyContent: 'end' }}>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
              {toFormattedAmount(cost.sumAddition)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
              {toFormattedAmount(cost.sumAdditionIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
            <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Prosjektpris</div>
            {isQuoteFromMerchant && !isMerchantUser && (
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
              {toFormattedAmount(cost.totalMerchantPurchasePrice)}
            </S.CalcDetailColumn>
            )}
            <S.CalcDetailColumn />
            <S.CalcDetailColumn />
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
              {toFormattedAmount(cost.projectPrice)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
              {toFormattedAmount(cost.projectPriceIncludingVat)}
            </S.CalcDetailColumn>
          </S.CalcDetailTableRow>

          <S.CalcDetailTableRow style={{ marginTop: '41px' }}>
            <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Fratrekk</div>
          </S.CalcDetailTableRow>
          <FreeTextField index={3} freeTextValues={freeTextValues} onSubmit={onUpdateFreeTextValues} isBusiness={isBusiness} />

          <S.CalcDetailTableRow style={{ borderBottom: '0' }}>
            <div style={{ flex: '1', fontWeight: 'bold', paddingBottom: '5px' }}>Total pris</div>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>&nbsp;</S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold' }}>
              {toFormattedAmount(cost.total)}
            </S.CalcDetailColumn>
            <S.CalcDetailColumn style={{ fontWeight: 'bold', visibility: isBusiness ? 'collapse' : 'visible' }}>
              {toFormattedAmount(cost.totalIncludingVat)}
            </S.CalcDetailColumn>
            { isBusiness ? <S.CalcDetailColumn /> : null }
          </S.CalcDetailTableRow>
        </div>
      </S.MiddlePanelMain>
    </S.MiddlePanel>
  );
};

DashboardQuotePageMiddlePanelTakbytte.defaultProps = {
  quote: undefined,
  onUpdateFreeTextValues: () => {},
  saleStatus: '',
  isMerchantUser: false,
};

const prices = {
  netPrice: PropTypes.number,
  quantity: PropTypes.number,
  total: PropTypes.number,
  totalIncludingVat: PropTypes.number,
  totalMerchantPurchasePrice: PropTypes.number,
};

DashboardQuotePageMiddlePanelTakbytte.propTypes = {
  saleStatus: PropTypes.string,
  onUpdateFreeTextValues: PropTypes.func,
  isMerchantUser: PropTypes.bool,
  quote: PropTypes.shape({
    county_number: PropTypes.number,
    roofs: PropTypes.arrayOf(PropTypes.shape({})),
    merchant_id: PropTypes.string,
    business_type: PropTypes.string,
    uuid: PropTypes.string,
    options: PropTypes.shape({
      freeTextValues: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.number,
        isDiscount: PropTypes.bool,
      })),
      roofingType: PropTypes.shape({
        productName: PropTypes.string,
        price: PropTypes.number,
      }),
      isQuoteOrdered: PropTypes.bool,
      paymentMethod: PropTypes.string,
    }),
    calculations: PropTypes.shape({
      roofCount: PropTypes.number,
      roofArea: PropTypes.number,
      cost: PropTypes.shape({
        sumAddition: PropTypes.number,
        sumAdditionIncludingVat: PropTypes.number,
        sumDeduction: PropTypes.number,
        sumDeductionIncludingVat: PropTypes.number,
        projectPrice: PropTypes.number,
        projectPriceIncludingVat: PropTypes.number,
        totalMerchantPurchasePrice: PropTypes.number,
        totalIncludingVat: PropTypes.number,
        total: PropTypes.number,
        installation: PropTypes.shape({
          engineeringCostPerInstallation: PropTypes.shape(prices),
          engineeringCostPerRoof: PropTypes.shape(prices),
          startupCost: PropTypes.shape(prices),
          extraCostPerRoof: PropTypes.shape(prices),
          roofingWork: PropTypes.shape(prices),
          roofingWorkPerRoof: PropTypes.shape(prices),
          extraCostHighPitch: PropTypes.shape(prices),
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
        }),
        equipment: PropTypes.shape({
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
          battens: PropTypes.shape(prices),
          battensRows: PropTypes.shape(prices),
          clips: PropTypes.shape(prices),
          roofCovering: PropTypes.shape(prices),
          others: PropTypes.shape(prices),
          fasteningMaterial: PropTypes.shape(prices),
          container: PropTypes.shape(prices),
          gutter: PropTypes.shape(prices),
          drain: PropTypes.shape(prices),
          scaffolding: PropTypes.shape(prices),
          ridgeTiles: PropTypes.shape(prices),
          roofingType: PropTypes.shape(prices),
        }),
        additional: PropTypes.shape({
          total: PropTypes.number,
          totalIncludingVat: PropTypes.number,
          totalMerchantPurchasePrice: PropTypes.number,
        }),
      }),
    }),
  }),
};

export default DashboardQuotePageMiddlePanelTakbytte;
