import React from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const CustomTable = ({ title, rows }) => (
  <>
    <h3>{title}</h3>
    <Table>
      <TableBody>
        {
          rows.map(({ title: rowTitle, value, isUrl = false }) => (
            <TableRow key={`${rowTitle}-${value}`}>
              <TableCell align="left">{rowTitle}</TableCell>
              <TableCell align="right">
                {
                  isUrl ? <a href={value}>{value}</a> : value
                }
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  </>
);

CustomTable.defaultProps = {
  title: '',
  rows: [],
};

CustomTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.string,
    isUrl: PropTypes.bool,
  })),
};

export default CustomTable;
