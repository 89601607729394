import imageUrlBuilder from '@sanity/image-url';
import { insertInToHtmlDoc, insertInToCell, insertInToRow, insertInToTable } from './htmlForEmail';
import myConfiguredSanityClient from '../sanityClient';

const HALF_IMAGE_WIDTH = 240;
const FULL_IMAGE_WIDTH = 500;

const EMPTY_STRING = '<span style="color: white;">&#10240;<br /></span>';

const REPLACEMENT_VALUES = [
  { name: 'name', tag: '%NAME%', value: '' },
  { name: 'calculations.cost.total', tag: '%TOTAL_PRICE%', value: 0, AddSpecs: true },
  { name: 'calculations.cost.monthlyPayment', tag: '%MONTHLY_PAYMENT%', value: 0, AddSpecs: true },
  { name: 'calculations.cost.enovaFunding', tag: '%ENOVA_FUNDING%', value: 0, AddSpecs: true },
  { name: 'calculations.production.roofCount', tag: '%ROOFCOUNT%', value: '' },
  { name: 'calculations.production.panelCount', tag: '%PANELCOUNT%', value: '' },
  { name: 'calculations.production.co2EmissionPrevented', tag: '%CO2%', value: 0, AddSpecs: true },
  { name: 'calculations.production.emissionInNumberOfCars', tag: '%CARCOUNT%', value: 0, AddSpecs: true },
  { name: 'calculations.production.numberOfBergenOsloTrips', tag: '%BERGENOSLOCOUNT%', value: 0, AddSpecs: true },
  { name: 'calculations.production.peakPower', tag: '%TOTALPEAKPOWER%', value: 0, AddSpecs: true },
  { name: 'calculations.production.estimatedAnnualProduction', tag: '%ESTIMATEDANNUALPRODUCTION%', value: 0, AddSpecs: true },
  { name: 'calculations.production.estimatedTotalProduction', tag: '%ESTIMATEDTOTALPRODUCTION%', value: 0, AddSpecs: true },
  { name: 'calculations.production.productionInAnnualConsumption', tag: '%PRODUCTIONINYEARS%', value: 0, AddSpecs: true },
  { name: 'calculations.production.estimatedAnnualSavings', tag: '%ANNUALSAVINGS%', value: 0, AddSpecs: true },
  { name: 'calculations.production.estimatedTotalSavings', tag: '%TOTALSAVINGS%', value: 0, AddSpecs: true },
  { name: 'settings.environment.electricityPrice', tag: '%ELECTRICITYPRICE%', value: 0, AddSpecs: true },
  { name: 'settings.prices.loanInterestRate', tag: '%INTERESTRATE%', value: '' },
  { name: 'settings.prices.loanTermLength', tag: '%TERMLENGTH%', value: '' },
  { name: 'settings.prices.loanTermFee', tag: '%TERMFEE%', value: 0, AddSpecs: true },
];

const textMarkStyleMap = {
  strong: 'font-weight: bold;',
  em: 'font-style: italic;',
  underline: 'text-decoration: underline;',
};

const builder = imageUrlBuilder(myConfiguredSanityClient);

/**
 * get url for an image from sanity
 * @param ref {string}
 * @param width {int}
 * @returns {string}
 */
const getImageUrl = (ref, width = FULL_IMAGE_WIDTH) => (!ref
  ? ''
  : builder
    .image(ref)
    .width(width)
    .format('jpg')
    .quality(90)
    .toString()
);

const getSeparator = ({ visible }) => (
  insertInToRow(`<td style="background-color: ${visible ? '#f07f17' : '#fff'}; width: 542px; height: 14px; margin: 8px 0"></td>`)
);

const addLinkToText = (markDefs, marks, uuid, text) => {
  const markDef = markDefs[0] || {};
  if (!marks.some((mark) => mark === markDef._key)) return text;

  const linkUrl = markDef.isQuotePageLink
    ? `${window.location.origin}/quote/${uuid}`
    : markDef.url;

  return linkUrl
    ? `<a href="${linkUrl}" target="_blank" style="text-decoration: none; color: #0000FFFF;">${text}</a>`
    : text;
};

export const replaceTagsWithValue = (text, replacementValues) => text.replace(/%\w+%/g, ((variable) => replacementValues[variable] || variable));

const blockParser = ({ children = [], markDefs = [], style = 'normal' }, replacementValues, uuid) => {
  const textWithDecoration = children.map(({ marks = [], text = '' }) => {
    const textStyle = marks.map((mark) => textMarkStyleMap[mark] || '').join('');
    const textWithReplacedTags = replaceTagsWithValue(text, replacementValues);
    const textWithLink = markDefs.length
      ? addLinkToText(markDefs, marks, uuid, textWithReplacedTags)
      : textWithReplacedTags;

    return textStyle
      ? `<span style="${textStyle}">${textWithLink || EMPTY_STRING}</span>`
      : (textWithLink || EMPTY_STRING);
  }).join('');

  switch (style) {
    case 'normal': return `<div style="font-size: 14px;">${textWithDecoration}</div>`;
    case 'title-c-l': return `<div style="width: 100%; text-align: center"><h2 style="font-size: 28px; margin: 20px 0 10px">${textWithDecoration}</h2></div>`;
    case 'title-c-s': return `<div style="width: 100%; text-align: center"><h3 style="font-size: 20px; margin: 10px 0 0">${textWithDecoration}</h3></div>`;
    case 'h2': return `<h2 style="font-size: 28px; margin: 20px 0 10px">${textWithDecoration}</h2>`;
    case 'h3': return `<h3 style="font-size: 20px; margin: 10px 0 0">${textWithDecoration}</h3>`;
    default: return '';
  }
};

const image = ({ asset = {}, imageWidth = '', text = [], title = '', customImageWidth = '' }, replacementValues, uuid) => {
  const { _ref: ref } = asset;
  if (!ref) return '';

  const customImageWidthAsInt = Number.parseInt(customImageWidth, 10);
  if (imageWidth === 'custom-width' && customImageWidthAsInt >= 0) {
    return insertInToRow(insertInToCell(
      `<img src="${getImageUrl(ref, customImageWidthAsInt)}" style="width: ${customImageWidthAsInt}px; margin: 0;" alt="${title}"/>`,
    ));
  }

  if (imageWidth === 'full-width') {
    return insertInToRow(insertInToCell(
      `<img src="${getImageUrl(ref, FULL_IMAGE_WIDTH)}" style="width: 500px; margin: 0;" alt="${title}"/>`,
    ));
  }

  const imageText = text.map((block) => blockParser(block, replacementValues, uuid)).join('');
  const children = [imageText, `<img src="${getImageUrl(ref, HALF_IMAGE_WIDTH)}" style="width: 240px;" alt="${title}"/>`];

  return insertInToRow(insertInToCell(imageWidth === 'half-width-right' ? children : children.reverse(), 240));
};

const parser = (elements, replacementValues, uuid) => elements.map((element) => {
  switch (element._type) {
    case 'image': return insertInToTable(image(element, replacementValues, uuid), 500);
    case 'separator': return insertInToTable(getSeparator(element), 540);
    case 'block': return insertInToTable(insertInToRow(insertInToCell(blockParser(element, replacementValues, uuid))), 500);
    default: return '';
  }
});

export const getValuesFromObj = (quote = {}, settings = {}) => {
  const obj = { ...quote, settings };
  const isObject = (object) => object != null && typeof object === 'object' && !Array.isArray(object);

  const newObj = [];

  REPLACEMENT_VALUES.forEach(({ name = '', value, tag, AddSpecs }) => {
    const tempArr = name.split('.');
    let tempObj = obj;

    tempArr.forEach((key, index) => {
      if (index === tempArr.length - 1) {
        newObj[tag] = AddSpecs && typeof value === 'number'
          ? Number(tempObj[key] ?? value).toLocaleString('no-NO', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
          : tempObj[key] ?? value;
      } else if (isObject(tempObj[key])) {
        tempObj = tempObj[key];
      } else {
        newObj[tag] = value;
      }
    });
  });

  return newObj;
};

const portableTextToHtml = (blocks = [], emailSubject = '', quote = {}, settings = {}) => {
  const replacementValues = getValuesFromObj(quote, settings);
  const children = parser(blocks, replacementValues, quote.uuid);

  return {
    emailSubject: replaceTagsWithValue(emailSubject, replacementValues),
    emailHtml: insertInToHtmlDoc(children.join('')), // .replace(/(\r\n|\n|\r)/gm, ''),
  };
};

export default portableTextToHtml;
