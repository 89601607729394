import styled from 'styled-components';
import Button from '@mui/material/Button';
import { MapContainer as MapContainerBase } from 'react-leaflet';
import DialogBase from '@mui/material/Dialog';
import DialogActionsBase from '@mui/material/DialogActions';
import SelectBase from '@mui/material/Select';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.color.primaryDark};
`;

export const Header = styled.div`
  border-bottom: 1px solid #e8e2dc;
  height: 3rem;
  display: flex;
  gap: 7px;
  margin-bottom: 1rem;
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  max-width: ${({ printingStatus }) => (printingStatus > 0 && printingStatus < 4 ? '70rem;' : '100rem')}

  @media print {
    padding-top: 3rem;
  }
`;

export const UpperPanel = styled.div`
  display: flex;
  padding-top: 1rem;
`;

export const UpperLeftPanel = styled.div`
  width: 40%;
`;

export const ButtonWrapper = styled.div`
  @media print {
    display: none;
  }
`;

export const UpperRightPanel = styled.div`
  width: 60%;
`;

export const MiddlePanel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
`;

export const MiddlePanelHeader = styled.div`
  background-color: white;
  padding: 1rem 2rem;
  font-weight: 300;
  font-size: 1.1rem;
  border-bottom: 2px solid #f0ece8;

  @media print {
    border: none;
    font-weight: 400;
    font-size: 1.4rem;
    padding: 0;

    // controls distance from upper map to first list in first page.
    margin: 7rem 0 1rem;
    counter-increment: page;
  }
`;

export const MiddlePanelMain = styled.div`
  background-color: white;
  padding: 0 2rem;

  @media print {
    // controls distance from "total pris" middle to the map in last page.
    margin-bottom: 20rem;
    padding: 0 2rem 0 0;
    counter-increment: page;
  }
`;

export const TopList = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #f0ece8;
  margin-bottom: 8rem;
  
  @media print {
    border: none;
    flex-direction: column-reverse;
    
    // Controls distance between first and second list. distance between first and second page.
    margin-bottom: 10rem;
  }
`;

export const TopListSide = styled.div`
  width: 46%;

  @media print {
    margin-bottom: 2rem;
    width: 100%;
  }
`;

export const TopListRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  padding-top: .4rem;

  &:not(:last-child) {
    border-bottom: 1px solid #f0ece8;
  }
  
  &:last-child {
    @media print {
      border-bottom: 2px solid #f0ece8;
    }
  }

  ${({ bold }) => (bold ? `
    font-weight: bold;
  ` : '')}
`;

export const LowerMapPanelHeader = styled.div`
  margin-bottom: 1rem;
  
  @media print {
    display: none;
  }
`;

export const LowerMapPanel = styled.div`
  @media print {
    margin-top: 3rem;
  }
`;

export const LowerTable = styled.div`
  padding-bottom: 20px;
`;

export const StandardButton = styled(Button)`
  width: ${({ width = '8rem' }) => width};
  background-color: ${({ theme }) => `${theme.color.lightOrange}`};
  margin: 3.3rem 1rem 0 0;
  color: ${({ enabled = 'true' }) => (enabled === 'true' ? 'white' : 'rgba(0, 0, 0, 0.26)')};

  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}90`};
  }

  @media print {
    display: none;
  }
`;

export const MapContainer = styled(MapContainerBase)`
  height: ${({ height = '40rem' }) => height};
  width: auto;
  text-align: left;

  @media print {
    .leaflet-bottom, .leaflet-control {
      display: none;
    }
  }
`;

export const CalcDetailTableRow = styled.div`
  display: flex; 
  border-bottom: 1px solid #f0ece8;
  padding-top: .5rem;
  font-weight: 300;
  font-size: 1.15rem;
`;

export const CalcDetailColumn = styled.div`
  flex: 0 0 180px;
  text-align: right;
  font-variant-numeric: tabular-nums;
`;

export const LowerMapTableRow = styled.tr`
  will-change: background-color;
  background-color: transparent;
  transition: background-color 2s;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
  transition-delay: 0s;
`;

export const LowerMapTableColumn = styled.td`
  padding: 10px 10px 6px;
`;

export const FreeTextFieldContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #f0ece8;
  *::before { border: none; }
  
  >:nth-child(1) {
    width: calc(100% - 25rem);
    max-width: 25rem;
  }
  
  >:nth-child(2) {
    display: inline-flex;
    font-weight: 300;
    font-size: 1.15rem;
    width: 18rem;
    
    > div {
      width: 8rem;
      &:last-child {
        padding-top: 3px;
        width: 50%;
        margin-left: 1rem;
        text-align: end;
        font-variant-numeric: tabular-nums;
      }
    }
    
    > span {
      padding-top: 3px;
      padding-right: 5px;
    }
  }
  
  svg {
   fill: green;
  }
    
  .rotate {
    fill: black;
    animation: ckw infinite 1s linear;
    @keyframes ckw {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
    }
  }
  
  ${({ hasKeyAndValue }) => (hasKeyAndValue ? '' : '@media print { display: none; }')}  
  
  input {
    font-family: 'BrandonText',sans-serif;
    font-weight: 300;
    font-size: 1.15rem;
    color: ${({ theme }) => theme.color.black};
  }
`;

export const Dialog = styled(DialogBase)`
  .MuiDialog-paper {
    background-color: ${({ theme }) => theme.color.lighterGray};
  }
`;

export const PopUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
  gap: 1rem;
`;

export const EmailForm = styled.div`
  display: inline-flex;
  width: 100%;
  padding-left: 1rem;
  gap: 1rem;

  > * {
    width: calc(33% - 1rem);
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 16rem);
  border: none;
  background-color: #f0f0f0;
  border-radius: 4px;
`;

export const ErrorText = styled.div`
  margin: 1rem;
  padding: 1rem 0;
  border-bottom: black solid 2px;
  
  *:first-child {
    color: ${({ theme }) => theme.color.red};
  }
`;

export const DialogActions = styled(DialogActionsBase)`
  svg {
    fill: green;
  }

  .rotate {
    fill: black;
    animation: ckw infinite 1s linear;
    @keyframes ckw {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Select = styled(SelectBase)`
  font-family: 'BrandonText', serif;
  height: 31px;
  margin-top: 0.6rem;
  padding-left: 0.8rem;
`;
