import styled from 'styled-components';
import ButtonBase from '@mui/material/Button';
import CheckboxBase from '@mui/material/Checkbox';
import FormControlLabelBase from '@mui/material/FormControlLabel';
import RadioBase from '@mui/material/Radio';
import RadioGroupBase from '@mui/material/RadioGroup';
import TextFieldBase from '@mui/material/TextField';

import { Link as LinkBase } from 'react-router-dom';

export const Container = styled.div`
  margin-top: 5rem;
  width: 100%;
  max-width: 40rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    max-width: 35rem;
    margin-top: 3.5rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    max-width: 30rem;
    margin-top: 2rem;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`;

export const TextField = styled(TextFieldBase)`
  font-weight: 700;
  text-align: left;
  
  

  & fieldset {
    border-radius: 1rem;
    border: ${({ theme }) => `2px solid ${theme.color.black}`};
  }

  & input {
    background: ${({ theme }) => theme.color.white};
    border-radius: 1rem;
  }

  .MuiSelect-select {
    background: ${({ theme }) => theme.color.white};
    border-radius: 1rem;
  }
  
  .MuiSelect-selectMenu {
    background: ${({ theme }) => theme.color.white};
    border-radius: 1rem;
  }
`;

export const RadioGroup = styled(RadioGroupBase)`
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.color.white};
  border: ${({ theme }) => `2px solid ${theme.color.black}`};
`;

export const FormControlLabel = styled(FormControlLabelBase)`
  :not(:last-child) {
    padding-right: 2rem;

    @media (max-width: ${({ theme }) => theme.screen.md}) {    
      padding-right: 1rem;
    }
  }
`;

export const Radio = styled(RadioBase)`
  &.MuiRadio-root {
    color: ${({ theme }) => theme.color.primaryDark};
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.color.orange};
  }
`;

export const Checkbox = styled(CheckboxBase)`
  &.MuiCheckbox-root {
    color: ${({ theme }) => theme.color.primaryDark};
  }
  &.Mui-checked {
    color: ${({ theme }) => theme.color.orange};
  }
`;

export const Link = styled(LinkBase)`
  
`;

export const Button = styled(ButtonBase)`
  width: 12rem;
  height: 4rem;
  border-radius: 1rem;
  background: ${({ theme }) => theme.color.orange};
  color: ${({ theme }) => theme.color.primaryLight};
  font-weight: 700;

  :hover {
    background: ${({ theme }) => theme.color.lightOrange};
  }
`;
