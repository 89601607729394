import styled from 'styled-components';
import { MapContainer as MapContainerBase } from 'react-leaflet';
import SwitchBase from '@mui/material/Switch';
import SliderBase from '@mui/material/Slider';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NumberInputField from '../../lib/NumberInputField';

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 6fr;
  background: ${({ theme }) => theme.color.primaryLight};
  grid-gap: 0.5rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    display: flex;
    flex-direction: column;
  }
`;

export const RoofList = styled.div`
  height: 30rem;
  display: flex;
  flex-direction: column;
`;

export const RoofListInnerWrapper = styled.div`
  flex-grow: 1;
  min-height: 0;
  overflow-y: scroll;
`;

export const RoofWrapper = styled.div`
  background: ${(props) => (props.$isSelected ? props.theme.color.primaryDark : 'transparent')};
  color: ${(props) => (props.$isSelected ? props.theme.color.primaryLight : props.theme.color.primaryDark)};
  margin: 0;
  max-height: ${({ $isSelected }) => ($isSelected ? '40rem' : '3rem')};
  border-bottom: ${(props) => (props.$isSelected ? '2px solid' : '1px solid')};
  border-color: ${(props) => (props.$isSelected ? props.theme.color.primaryLight : props.theme.color.primaryDark)};
  will-change: transform;
  transition: max-height 0.1s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const RoofHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
  text-align: left;
`;

export const RoofBody = styled.div`
  width: 100%;
  padding: 0 1rem 1rem 1rem;
`;

export const RoofTitle = styled.h5`
  flex-grow: 1;
`;

export const Switch = styled(SwitchBase)`
  .MuiSwitch-switchBase {
    &.Mui-checked, .MuiTouchRipple-root {
      color: ${({ theme }) => theme.color.primaryHover};
    }

    &.Mui-checked + .MuiSwitch-track {
      background: ${({ theme }) => theme.color.primaryHover};
    }

    &:hover {
      background: ${(props) => (props.$isSelected ? `${props.theme.color.lightOrange}20` : `${props.theme.color.lightOrange}70`)};
    }
  }
`;

export const InnerHeading = styled.h6`
  text-align: left;
`;

export const HorizontalFlex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
`;

export const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5rem;
  line-height: 1.3rem;
`;

export const RoofDimensionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 2rem;
`;

export const Slider = styled(SliderBase)`
  width: calc(100% - 1rem);
  margin-left: 0.5rem;

  &.MuiSlider-root {
    margin-top: 6px;
    height: 24px;

    .MuiSlider-thumb {
      height: 24px;
      width: 24px;   
      color: ${({ theme }) => theme.color.lightOrange};
    }

    .MuiSlider-thumb:focus {
      box-shadow: 0 0 0 8px ${({ theme }) => `${theme.color.primaryHover}50`};
    }

    .MuiSlider-thumb:hover {
      box-shadow: 0 0 0 8px ${({ theme }) => `${theme.color.primaryHover}70`};
    }

    .MuiSlider-thumb:active {
      box-shadow: 0 0 0 12px ${({ theme }) => `${theme.color.primaryHover}70`};
    }

    .MuiSlider-rail, .MuiSlider-track {
      height: 8px;
      color: ${({ theme }) => theme.color.orange}
    }

    .MuiSlider-mark {
      background: ${({ theme }) => theme.color.white};
      height: 8px;
      width: 4px;
      border-radius: 4px;
      margin-left: -2px;
    }
  }
`;

export const NewRoofInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  width: 100%;
`;

export const NewRoofInput = styled(NumberInputField)`
  margin-top: 1rem;
  width: 100%;
  .MuiInputBase-input {
    padding: 0.5rem;
  }

  label, label.Mui-focused {
    color: ${({ theme }) => theme.color.primaryLight};
    padding: 0;
  }

  .MuiOutlinedInput-root {
    color: ${({ theme }) => theme.color.primaryLight};
    fieldset {
      border-color: ${({ theme }) => theme.color.primaryLight};;
    }
    :hover fieldset {
      border-color: ${({ theme }) => theme.color.primaryLight};;
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.color.primaryLight};;
    }
  }
`;

export const AddRoofWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
`;

export const AddRoofButton = styled(AddIcon)`
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.orange};
  color: ${({ theme }) => theme.color.primaryDark};
  padding: 0.2rem;
  margin-right: 0.5rem;
  transition: all 0.1s ease-in-out;
  cursor: pointer;

  :hover {
    background: ${({ theme }) => theme.color.lightOrange};
  }
`;

export const RemoveRoofButton = styled(DeleteForeverIcon)`
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.35rem;
  color: ${(props) => (props.$isSelected ? props.theme.color.lightOrange : props.theme.color.darkPrimary)};
  position: relative;
  background: transparent;
  border-radius: 50%;
  cursor: pointer;

  :hover {
    background: ${(props) => (props.$isSelected ? `${props.theme.color.lightOrange}20` : `${props.theme.color.lightOrange}70`)};
  }
`;

export const AddRoofLink = styled.div`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export const Warning = styled.div`
  width: 100%;
  margin-top: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.color.red};
  background-color: ${({ theme }) => `${theme.color.primaryLight}`};
  padding: 1rem;
  border-radius: 1rem;
`;

export const PvgisCalculationProgressIcon = styled(CircularProgress)`
  color: ${({ theme }) => theme.color.white};
`;

export const PanelSummary = styled.div`  
  width: 100%;
  background: ${({ theme }) => theme.color.primaryLight};
  color: ${({ theme }) => theme.color.primaryDark};
  padding: 1rem;
  border-radius: 1rem;
  font-weight: 400;
  text-align: left;
`;

export const MapContainer = styled(MapContainerBase)`
  height: 30rem;
  width: auto;
  text-align: left;

  &.leaflet-map-pane {
    cursor: progress;
  }
`;

export const TextOverlay = styled.div`
  left: 50%;
  transform: translateX(-50%);
  margin-top: 12px;
  height: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 2px 2px 5px #00000050;
  padding: 0 1rem;
`;
