/* eslint-disable no-param-reassign */
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';
import { format, parseISO } from 'date-fns';
import getDistinguishableColors from '../getDistinguishableColors';
import {
  BUSINESS_TYPE_MAPPING,
  getDeviation,
  getDeviationHeaders,
  getRoofColumns,
  getPriceBasedOnCustomerType,
} from './common';

const takbytteExportQuotesXlsxFile = async (quotes, roofTypesMap, settings, merchantList = []) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet('Beregninger');

  const RoofGroupColumnWidth = 6;
  const ColumnIndexOfFirstRoofGroup = 50;

  // Prepare columns with header and width
  const { roofColumns, maxSelectedRoofCount } = getRoofColumns(quotes, [
    { header: 'lengde', key: 'Length' },
    { header: 'bredde', key: 'Width' },
    { header: 'areal', key: 'Area' },
    { header: 'helning', key: 'Slope' },
    { header: 'takrenne', key: 'GutterLines' },
    { header: 'gavelstein', key: 'RidgeTilesLines' },
  ]);

  ws.columns = [
    { header: '#', key: 'id', width: 8 },
    { header: 'Dato', key: 'date', width: 12 },
    { header: 'Tid', key: 'time', width: 12 },
    { header: 'Forhandler', key: 'merchant', width: 20 },
    { header: 'Navn', key: 'name', width: 30 },
    { header: 'Adresse', key: 'address', width: 30 },
    { header: 'Postnr.', key: 'postalCode', width: 8 },
    { header: 'Poststed', key: 'city', width: 20 },
    { header: 'Fylke', key: 'county', width: 20 },
    { header: 'Kart', key: 'mapLink', width: 20 },
    { header: 'Telefon', key: 'telephone', width: 12 },
    { header: 'Epost', key: 'email', width: 35 },
    { header: 'Privat / bedrift', key: 'businessType', width: 18 },

    { header: 'Dashboard-lenke', key: 'dashboardLink', width: 20 },
    { header: 'Kunde-lenke', key: 'customerLink', width: 20 },

    { header: 'Status', key: 'status', width: 14 },

    { header: 'Taktype', key: 'roofingType', width: 25 },
    { header: 'Takareal', key: 'roofArea', width: 15 },
    { header: 'Antall tak', key: 'roofCount', width: 15 },

    { header: 'Taktype', key: 'roofingPrice', width: 12 },

    { header: 'Gavelstein', key: 'ridgeTiles', width: 12 },
    { header: 'Lekter', key: 'battens', width: 12 },
    { header: 'Rekter/Rekker', key: 'battensRows', width: 15 },
    { header: 'Klips', key: 'clips', width: 12 },
    { header: 'Undertak', key: 'roofCovering', width: 12 },
    { header: 'Festemateriell', key: 'fasteningMaterial', width: 15 },
    { header: 'Takrenne', key: 'gutter', width: 15 },
    { header: 'Nedløp', key: 'drain', width: 15 },
    { header: 'Stilas', key: 'scaffolding', width: 12 },
    { header: 'Konteiner', key: 'container', width: 12 },
    { header: 'Diverse', key: 'others', width: 12 },
    { header: 'Utstyr total', key: 'equipmentTotal', width: 15 },

    { header: 'Høy takhelling', key: 'extraCostHighPitch', width: 15 },
    { header: 'Oppstart', key: 'startupCost', width: 15 },
    { header: 'Ekstra kostnad per tak', key: 'extraCostPerRoof', width: 15 },
    { header: 'Arbeid', key: 'roofingWork', width: 15 },
    { header: 'Arbeid per tak', key: 'roofingWorkPerRoof', width: 15 },
    { header: 'Prosjektering fastpris', key: 'engineeringCostPerInstallation', width: 20 },
    { header: 'Prosjektering per tak', key: 'engineeringCostPerRoof', width: 20 },
    { header: 'Takmontering total', key: 'installationTotal', width: 20 },

    { header: 'Ekstra tilvalg total', key: 'additionsTotal', width: 20 },

    ...getDeviationHeaders(false),
    { header: 'Prosjekt pris', key: 'projectPrice', width: 15 },
    ...getDeviationHeaders(true),
    { header: 'Total pris eks mva', key: 'totalWithoutVat', width: 20 },
    { header: 'Total pris inkl mva', key: 'totalWithVat', width: 20 },
    { header: 'Månedlig sum', key: 'monthlyPayment', width: 15 },
    ...roofColumns,
  ];

  // Bold font on 1st row
  ws.getRow(1).eachCell((cell) => {
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'center' };
  });

  quotes.forEach((quote) => {
    const selectedRoofs = quote.roofs.filter((roof) => roof.isSelected);
    const { merchant_domain: merchantDomain = '' } = merchantList.find((merchant) => merchant.merchant_id === quote.merchant_id) || {};
    const currentCounty = settings.priceDifferentiation.counties.find((_county) => _county.countyNumber === quote.county_number);

    const row = {
      id: quote.id,
      date: format(parseISO(quote.timestamp), 'dd.MM.yyyy'),
      time: format(parseISO(quote.timestamp), 'HH:mm:ss'),
      merchant: quote.merchant_id
        ? (merchantList.find((merchant) => merchant.merchant_id === quote.merchant_id)?.name || '')
        : process.env.REACT_APP_COMPANY_NAME,
      name: quote.name,
      address: quote.address,
      postalCode: quote.postal_code,
      city: quote.city,
      county: currentCounty ? currentCounty.name : '',
      mapLink: {
        text: `https://www.kommunekart.com/?funksjon=VisPunkt&x=${quote.map_position.y}&y=${quote.map_position.x}&markering=1`,
        hyperlink: `https://www.kommunekart.com/?funksjon=VisPunkt&x=${quote.map_position.y}&y=${quote.map_position.x}&markering=1`,
      },
      telephone: quote.telephone,
      email: quote.email,
      businessType: BUSINESS_TYPE_MAPPING[quote.business_type],

      dashboardLink: {
        text: `${window.location.origin}/quote/${quote.uuid}`,
        hyperlink: `${window.location.origin}/quote/${quote.uuid}`,
      },
      customerLink: {
        text: merchantDomain ? `${merchantDomain}/quote/${quote.uuid}` : `${process.env.REACT_APP_PAGE_URL}/quote/${quote.uuid}`,
        hyperlink: merchantDomain ? `${merchantDomain}/quote/${quote.uuid}` : `${process.env.REACT_APP_PAGE_URL}/quote/${quote.uuid}`,
      },

      status: settings.saleStatus[quote.sale_status],

      roofingType: quote.options.roofingType.productName,
      roofArea: quote.calculations.roofArea,
      roofCount: quote.calculations.roofCount,

      roofingPrice: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.roofingType, quote.business_type),
      ridgeTiles: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.ridgeTiles, quote.business_type),
      battens: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.battens, quote.business_type),
      battensRows: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.battensRows, quote.business_type),
      clips: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.clips, quote.business_type),
      roofCovering: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.roofCovering, quote.business_type),
      fasteningMaterial: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.fasteningMaterial, quote.business_type),
      gutter: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.gutter, quote.business_type),
      drain: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.drain, quote.business_type),
      scaffolding: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.scaffolding, quote.business_type),
      container: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.container, quote.business_type),
      others: getPriceBasedOnCustomerType(quote.calculations.cost.equipment.others, quote.business_type),
      equipmentTotal: getPriceBasedOnCustomerType(quote.calculations.cost.equipment, quote.business_type),

      extraCostHighPitch: getPriceBasedOnCustomerType(quote.calculations.cost.installation.extraCostHighPitch, quote.business_type),
      startupCost: getPriceBasedOnCustomerType(quote.calculations.cost.installation.startupCost, quote.business_type),
      extraCostPerRoof: getPriceBasedOnCustomerType(quote.calculations.cost.installation.extraCostPerRoof, quote.business_type),
      roofingWork: getPriceBasedOnCustomerType(quote.calculations.cost.installation.roofingWork, quote.business_type),
      roofingWorkPerRoof: getPriceBasedOnCustomerType(quote.calculations.cost.installation.roofingWorkPerRoof, quote.business_type),
      engineeringCostPerInstallation: getPriceBasedOnCustomerType(
        quote.calculations.cost.installation.engineeringCostPerInstallation,
        quote.business_type,
      ),
      engineeringCostPerRoof: getPriceBasedOnCustomerType(quote.calculations.cost.installation.engineeringCostPerRoof, quote.business_type),
      installationTotal: getPriceBasedOnCustomerType(quote.calculations.cost.installation, quote.business_type),

      additionsTotal: quote.business_type === 'business'
        ? quote.calculations.cost.additional?.total ?? 0
        : quote.calculations.cost.additional?.totalIncludingVat ?? 0,

      projectPrice: quote.business_type === 'business'
        ? quote.calculations.cost.projectPrice
        : quote.calculations.cost.projectPriceIncludingVat,
      ...getDeviation(quote.options.freeTextValues, quote.business_type === 'business'),

      totalWithoutVat: Math.round(quote.calculations.cost.total),
      totalWithVat: Math.round(quote.calculations.cost.totalIncludingVat),
      monthlyPayment: quote.calculations.cost.monthlyPayment,
    };

    selectedRoofs.forEach((roof, index) => {
      row[`roof${index + 1}Length`] = Math.round(roof.length * 10) / 10;
      row[`roof${index + 1}Width`] = Math.round(roof.width * 10) / 10;
      row[`roof${index + 1}Area`] = Math.round(roof.area3d * 10) / 10;
      row[`roof${index + 1}Slope`] = Math.round(roof.slope * 10) / 10;
      row[`roof${index + 1}GutterLines`] = Math.round(roof.gutterLines.map((e) => e.length).reduce((a, b) => (a + b), 0) * 10) / 10;
      row[`roof${index + 1}RidgeTilesLines`] = Math.round(roof.ridgeTilesLines.map((e) => e.length).reduce((a, b) => (a + b), 0) * 10) / 10;
    });

    ws.addRow(row);
  });

  // Add borders
  const columnCount = ws.getRow(1).cellCount;
  ws.eachRow((row, rowNumber) => {
    // Add thick left border to the leftmost cell
    row.getCell(1).border = { left: { style: 'medium' } };
    for (let i = 1; i < columnCount + 1; i += 1) {
      // Add thick right border to all cells, and add thick bottom border if cells are on 1st row
      row.getCell(i).border = rowNumber !== 1 ? { right: { style: 'medium' } } : { right: { style: 'medium' }, bottom: { style: 'medium' } };
    }
  });

  // Add background colors
  const colors = getDistinguishableColors(maxSelectedRoofCount + 11);

  ws.eachRow((row) => {
    for (let colNum = 1; colNum <= ColumnIndexOfFirstRoofGroup; colNum += 1) {
      let colorIndex = -1;
      switch (true) {
        // Index and timestamp columns
        case (colNum <= 4): colorIndex = 0; break;
        // Personalia columns
        case (colNum <= 15): colorIndex = 1; break;
        // status
        case (colNum <= 16): colorIndex = 2; break;
        // Details columns
        case (colNum <= 19): colorIndex = 3; break;
        // Equipment cost columns
        case (colNum <= 32): colorIndex = 4; break;
        // Roofing work cost columns
        case (colNum <= 40): colorIndex = 5; break;
        // Additions Total
        case (colNum <= 41): colorIndex = 6; break;
        // Deviation additions columns
        case (colNum <= 44): colorIndex = 7; break;
        // Project cost column
        case (colNum <= 45): colorIndex = 8; break;
        // Deviation discount column
        case (colNum <= 46): colorIndex = 9; break;
        // Total Prise and monthly payment
        case (colNum <= 49): colorIndex = 10; break;
        // will not happen
        default: colorIndex = 0;
      }

      row.getCell(colNum).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: `FF${colors[colorIndex]}` },
      };
    }

    for (let i = 0; i < maxSelectedRoofCount; i += 1) {
      for (
        let colNum = ColumnIndexOfFirstRoofGroup + (i * RoofGroupColumnWidth);
        colNum < ColumnIndexOfFirstRoofGroup + RoofGroupColumnWidth + (i * RoofGroupColumnWidth);
        colNum += 1) {
        row.getCell(colNum).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: `FF${colors[i + 11]}` },
        };
      }
    }
  });

  // Write to file
  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${format(new Date(), 'yyyy-MM-dd HH.mm.ss')} - Solcellekraft-beregninger.xlsx`);
};

export default takbytteExportQuotesXlsxFile;
