import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const DashboardQuotePageHeader = ({ merchantList, merchantId, id }) => (
  <S.Header>
    <div>
      <b>{`Beregning ${id ?? ''}`}</b>
    </div>
    {
      merchantList.length
        ? (
          <div>
            {`for forhandler ${merchantId ? (merchantList.find((m) => m.merchant_id === merchantId)?.name || '') : process.env.REACT_APP_COMPANY_NAME}`}
          </div>
        )
        : null
    }
  </S.Header>
);

DashboardQuotePageHeader.defaultProps = {
  merchantList: [],
  merchantId: '',
  id: undefined,
};

DashboardQuotePageHeader.propTypes = {
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    merchant_id: PropTypes.string,
    name: PropTypes.string,
  })),
  merchantId: PropTypes.string,
  id: PropTypes.number,
};

export default DashboardQuotePageHeader;
