import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const RoofCountText = ({ text, roofCountText }) => text.split('%NEWPARAGRAPH%').map((paragraph) => (
  <p key={paragraph}>
    {
      paragraph.split('%ROOFCOUNT%').map((string, index) => (
        <Fragment key={string}>
          {
            index % 2 === 0
              ? string
              : (
                <>
                  <b>{roofCountText}</b>
                  {string}
                </>
              )
          }
        </Fragment>
      ))
    }
  </p>
));

RoofCountText.defaultProps = {
  text: '',
  roofCountText: '',
};

RoofCountText.propTypes = {
  text: PropTypes.string,
  roofCountText: PropTypes.string,
};

export default RoofCountText;
