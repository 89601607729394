import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import BlockContent from '@sanity/block-content-to-react';
import sanityClient from '../../lib/sanityClient';
import { LanguageContext } from '../../lib/Language';
import { SettingsContext } from '../../lib/Settings';
import { saleStatusEnum } from '../../lib/saleStatusMapping';
import Page from '../../components/Page';
import RoofSelector from '../../components/roofselector/RoofSelector';
import Text from '../../components/Text';
import QuoteStatusBar from '../../components/quotestatusbar/QuoteStatusBar';
import SolarPanelSelector from '../../components/solarpanelselector/SolarPanelSelector';
import { RoofSelectorTakbytte, RoofingTypeSelector } from '../../components';
import OptionSelector from '../../components/optionselector/OptionSelector';
import * as S from './styles';

const ROOFING_OPTIONS_STARTS_WITH = 'roofing-select-option-';
const VAT_PERCENTAGE = 25;
const IS_TAKBYTTE = process.env.REACT_APP_SITE === 'TAKBYTTE';

const getQuote = async (uuid) => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${uuid}`);
  return res.json();
};

const updateQuote = async (quoteData, settings) => {
  const body = {
    roofs: JSON.stringify(quoteData.roofs),
    options: JSON.stringify(quoteData.options),
    settings: JSON.stringify(settings),
    countyNumber: quoteData.county_number,
    merchantId: quoteData.merchant_id,
    businessType: quoteData.business_type,
    saleStatus: quoteData.sale_status,
    locked: quoteData.locked,
  };
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${quoteData.uuid}`;
  const response = await fetch(url, requestOptions).catch(console.error);
  if (response.ok || response.status === 423) {
    return response.json();
  }
  return getQuote(quoteData.uuid);
};

const getValueInCurrency = (value = 0, language, isNegative = false, addVat = false) => {
  const price = addVat ? (value * (1 + VAT_PERCENTAGE / 100)) : value;
  return `${new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'NOK',
    maximumFractionDigits: 1,
  }).format(Math.round((isNegative && price) ? (price * -1) : price)).slice(0, -1)}-`;
};

const getTotalPrices = (isBusiness, cost) => {
  // needed to support legacy calculation, not including projectPrice.
  const isLegacyCalc = cost.projectPrice == null;

  if (IS_TAKBYTTE) {
    return {
      total: (isBusiness ? cost.total : cost.totalIncludingVat) || 0,
      projectPrice: isBusiness ? cost.projectPrice : cost.projectPriceIncludingVat,
    };
  }
  return isLegacyCalc
    ? {
      total: (isBusiness ? cost.total : cost.totalIncludingVatAndEnovaFunding) || 0,
      // Project price will include enova discount
      projectPrice: (isBusiness ? cost.total : cost.totalIncludingVatAndEnovaFunding),
    }
    : {
      total: cost.total,
      // Project price will include enova discount
      projectPrice: isBusiness ? cost.projectPrice : cost.projectPriceIncludingVat,
    };
};

const PaymentMethodSelectButton = ({ children, isActive, onClick }) => (
  isActive ? (
    <S.PaymentMethodActiveSelectButton>
      {children}
    </S.PaymentMethodActiveSelectButton>
  ) : (
    <S.PaymentMethodInactiveSelectButton onClick={onClick}>
      {children}
    </S.PaymentMethodInactiveSelectButton>
  )
);

PaymentMethodSelectButton.propTypes = {
  children: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const getSanityData = async () => sanityClient.fetch(`*[_type == "page" && slug.current == "quote"]{
  title,
  blocks
}`);

const fetchData = async (uuid, settings, setQuoteData, setShowConfirm, setPageData, setShowSnackbar) => {
  if (!settings) return;

  const [
    sanityData,
    quoteData,
  ] = await Promise.all([
    getSanityData(),
    getQuote(uuid),
  ]).catch(console.error);

  // set sanity data.
  setPageData((sanityData.length === 0) ? {} : sanityData[0]);

  if (IS_TAKBYTTE) {
    setQuoteData(quoteData);
    return;
  }

  const solarPanelFromSettings = settings.solar.solarPanels
    .find((solarPanel) => solarPanel._key === quoteData.options.solarPanel?._key);
  if (solarPanelFromSettings.isActive || solarPanelFromSettings._key === settings.solar.solarPanels[0]._key) {
    setQuoteData(quoteData);
  } else {
    setQuoteData({
      ...quoteData,
      options: {
        ...quoteData.options,
        solarPanel: settings.solar.solarPanels[0],
      },
    });

    setShowSnackbar(true);
  }
};

const QuotePage = () => {
  const { uuid } = useParams();
  const [pageData, setPageData] = useState();
  const [quoteData, setQuoteData] = useState();
  const { language } = useContext(LanguageContext);
  const settings = useContext(SettingsContext);
  const getBlock = (id) => pageData.blocks.find(({ slug }) => slug.current === id);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [roofOptionData, setRoofOptionData] = useState(undefined);

  useEffect(() => {
    fetchData(uuid, settings, setQuoteData, setShowConfirm, setPageData, setShowSnackbar);

    sanityClient
      .fetch(`
      *[_type == "component" && slug.current == "quote-request-form"][0]{
        "roofingTypes": blocks[slug.current match "roofing-select-option-"] {
          "slug": slug.current,
          string
        },
        "roofOptionLabel": blocks[slug.current == "roofing-option-list-label"][0].string,
        "roofingSelectorHeading": blocks[slug.current == "roofing-selector-heading"][0].string,
      }`)
      .then(setRoofOptionData)
      .catch(console.error);
  }, [settings, setQuoteData]);

  if (!pageData || !quoteData || !settings) return <div>Loading...</div>;
  if (Object.keys(pageData).length === 0) return <div>Page data not found...</div>;

  const { production = {} } = quoteData.calculations || {};
  const roofCount = production.roofCount || quoteData.calculations.roofCount;

  const roofCountText = `${roofCount} ${roofCount === 1
    ? getBlock('roof-singular-form').string[language]
    : getBlock('roof-plural-form').string[language]}`;

  const panelCountText = `${production.panelCount || 0} ${getBlock('panel-plural-form').string[language]}`;
  const roofAreaText = `${new Intl.NumberFormat(language).format(Math.round((quoteData.calculations.production?.roofArea || 0) * 10) / 10)} m²`;
  const roofAreaTakbytteText = `${new Intl.NumberFormat(language).format(Math.round((quoteData.calculations.roofArea || 0) * 10) / 10)} m²`;

  const summaryTextString1 = getBlock('summary-text-string-1').string[language]
    .replace(/%\w+%/g, ((variable) => {
      const substring = {
        '%ROOFCOUNT%': roofCountText,
        '%PANELCOUNT%': panelCountText,
        '%ROOFAREA%': roofAreaText,
        '%ROOFAREATAKBYTTE%': roofAreaTakbytteText,
      }[variable] || variable;
      return `<b>${substring}</b>`;
    }));

  const co2EmissionText = `${new Intl.NumberFormat(language).format(production.co2EmissionPrevented)} kg CO₂`;
  const emissionInNumberOfCarsText = `${new Intl.NumberFormat(language).format(production.emissionInNumberOfCars)} ${getBlock('car-plural-form').string[language]}`;
  const numberOfBergenOsloTripsText = `${new Intl.NumberFormat(language).format(production.numberOfBergenOsloTrips)} ${getBlock('time-plural-form').string[language]}`;

  const summaryTextString2 = getBlock('summary-text-string-2').string[language]
    .replace(/%\w+%/g, ((variable) => {
      const substring = {
        '%CO2%': co2EmissionText,
        '%CARCOUNT%': emissionInNumberOfCarsText,
        '%BERGENOSLOCOUNT%': numberOfBergenOsloTripsText,
      }[variable] || variable;
      return `<b>${substring}</b>`;
    }));

  const totalPeakPowerText = `${new Intl.NumberFormat(language).format(production.peakPower)} kWp`;

  const systemSizeSellingPointSubtitle = getBlock('system-size-subtitle').string[language]
    .replace(/%\w+%/g, ((variable) => ({
      '%TOTALPEAKPOWER%': totalPeakPowerText,
      '%PANELCOUNT%': panelCountText,
    }[variable] || variable)));

  const systemSizeSellingPointText = getBlock('system-size-text').string[language]
    .replace(/%\w+%/g, ((variable) => {
      const substring = {
        '%ROOFAREA%': roofAreaText,
      }[variable] || variable;
      return `<b>${substring}</b>`;
    }));

  const estimatedAnnualProductionText = `${new Intl.NumberFormat(language).format(production.estimatedAnnualProduction)} ${getBlock('kilowatthour-unit').string[language]}`;
  const estimatedTotalProductionText = `${new Intl.NumberFormat(language).format(production.estimatedTotalProduction)} ${getBlock('kilowatthour-unit').string[language]}`;
  const productionInAnnualConsumptionText = `${new Intl.NumberFormat(language).format(production.productionInAnnualConsumption)} ${getBlock('annual-consumption-unit-text').string[language]}`;

  const estimatedProductionSellingPointSubtitle = getBlock('estimated-production-subtitle').string[language]
    .replace(/%\w+%/g, ((variable) => ({ '%ESTIMATEDANNUALPRODUCTION%': estimatedAnnualProductionText }[variable] || variable)));

  const estimatedProductionSellingPointText = getBlock('estimated-production-text').string[language]
    .replace(/%\w+%/g, ((variable) => {
      const substring = {
        '%ESTIMATEDANNUALPRODUCTION%': estimatedAnnualProductionText,
        '%ESTIMATEDTOTALPRODUCTION%': estimatedTotalProductionText,
        '%PRODUCTIONINYEARS%': productionInAnnualConsumptionText,
      }[variable] || variable;
      return `<b>${substring}</b>`;
    }));

  const estimatedAnnualSavingsText = `${new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'NOK',
    maximumFractionDigits: 1,
  }).format(Math.round(production.estimatedAnnualSavings)).slice(0, -1)}-`;

  const economySellingPointSubtitle = getBlock('economy-subtitle').string[language]
    .replace(/%\w+%/g, ((variable) => ({ '%ANNUALSAVINGS%': estimatedAnnualSavingsText }[variable] || variable)));

  const estimatedTotalSavingsText = `${new Intl.NumberFormat(language, {
    style: 'currency',
    currency: 'NOK',
    maximumFractionDigits: 1,
  }).format(Math.round(production.estimatedTotalSavings)).slice(0, -1)}-`;

  const electricityPriceText = `${new Intl.NumberFormat(language, { style: 'currency', currency: 'NOK' })
    .format(settings.environment.electricityPrice)}`;

  const economySellingPointText = getBlock('economy-text').string[language]
    .replace(/%\w+%/g, ((variable) => {
      const substring = {
        '%TOTALSAVINGS%': estimatedTotalSavingsText,
        '%ELECTRICITYPRICE%': electricityPriceText,
      }[variable] || variable;
      return `<b>${substring}</b>`;
    }));

  const environmentSellingPointSubtitle = getBlock('environment-subtitle').string[language]
    .replace(/%\w+%/g, ((variable) => ({ '%CO2%': co2EmissionText }[variable] || variable)));

  const environmentSellingPointText = getBlock('environment-text').string[language]
    .replace(/%\w+%/g, ((variable) => {
      const substring = {
        '%CARCOUNT%': emissionInNumberOfCarsText,
        '%BERGENOSLOCOUNT%': numberOfBergenOsloTripsText,
      }[variable] || variable;
      return `<b>${substring}</b>`;
    }));

  const costOverviewLoanTermsText = getBlock('cost-overview-loan-terms-text').string[language]
    .replace(/%\w+%/g, ((variable) => ({
      '%INTERESTRATE%': new Intl.NumberFormat(language).format(settings.prices.loanInterestRate),
      '%TERMLENGTH%': settings.prices.loanTermLength,
      '%TERMFEE%': (settings.prices.loanTermFee % 1 === 0
        ? `${new Intl.NumberFormat(language, {
          style: 'currency',
          currency: 'NOK',
          maximumFractionDigits: 1,
        }).format(settings.prices.loanTermFee).slice(0, -1)}-`
        : new Intl.NumberFormat(language, {
          style: 'currency',
          currency: 'NOK',
          minimumFractionDigits: 2,
        }).format(settings.prices.loanTermFee)),
    }[variable] || variable)));

  const confirmOrder = () => {
    const _quoteData = {
      ...quoteData,
      sale_status: saleStatusEnum.ORDERED,
      locked: true,
      options: {
        ...quoteData.options,
        // isQuoteOrdered is deprecated!
        isQuoteOrdered: true,
      },
    };
    return updateQuote(_quoteData, settings)
      .then(() => {
        window.location.href = `/whitelabel/confirmation/${quoteData.uuid}`;
      });
  };

  const setRoofSlider = (roofs = []) => {
    let newState = null;
    setQuoteData((prevState) => {
      newState = { ...prevState, roofs };
      return newState;
    });
    updateQuote(newState, settings)
      .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
  };

  if (!quoteData || !roofOptionData) return <div>Loading...</div>;
  const freeTextValues = quoteData.options?.freeTextValues
    ?.filter(({ value, key }) => !!(value || key))
    ?.sort((a, b) => a.isDiscount - b.isDiscount);

  const isBusiness = quoteData.business_type !== 'personal';
  const hasDeviation = !!freeTextValues?.length;
  const { total, projectPrice } = getTotalPrices(isBusiness, quoteData.calculations?.cost);

  return (
    <Page title={pageData.title[language]}>
      <S.BodyWrapper>
        <S.SummaryBanner>
          <S.SummaryText>
            <h1>
              {quoteData.address}
              <br />
              {`${quoteData.postal_code} ${quoteData.city}`}
            </h1>
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: summaryTextString1 }} />
            {/* eslint-disable-next-line react/no-danger */}
            <p dangerouslySetInnerHTML={{ __html: summaryTextString2 }} />
          </S.SummaryText>
          <div>
            <S.PaymentMethodSelectorWrapper>
              <PaymentMethodSelectButton
                isActive={quoteData.options.paymentMethod === 'loan'}
                onClick={() => {
                  const copy = { ...quoteData };
                  copy.options.paymentMethod = 'loan';
                  setQuoteData(copy);
                  if (!quoteData.locked) {
                    updateQuote(copy, settings)
                      .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
                  }
                }}
              >
                {getBlock('cost-overview-loan-button-text').string[language]}
              </PaymentMethodSelectButton>
              <PaymentMethodSelectButton
                isActive={quoteData.options.paymentMethod === 'cash'}
                onClick={() => {
                  const copy = { ...quoteData };
                  copy.options.paymentMethod = 'cash';
                  setQuoteData(copy);
                  if (!quoteData.locked) {
                    updateQuote(copy, settings)
                      .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
                  }
                }}
              >
                {getBlock('cost-overview-cash-button-text').string[language]}
              </PaymentMethodSelectButton>
            </S.PaymentMethodSelectorWrapper>
            {quoteData.options.paymentMethod === 'loan' && (
              <S.CostWrapper>
                <Text fontSize="lg" bold marginBottom="1rem">
                  {getBlock('cost-overview-loan-heading-text').string[language]}
                </Text>
                <S.HorizontalFlex>
                  <Text fontSize="md">
                    {getBlock('cost-overview-loan-down-payment-text').string[language]}
                  </Text>
                  <Text fontSize="md">
                    {`${new Intl.NumberFormat(language, {
                      style: 'currency',
                      currency: 'NOK',
                      maximumFractionDigits: 1,
                    })
                      .format(0).slice(0, -1)}-`}
                  </Text>
                </S.HorizontalFlex>
                <S.Separator />
                <S.HorizontalFlex>
                  <Text fontSize="md" bold>
                    {getBlock('cost-overview-loan-montly-payment-text').string[language]}
                  </Text>
                  <Text fontSize="md" bold>
                    {`${new Intl.NumberFormat(language, {
                      style: 'currency',
                      currency: 'NOK',
                      maximumFractionDigits: 1,
                    })
                      .format(Math.round(quoteData.calculations.cost.monthlyPayment)).slice(0, -1)}-`}
                  </Text>
                </S.HorizontalFlex>
                <S.Separator />
                {
                  !isBusiness && !IS_TAKBYTTE
                    ? (
                      <Text marginBottom="1rem">
                        {`${getBlock('cost-overview-loan-enova-funding-text').string[language]} `}
                        {`${new Intl.NumberFormat(language, {
                          style: 'currency',
                          currency: 'NOK',
                          maximumFractionDigits: 1,
                        })
                          .format(Math.round(quoteData.calculations.cost.enovaFunding)).slice(0, -1)}-`}
                        .
                      </Text>
                    ) : null
                }
                <Text marginBottom="1rem">
                  {costOverviewLoanTermsText}
                </Text>
                <Text>
                  {`${getBlock('cost-overview-loan-green-loan-text').string[language]} `}
                  <S.LoanInformationLink href="https://www.dnb.no/lan/boliglan/gront" target="_blank">
                    DNB
                  </S.LoanInformationLink>
                  {' / '}
                  <S.LoanInformationLink href="https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/gront-boliglan.html" target="_blank">
                    Nordea
                  </S.LoanInformationLink>
                </Text>
              </S.CostWrapper>
            )}
            {quoteData.options.paymentMethod === 'cash' && (
              <S.CostWrapper>
                <Text fontSize="lg" bold marginBottom="1rem">
                  {getBlock('cost-overview-cash-heading-text').string[language]}
                </Text>
                <S.HorizontalFlex>
                  {IS_TAKBYTTE ? (
                    <Text fontSize="md" bold>
                      {getBlock(isBusiness ? 'total-price-for-business-customers' : 'cost-overview-cash-price-text').string[language]}
                    </Text>
                  ) : (
                    <Text fontSize="md" bold>
                      {getBlock(isBusiness ? 'total-price-for-business-customers' : 'cost-overview-cash-price-including-enova-funding-text').string[language]}
                    </Text>
                  )}
                  <Text fontSize="md" bold>
                    {`${new Intl.NumberFormat(language, {
                      style: 'currency',
                      currency: 'NOK',
                      maximumFractionDigits: 1,
                    })
                      .format(Math.round(total)).slice(0, -1)}-`}
                  </Text>
                </S.HorizontalFlex>
                <S.Separator />
                {
                  !isBusiness && !IS_TAKBYTTE
                    ? (
                      <Text>
                        {`${getBlock('cost-overview-cash-enova-funding-text').string[language]} `}
                        {`${new Intl.NumberFormat(language, {
                          style: 'currency',
                          currency: 'NOK',
                          maximumFractionDigits: 1,
                        })
                          .format(Math.round(quoteData.calculations.cost.enovaFunding)).slice(0, -1)}-`}
                        .
                      </Text>
                    ) : null
                }
              </S.CostWrapper>
            )}
          </div>
        </S.SummaryBanner>

        {
          hasDeviation
            ? (
              <S.AdditionalPrices>
                <h3>{getBlock('deviation-table-header').string[language]}</h3>
                <S.AdditionalPricesTable>
                  {
                    freeTextValues.filter(({ isDiscount }) => !isDiscount)
                      .map(({ value, key, id, isDiscount }) => (
                        <S.AdditionalRow key={id}>
                          <span>{key}</span>
                          <span>{getValueInCurrency(value, language, isDiscount, !isBusiness)}</span>
                        </S.AdditionalRow>
                      ))
                  }
                  <S.AdditionalRow bold>
                    <span>{getBlock('project-price').string[language]}</span>
                    <span>{getValueInCurrency(projectPrice, language, false, false)}</span>
                  </S.AdditionalRow>
                  {
                    !isBusiness && !IS_TAKBYTTE
                      ? (
                        <S.AdditionalRow>
                          <span>{getBlock('enova-fund').string[language]}</span>
                          <span>{getValueInCurrency(quoteData.calculations.cost.enovaFunding, language, true, false)}</span>
                        </S.AdditionalRow>
                      ) : null
                  }
                  {
                    freeTextValues.filter(({ isDiscount }) => isDiscount)
                      .map(({ value, key, id, isDiscount }) => (
                        <S.AdditionalRow key={id}>
                          <span>{key}</span>

                          <span>{getValueInCurrency(value, language, isDiscount, !isBusiness)}</span>
                        </S.AdditionalRow>
                      ))
                  }
                  <S.AdditionalRow bold>
                    {IS_TAKBYTTE
                      ? <span>{getBlock(isBusiness ? 'total-price-for-business-customers' : 'cost-overview-cash-price-text').string[language]}</span>
                      : <span>{getBlock(isBusiness ? 'total-price-for-business-customers' : 'cost-overview-cash-price-including-enova-funding-text').string[language]}</span>}
                    <span>{getValueInCurrency(total, language, false, false)}</span>
                  </S.AdditionalRow>
                </S.AdditionalPricesTable>
              </S.AdditionalPrices>
            )
            : null
        }
        {IS_TAKBYTTE ? (
          <div style={{ marginTop: '4rem' }}>
            <RoofSelectorTakbytte
              mapPosition={quoteData.map_position}
              roofs={quoteData.roofs}
              setRoofs={setRoofSlider}
              solarPanel={quoteData.options.solarPanel}
              locked={quoteData.locked}
              onChangeCommitted={() => {
                updateQuote(quoteData, settings)
                  .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
              }}
              addRoofButtonInMap
            />
            <RoofingTypeSelector
              roofingType={quoteData.options.roofingType}
              pageData={pageData}
              locked={quoteData.locked}
              onChange={(roofingType) => {
                const copy = { ...quoteData };
                copy.options.roofingType = roofingType;
                setQuoteData(copy);
                updateQuote(quoteData, settings)
                  .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
              }}
            />
            <h2>{getBlock('additional-options-header').string[language]}</h2>
            {settings?.additional?.map((option) => (
              <OptionSelector
                option={option}
                locked={quoteData.locked}
                isBusiness={quoteData.business_type === 'business'}
                setQuoteData={setQuoteData}
                updateQuote={updateQuote}
                selectLabel={getBlock('select-additional-option-button-text').string[language]}
                selectedLabel={getBlock('selected-additional-option-button-text').string[language]}
                selected={quoteData.options.additionalOptions?.some((opt) => opt._key === option._key)}
                key={option._key}
              />
            ))}
            <h2 style={{ marginTop: '4rem', marginBottom: '1rem' }}>{getBlock('order-content-information-header').string[language]}</h2>
            <S.OrderInformationWrapper>
              <div>
                <S.OrderInformationHeader>
                  {getBlock('preparatory-work-title').string[language]}
                </S.OrderInformationHeader>
              </div>
              {/* eslint-disable-next-line react/no-danger */}
              <BlockContent
                blocks={getBlock('preparatory-work-information').blockContent[language]}
              />
            </S.OrderInformationWrapper>
            <S.OrderInformationWrapper>
              <div>
                <S.OrderInformationHeader>
                  {getBlock('mounting-title').string[language]}
                </S.OrderInformationHeader>
              </div>
              {/* eslint-disable-next-line react/no-danger */}
              <BlockContent
                blocks={getBlock('mounting-information').blockContent[language]}
              />
            </S.OrderInformationWrapper>
            <S.OrderInformationWrapper>
              <div>
                <S.OrderInformationHeader>
                  {getBlock('post-work-and-completion-title').string[language]}
                </S.OrderInformationHeader>
              </div>
              {/* eslint-disable-next-line react/no-danger */}
              <BlockContent
                blocks={getBlock('post-work-and-completion-information').blockContent[language]}
              />
            </S.OrderInformationWrapper>
          </div>
        ) : (
          <div>
            <S.KeyValueBanner>
              <S.KeyValueWrapper>
                <S.BulbIcon />
                <Text fontSize="sm">
                  {getBlock('production-per-year-text').string[language]}
                </Text>
                <Text fontSize="xxxl" textAlign="center">
                  {`${new Intl.NumberFormat(language).format(production.estimatedAnnualProduction)}
              ${getBlock('kilowatthour-unit').string[language]}`}
                </Text>
              </S.KeyValueWrapper>
              <S.KeyValueWrapper>
                <S.CarIcon />
                <Text fontSize="sm">
                  {getBlock('equivalent-to-emissions-text').string[language]}
                </Text>
                <Text fontSize="xxxl" textAlign="center">
                  {`${new Intl.NumberFormat(language).format(production.emissionInNumberOfCars)}
              ${getBlock('car-plural-form').string[language]}`}
                </Text>
              </S.KeyValueWrapper>
              <S.KeyValueWrapper>
                <S.BusIcon />
                <Text fontSize="sm">
                  {getBlock('or-round-trip-bergen-oslo-text').string[language]}
                </Text>
                <Text fontSize="xxxl" textAlign="center">
                  {`${new Intl.NumberFormat(language).format(production.numberOfBergenOsloTrips)}
              ${getBlock('time-plural-form').string[language]}`}
                </Text>
              </S.KeyValueWrapper>
            </S.KeyValueBanner>
            <RoofSelector
              mapPosition={quoteData.map_position}
              roofs={quoteData.roofs}
              setRoofs={setRoofSlider}
              solarPanel={quoteData.options.solarPanel}
              locked={quoteData.locked}
              onChangeCommitted={() => {
                updateQuote(quoteData, settings)
                  .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
              }}
              addRoofButtonInMap
            />
            <S.RoofTypeWrapper>
              <h2>{roofOptionData?.roofingSelectorHeading?.[language]}</h2>
              <S.Select
                select
                variant="outlined"
                id="roofing-option"
                label={roofOptionData?.roofOptionLabel?.[language] || ''}
                required
                value={quoteData?.options?.roofing || ''}
                disabled={quoteData.locked}
                onChange={({ target }) => {
                  const copy = { ...quoteData };
                  copy.options.roofing = target.value;
                  updateQuote(copy, settings)
                    .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
                }}
              >
                {
                  roofOptionData?.roofingTypes?.map(({ string, slug }) => (
                    <MenuItem value={slug.slice(ROOFING_OPTIONS_STARTS_WITH.length)} key={slug}>
                      {string[language]}
                    </MenuItem>
                  ))
                }
              </S.Select>
            </S.RoofTypeWrapper>
            <SolarPanelSelector
              solarPanel={quoteData.options.solarPanel}
              pageData={pageData}
              locked={quoteData.locked}
              onChange={(solarPanel) => {
                const copy = { ...quoteData };
                copy.options.solarPanel = solarPanel;
                copy.roofs.forEach((_, index) => {
                  copy.roofs[index].panelCountMax = Math.floor(
                    copy.roofs[index].availableSpace / (solarPanel.height * solarPanel.width),
                  );
                  if (copy.roofs[index].panelCountMax < copy.roofs[index].panelCountMin) {
                    copy.roofs[index].panelCountMax = copy.roofs[index].panelCountMin;
                  }
                  if (copy.roofs[index].panelCount > copy.roofs[index].panelCountMax) {
                    copy.roofs[index].panelCount = copy.roofs[index].panelCountMax;
                  }
                });
                setQuoteData(copy);
                updateQuote(quoteData, settings)
                  .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
              }}
            />
            <S.SellingPointWrapper>
              <div>
                <S.SellingPointHeader>
                  {getBlock('system-size-title').string[language]}
                </S.SellingPointHeader>
                {systemSizeSellingPointSubtitle}
              </div>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: systemSizeSellingPointText }} />
            </S.SellingPointWrapper>
            <S.SellingPointWrapper>
              <div>
                <S.SellingPointHeader>
                  {getBlock('estimated-production-title').string[language]}
                </S.SellingPointHeader>
                {estimatedProductionSellingPointSubtitle}
              </div>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: estimatedProductionSellingPointText }} />
            </S.SellingPointWrapper>
            <S.SellingPointWrapper>
              <div>
                <S.SellingPointHeader>
                  {getBlock('economy-title').string[language]}
                </S.SellingPointHeader>
                {economySellingPointSubtitle}
              </div>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: economySellingPointText }} />
            </S.SellingPointWrapper>
            <S.SellingPointWrapper>
              <div>
                <S.SellingPointHeader>
                  {getBlock('environment-title').string[language]}
                </S.SellingPointHeader>
                {environmentSellingPointSubtitle}
              </div>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: environmentSellingPointText }} />
            </S.SellingPointWrapper>

          </div>
        ) }
      </S.BodyWrapper>
      {
        quoteData.sale_status === saleStatusEnum.NOT_ORDERED
          ? (
            <QuoteStatusBar
              pageData={pageData}
              paymentMethod={quoteData.options.paymentMethod}
              price={total}
              monthlyPayment={quoteData.calculations.cost.monthlyPayment}
              onButtonClick={() => setShowConfirm(true)}
              onPaymentMethodChanged={(event) => {
                const copy = { ...quoteData };
                copy.options.paymentMethod = event.target.value;
                setQuoteData(copy);
                updateQuote(copy, settings)
                  .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
              }}
            />
          ) : null
      }

      <Dialog
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
      >
        <DialogTitle>
          {getBlock('order-confirmation-header').string[language]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {getBlock('order-confirmation-text').string[language]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <S.ConfirmButton onClick={() => {
            confirmOrder();
            setShowConfirm(false);
          }}
          >
            {getBlock('confirm').string[language]}
          </S.ConfirmButton>
          <S.ConfirmButton
            onClick={() => setShowConfirm(false)}
            autoFocus
          >
            {getBlock('cancel').string[language]}
          </S.ConfirmButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        key="bottomLeft"
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="info"
        >
          {getBlock('availability-warning-text')?.string[language] || ''}
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default QuotePage;
