import styled from 'styled-components';

export const Container = styled.div`
  margin: 7rem 5rem 3rem;
  width: calc(100vw - 14rem);
  max-width: 50rem;
  
  padding: 1rem;  

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    margin: 7rem 2rem 2rem;
    width: calc(100vw - 4rem);
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    margin: 5rem .5rem .5rem;
    width: calc(100vw - 1rem);
  }
  
  table {
    margin-bottom: 2rem;
    
    tr:nth-child(odd) {
      background-color: ${({ theme }) => theme.color.beige};
    }

    tr:nth-child(even) {
      background-color: ${({ theme }) => `${theme.color.beige}60`};
    }
  }
`;
