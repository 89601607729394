import React from 'react';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../../../lib/sanityClient';
import Page from '../../../components/Page';
import * as S from './styles';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => builder.image(source);

const serializers = {
  types: {
    image: ({ node }) => (
      <S.ImageWrapper>
        <S.Image
          src={urlFor(node)}
          alt={node.alt}
        />
        <S.ImageCaption>
          {
            node.caption
          }
        </S.ImageCaption>
      </S.ImageWrapper>
    ),
  },
  marks: {
    color: ({ children, mark }) => (
      <S.TextColorWrapper
        $red={mark.rgb.r}
        $green={mark.rgb.g}
        $blue={mark.rgb.b}
        $alpha={mark.rgb.a}
      >
        {children[0]}
      </S.TextColorWrapper>
    ),
  },
};

const ProductPageSolcellekraft = ({ pageData, language }) => (
  <Page title={pageData.title[language]}>
    <S.BodyWrapper>
      {
        pageData.blocks.map((block) => (
          <BlockContent
            key={block._key}
            blocks={block?.blockContent?.[language] || []}
            serializers={serializers}
            projectId={sanityClient.config().projectId}
            dataset={sanityClient.config().dataset}
          />
        ))
      }
    </S.BodyWrapper>
  </Page>
);

ProductPageSolcellekraft.defaultProps = {
  pageData: {},
  language: 'no',
};

ProductPageSolcellekraft.propTypes = {
  pageData: PropTypes.shape({
    _key: PropTypes.string,
    title: PropTypes.shape({}),
    blocks: PropTypes.arrayOf(PropTypes.shape({
      blockContent: PropTypes.shape({}),
    })),
  }),
  language: PropTypes.string,
};

export default ProductPageSolcellekraft;
