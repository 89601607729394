import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import TabsComponent from '../../tabscomponent/TabsComponent';
import MerchantContactForm from '../merchantforms/MerchantContactForm';
import MerchantPurchaseDiscountFrom from '../merchantforms/MerchantPurchaseDiscountFrom';
import PriceDifferentiationTable from '../pricedifferentiationtable/PriceDifferentiationTable';
import UserList from '../userlist/UserList';
import { MerchantExtendedRow, MerchantStandardRow } from './styles';

const MerchantListItem = ({
  merchant = {},
  users,
  merchantList,
  refreshUserList,
  refreshMerchantList,
  userInfo,
  isSolcellekraft,
}) => {
  const [expanded, setExpanded] = useState(false);
  const onExtend = () => setExpanded((prevState) => !prevState);

  return (
    <>
      <MerchantStandardRow>
        <TableCell style={{ width: '2rem', paddingRight: '0' }}>
          {
           expanded
             ? <KeyboardArrowUp size="small" onClick={onExtend} style={{ cursor: 'pointer', width: '3rem' }} />
             : <KeyboardArrowDown size="small" onClick={onExtend} style={{ cursor: 'pointer', width: '3rem' }} />
          }
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ fontSize: '1.4rem', fontWeight: '700', cursor: 'pointer' }}
          onClick={onExtend}
        >
          {merchant.name}
        </TableCell>
        <TableCell>
          { !expanded ? merchant.address_line1 : ''}
          <br />
          { !expanded ? `${merchant.postal_code} ${merchant.city}` : ''}
        </TableCell>
        <TableCell>
          { !expanded ? merchant.phone : ''}
          <br />
          { !expanded ? merchant.county : ''}
        </TableCell>
      </MerchantStandardRow>
      <MerchantExtendedRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <TabsComponent>
              <MerchantContactForm label="Kontaktinfo" merchant={merchant} setVisibility={setExpanded} refreshMerchantList={refreshMerchantList} />
              {
                isSolcellekraft
                  ? null
                  : <MerchantPurchaseDiscountFrom label="Pris" merchant={merchant} refreshMerchantList={refreshMerchantList} />
              }
              <UserList label="Brukere" userList={users} merchantList={merchantList} userInfo={userInfo} refreshUserList={refreshUserList} />
              <PriceDifferentiationTable
                label="Prisdifferensiering på fylke"
                merchantId={merchant.merchant_id}
                countyPriceDifferent={merchant.county_price_different}
                canEdit
                hideHeader
              />
            </TabsComponent>
          </Collapse>
        </TableCell>
      </MerchantExtendedRow>
    </>
  );
};

MerchantListItem.defaultProps = {
  merchant: {},
  users: [],
  merchantList: [],
  refreshUserList: () => {},
  refreshMerchantList: () => {},
  userInfo: {},
  isSolcellekraft: false,
};

MerchantListItem.propTypes = {
  merchant: PropTypes.shape({
    name: PropTypes.string,
  }),
  users: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  refreshUserList: PropTypes.func,
  refreshMerchantList: PropTypes.func,
  userInfo: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
    role: PropTypes.string.isRequired,
  }),
  isSolcellekraft: PropTypes.bool,
};

export default MerchantListItem;
