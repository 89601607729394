import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import useFetchData from '../../../hooks/useFetchData';
import { DashboardContainerWithMenu } from '../../../components/dashboard';
import ProgressWithBackDrop from '../../../components/progressWithBackDrop/ProgressWithBackDrop';
import { SOLCELLEKRAFT_ID } from '../../../constants/merchant';

const MERCHANT_LIST_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}`;

const WhitelabelTest = () => {
  const {
    loading: MerchantListLoading,
    data: merchantList,
  } = useFetchData(MERCHANT_LIST_URL);

  return (
    <DashboardContainerWithMenu>
      <ProgressWithBackDrop loading={MerchantListLoading}>
        <Table style={{ margin: '2rem' }}>
          <TableBody>
            {
              merchantList.filter(({ merchant_id: id }) => id !== SOLCELLEKRAFT_ID).map((merchant) => (
                <TableRow key={merchant.merchant_id}>
                  <TableCell>{merchant.name}</TableCell>
                  <TableCell>
                    <a href={`/whitelabel/${merchant.merchant_id}`}>{`${window.location.origin}/whitelabel/${merchant.merchant_id}`}</a>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </ProgressWithBackDrop>
    </DashboardContainerWithMenu>
  );
};

export default WhitelabelTest;
