import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSanity } from '../../lib/sanityClient';
import { Alert, Page, QuoteProgressDialog } from '../../components';
import HomePageBanner from '../home/HomePageBanner';
import HomePageStaticElements from '../home/HomePageStaticElements';
import AddressPageComponent from './AddressPageComponent';
import RoofCountText from './RoofCountText';
import useAddressData from './useAddressData';
import * as S from './styles';

const AddressPage = () => {
  const history = useHistory();
  const { data: pageData, loading: PageDataLoading, getBlock, getStringWithLanguage } = useSanity(`
  *[_type == "page" && slug.current == "home"][0]{
    title,
    blocks
  }`);

  const {
    addressData,
    loading: addressLoading,
    roofs,
    setRoofs,
    merchantId,
    alertMessage,
    onCloseAlert,
  } = useAddressData(getStringWithLanguage('address-search-failed-error-text'));

  if (PageDataLoading || addressLoading) return <QuoteProgressDialog message="" open />;
  if (Object.keys(pageData).length === 0) return <div>Page data not found...</div>;

  const roofCountText = `${roofs.length} ${roofs.length === 1
    ? getStringWithLanguage('roof-singular-form')
    : getStringWithLanguage('roof-plural-form')}`;

  // if there is a merchant id, iframe is used, and it should not include page, navigation and...
  return !merchantId
    ? (
      <Page title="adress" navLinkExpandedFontColor="white">
        <HomePageBanner pageData={pageData} />
        <S.AddressSearchSummaryWrapper>
          {
            !alertMessage
              ? (
                <S.AddressSearchSummaryText>
                  <h5>{getStringWithLanguage('address-search-result-heading-1')}</h5>
                  {addressData?.Text?.split(',')?.[0] || ''}
                  <br />
                  {`${addressData?.PostNummer} ${addressData?.PostSted}`}
                  <S.AddressSearchChangeLink onClick={() => history.push('/')}>
                    {getStringWithLanguage('address-search-change-link-label')}
                  </S.AddressSearchChangeLink>
                  <h5>{getStringWithLanguage('address-search-result-heading-2')}</h5>
                  {
                    roofs.length < 1
                      ? <RoofCountText text={getStringWithLanguage('address-search-result-no-roofs-found-text')} roofCountText={roofCountText} />
                      : <RoofCountText text={getStringWithLanguage('address-search-result-roofs-found-text')} roofCountText={roofCountText} />
                  }
                </S.AddressSearchSummaryText>
              ) : null
          }
          <AddressPageComponent
            addressData={addressData}
            roofs={roofs}
            setRoofs={setRoofs}
          />
          <HomePageStaticElements pageData={pageData} getBlock={getBlock} />
          <Alert onClose={onCloseAlert} message={alertMessage} alertOpen={!!alertMessage} />
        </S.AddressSearchSummaryWrapper>
      </Page>
    ) : (
      <S.WhitelabelWrapper>
        <AddressPageComponent
          addressData={addressData}
          roofs={roofs}
          setRoofs={setRoofs}
          merchantId={merchantId}
        />
        <Alert onClose={onCloseAlert} message={alertMessage} alertOpen={!!alertMessage} />
      </S.WhitelabelWrapper>
    );
};

export default AddressPage;
