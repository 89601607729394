import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import BlockContent from '@sanity/block-content-to-react';
import { LanguageContext } from '../../lib/Language';
import { SettingsContext } from '../../lib/Settings';
import sanityClient from '../../lib/sanityClient';
import { saleStatusEnum } from '../../lib/saleStatusMapping';
import QuoteProgressDialog from '../quoteprogressdialog/QuoteProgressDialog';
import Alert from '../Alert';
import * as S from './styles';

const ROOFING_OPTIONS_STARTS_WITH = 'roofing-select-option-';

const serializers = {
  marks: {
    // eslint-disable-next-line react/prop-types
    link: ({ children, mark }) => (
      // eslint-disable-next-line react/prop-types
      <S.Link to={mark.href} target="_blank">
        {children[0]}
      </S.Link>
    ),
  },
};

const QuoteRequestForm = ({ addressData, roofs, merchantId }) => {
  const [formData, setFormData] = useState(null);
  const [businessType, setBusinessType] = useState('personal');
  const [roofingOption, setRoofingOption] = useState('roof-tiles');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [telephone, setTelephone] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState({});
  const [quoteProgressDialogOpen, setQuoteProgressDialogOpen] = useState(false);

  const { language } = useContext(LanguageContext);
  const settings = useContext(SettingsContext);
  const history = useHistory();

  const getBlock = (id) => formData.blocks.find(({ slug }) => slug.current === id);

  const onSubmitHandler = (event) => {
    event.preventDefault();
    setSubmitted(true);
    setQuoteProgressDialogOpen(true);
    const body = {
      name,
      address: (addressData?.Text ?? '').split(',')[0],
      postalCode: addressData?.PostNummer ?? '',
      city: addressData?.PostSted ?? '',
      countyNumber: addressData?.FylkeNummer ?? 0,
      mapPosition: JSON.stringify({
        srs: addressData.Posisjon.SRS,
        x: addressData.Posisjon.X,
        y: addressData.Posisjon.Y,
      }),
      email,
      telephone,
      businessType,
      roofingOption,
      roofs: JSON.stringify(roofs),
      settings: JSON.stringify(settings),
      merchantId,
      sale_status: saleStatusEnum.NOT_ORDERED,
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    return fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json()
            .then(({ uuid }) => {
              const path = window.location.pathname[0] === '/' ? window.location.pathname.slice(1) : window.location.pathname;
              if (path.split('/')[0] !== 'whitelabel') {
                history.push(`/quote/${uuid}`);
              } else {
                window.location.href = `${window.location.origin}/whitelabel/quote/${uuid}`;
              }
            });
        }
        setAlertMessage(response.statusText);
        setAlertOpen(true);
        setSubmitted(false);
        setQuoteProgressDialogOpen(false);
        return null;
      });
  };

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "component" && slug.current == "quote-request-form"]{
        blocks
      }`)
      .then((data) => {
        setFormData((data.length === 0) ? {} : data[0]);
      })
      .catch(console.error);
  }, []);

  if (!formData) return <div>Loading...</div>;
  if (Object.keys(formData).length === 0) return <div>Quote request form data not found...</div>;

  return (
    <S.Container>
      <form onSubmit={onSubmitHandler}>
        <h1>{getBlock('title').string[language]}</h1>
        <h4>{getBlock('subtitle').string[language]}</h4>
        <S.FieldWrapper>
          <S.RadioGroup
            row
            value={businessType}
            onChange={(event) => setBusinessType(event.target.value)}
          >
            <S.FormControlLabel
              value="business"
              control={<S.Radio disabled={isSubmitted} />}
              label={getBlock('business-radio-button-label').string[language]}
            />
            <S.FormControlLabel
              value="personal"
              control={<S.Radio disabled={isSubmitted} />}
              label={getBlock('personal-radio-button-label').string[language]}
            />
          </S.RadioGroup>
          { process.env.REACT_APP_SITE === 'TAKBYTTE' ? null : (
            <S.TextField
              select
              variant="outlined"
              id="roofing-option"
              label={getBlock('roofing-option-list-label').string[language]}
              required
              value={roofingOption}
              onChange={(event) => setRoofingOption(event.target.value)}
              disabled={isSubmitted}
            >
              {
              formData.blocks
                .filter(({ slug }) => slug.current.startsWith(ROOFING_OPTIONS_STARTS_WITH))
                .map(({ string, slug: { current = '' } }) => (
                  <MenuItem value={current.slice(ROOFING_OPTIONS_STARTS_WITH.length)} key={current}>
                    {string[language]}
                  </MenuItem>
                ))
            }
            </S.TextField>
          ) }
          <S.TextField
            variant="outlined"
            id="name"
            label={getBlock('name-textfield-label').string[language]}
            value={name}
            onChange={(event) => setName(event.target.value)}
            required
            disabled={isSubmitted}
          />
          <S.TextField
            variant="outlined"
            id="email"
            type="email"
            label={getBlock('email-textfield-label').string[language]}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            required
            disabled={isSubmitted}
          />
          <S.TextField
            variant="outlined"
            id="telephone"
            label={getBlock('telephone-textfield-label').string[language]}
            value={telephone}
            onChange={(event) => setTelephone(event.target.value)}
            required
            disabled={isSubmitted}
          />
          <FormControlLabel
            control={(
              <S.Checkbox
                required
                disabled={isSubmitted}
              />
            )}
            label={(
              <BlockContent
                blocks={getBlock('accept-conditions-checkbox-label')?.blockContent[language]}
                serializers={serializers}
              />
            )}
          />
          <S.Button
            variant="contained"
            type="submit"
            disabled={isSubmitted}
          >
            {getBlock('get-quote-button-label').string[language]}
          </S.Button>
        </S.FieldWrapper>
      </form>
      <Alert
        alertOpen={alertOpen}
        onClose={() => setAlertOpen(false)}
        message={alertMessage}
      />
      <QuoteProgressDialog
        open={quoteProgressDialogOpen}
        message={getBlock('quote-progress-dialog-message').string[language]}
      />
    </S.Container>
  );
};

QuoteRequestForm.defaultProps = {
  roofs: [],
  addressData: {},
  merchantId: undefined,
};

QuoteRequestForm.propTypes = {
  roofs: PropTypes.arrayOf(PropTypes.shape({
    area2d: PropTypes.number,
    area3d: PropTypes.number,
    availableArea: PropTypes.number,
    availableSpace: PropTypes.number,
    azimuth: PropTypes.number,
    length: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    panelCount: PropTypes.number,
    panelCountMax: PropTypes.number,
    panelCountMin: PropTypes.number,
    pvgisAnnualProductionPerKwp: PropTypes.number,
    slope: PropTypes.number,
    stdDeviation: PropTypes.number,
    width: PropTypes.number,
    buildingId: PropTypes.string,
    buildingType: PropTypes.string,
    objectType: PropTypes.string,
    gotSpaceForSolar: PropTypes.bool,
    isFlat: PropTypes.bool,
    isSelected: PropTypes.bool,
    geometry: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
    }),
  })),
  addressData: PropTypes.shape({
    Text: PropTypes.string,
    FylkeNummer: PropTypes.number,
    PostNummer: PropTypes.string,
    PostSted: PropTypes.string,
    Posisjon: PropTypes.shape({
      SRS: PropTypes.number,
      X: PropTypes.number,
      Y: PropTypes.number,
    }),
  }),
  merchantId: PropTypes.string,
};

export default QuoteRequestForm;
