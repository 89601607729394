import React from 'react';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';
import styled from 'styled-components';
import { ReactComponent as MapHomeButtonIconBase } from '../../assets/MapHomeButtonIcon.svg';

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

const Container = styled.div`
`;

const Button = styled.button`
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: black;
  border: 0;
  border-radius: 3px;
  padding: 0;
  cursor: pointer;

  :hover {
    background: #f4f4f4;
  }
  
  @media print {
    display: none;
  }
`;

export const MapHomeButtonIcon = styled(MapHomeButtonIconBase)`
  height: 20px;
  width: 20px;
`;

const MapHomeButton = ({ mapPosition, zoom }) => {
  const map = useMap();
  const onClickHandler = () => {
    map.setView([mapPosition.y, mapPosition.x], zoom);
  };

  const positionClass = POSITION_CLASSES.topleft;
  return (
    <Container className={positionClass}>
      <div className="leaflet-control leaflet-bar">
        <Button onClick={onClickHandler}>
          <MapHomeButtonIcon />
        </Button>
      </div>
    </Container>
  );
};

MapHomeButton.propTypes = {
  mapPosition: PropTypes.objectOf(PropTypes.number).isRequired,
  zoom: PropTypes.number.isRequired,
};

export default MapHomeButton;
