import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import Intl from 'intl';
import 'intl/locale-data/jsonp/nb';
import useUserInfo from '../../../hooks/useUserInfo';
import useFetchData from '../../../hooks/useFetchData';
import { SettingsContext } from '../../../lib/Settings';
import exportQuotesXlsxFile from '../../../lib/xlsxFile/exportQuotesXlsxFile';
import { DashboardContainerWithMenu } from '../../../components/dashboard';
import ProgressWithBackDrop from '../../../components/progressWithBackDrop/ProgressWithBackDrop';
import QuotesPageDatePicker, { DEFAULT_PERIOD } from './QuotesPageDatePicker';
import DateString from './DateString';
import * as S from './styles';

const MERCHANT_LIST_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}`;
const QUOTES_LIST_URL = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}`;

const QUOTES_INITIAL_DATA = { count: 0, quotes: [] };
const START_PAGE = 1;

const ROOF_TYPE_MAP = {
  slate: 'Skifer',
  'roof-tiles': 'Takstein',
  'roof-tiles-steel-plates': 'Takstein stålplater',
  'roof-felt': 'Takpapp',
  'roof-shingles': 'Takshingel',
  'roof-steel-plates': 'Stålplater',
  'integrated-solar-panels': 'Integrert solcellepanel',
  decra: 'Decra',
  ballast: 'Ballast',
};

const DashboardQuotesPage = () => {
  const settings = useContext(SettingsContext);
  const [selectedQuoteIds, setSelectedQuoteIds] = useState([]);

  const { userInfo: { merchantId = '' }, loading: userInfoLoading } = useUserInfo();

  const {
    loading: MerchantListLoading,
    data: merchantRes,
  } = useFetchData(`${MERCHANT_LIST_URL}/${merchantId}`, { redirect: false, enabled: !userInfoLoading });
  const merchantList = useMemo(() => (Array.isArray(merchantRes) ? merchantRes : [merchantRes]), [merchantRes.length]);

  const {
    loading: quotesListLoading,
    data: { count: quoteCount, quotes },
    changeUrlQuery,
    urlQueries,
  } = useFetchData(QUOTES_LIST_URL, {
    initialQueries: { ...DEFAULT_PERIOD, merchantId },
    initialData: QUOTES_INITIAL_DATA,
    enabled: !userInfoLoading,
  });

  useEffect(() => {
    if (!quotesListLoading) {
      setSelectedQuoteIds((prev) => prev.filter((selectedId) => quotes.some(({ uuid }) => uuid === selectedId)));
    }
  }, [quotesListLoading]);

  return (
    <DashboardContainerWithMenu>
      <S.Body>
        <h2>Beregninger</h2>
        <S.SearchParameterWrapper>
          <S.SearchQuery
            label="SØKEORD"
            value={urlQueries.text || ''}
            onChange={({ target }) => changeUrlQuery({ text: target.value, page: START_PAGE })}
            onFocus={(e) => e.target.select()}
            variant="standard"
          />
          <QuotesPageDatePicker changeUrlQuery={changeUrlQuery} />
        </S.SearchParameterWrapper>

        <S.TablePagination
          component="div"
          count={Number.parseInt(quoteCount, 10)}
          page={(urlQueries.page - 1) || 0}
          rowsPerPage={urlQueries.limit || 25}
          rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
          onPageChange={(_, page) => changeUrlQuery({ page: page + 1 })}
          onRowsPerPageChange={(e) => changeUrlQuery({ limit: e.target.value, page: START_PAGE }, { debounce: false })}
          labelRowsPerPage="Beregninger per side:"
          labelDisplayedRows={({ from, to, count }) => `Viser beregninger ${from}-${to} av totalt ${count}`}
        />

        <S.QuoteDownloadWrapper>
          <S.SelectCheckbox
            checked={!!(selectedQuoteIds.length && quotes.length === selectedQuoteIds.length)}
            onClick={() => setSelectedQuoteIds((prev) => (prev.length ? [] : quotes.map(({ uuid }) => uuid)))}
          />
          <S.DownloadButton
            variant="contained"
            disabled={!selectedQuoteIds.length}
            onClick={() => {
              const selectedQuotes = quotes.filter(({ uuid }) => selectedQuoteIds.includes(uuid));
              exportQuotesXlsxFile(selectedQuotes, ROOF_TYPE_MAP, settings, merchantList);
            }}
          >
            Last ned
          </S.DownloadButton>
        </S.QuoteDownloadWrapper>

        <ProgressWithBackDrop loading={MerchantListLoading || quotesListLoading} fitContent>
          <S.QuoteList>
            {
              quotes.map((quote) => (
                <Link key={quote.id} to={`/quote/${quote.uuid}`}>
                  <S.QuoteWrapper>
                    <div>
                      <S.SelectCheckbox
                        checked={selectedQuoteIds.includes(quote.uuid)}
                        onClick={(event) => {
                          event.preventDefault();
                          setSelectedQuoteIds((prev = []) => (
                            prev.includes(quote.uuid)
                              ? prev.filter((uuid) => uuid !== quote.uuid)
                              : [...prev, quote.uuid]
                          ));
                        }}
                      />
                    </div>
                    <S.Column>
                      <S.QuoteMetadata>
                        {`Beregning ${quote.id} - `}
                        <DateString timestamp={quote.timestamp} />
                      </S.QuoteMetadata>
                      <S.Name>{quote.name}</S.Name>
                      <S.Address>
                        {`${quote.address}, ${quote.postal_code} ${quote.city}`}
                      </S.Address>
                    </S.Column>
                    <S.Column>
                      <S.Telephone>{quote.telephone}</S.Telephone>
                      <S.Email>{quote.email}</S.Email>
                    </S.Column>
                    { process.env.REACT_APP_SITE === 'TAKBYTTE'
                      ? (
                        <S.Column>
                          <div>
                            <b>{Math.round(quote.calculations.roofArea * 100) / 100}</b>
                            &nbsp;m²
                          </div>
                          <div>
                            <b>{quote.calculations.roofCount}</b>
                            &nbsp;Tak
                          </div>
                        </S.Column>
                      ) : (
                        <S.Column>
                          <div>
                            <b>{new Intl.NumberFormat('nb').format(Math.round((quote.calculations.production?.peakPower || 0) * 100) / 100)}</b>
                            &nbsp;kWp
                          </div>
                          <div>
                            <b>{quote.calculations.production?.panelCount}</b>
                            &nbsp;paneler
                          </div>
                          <div>
                            <b>{new Intl.NumberFormat('nb').format(quote.calculations.production?.roofArea)}</b>
                            &nbsp;m²
                          </div>
                        </S.Column>
                      )}
                    <S.Column>
                      <S.Telephone>
                        {settings.saleStatus[quote.sale_status]}
                      </S.Telephone>
                    </S.Column>
                    <S.Column style={{ visibility: merchantList.length ? 'visible' : 'collapse' }}>
                      { merchantList.length
                        ? (
                          <S.Telephone>
                            {
                              quote.merchant_id
                                ? (merchantList.find((m) => m.merchant_id === quote.merchant_id)?.name || '')
                                : process.env.REACT_APP_COMPANY_NAME
                            }
                          </S.Telephone>
                        ) : null}
                    </S.Column>
                  </S.QuoteWrapper>
                </Link>
              ))
            }
          </S.QuoteList>
        </ProgressWithBackDrop>
      </S.Body>
    </DashboardContainerWithMenu>
  );
};

export default DashboardQuotesPage;
