import styled from 'styled-components';
import TablePaginationBase from '@mui/material/TablePagination';
import Select from '@mui/material/Select';
import DatePickerBase from 'react-datepicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  background: ${({ theme }) => theme.color.beige};
  color: ${({ theme }) => theme.color.primaryDark};
`;

export const Body = styled.div`
  margin: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const SearchParameterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const PeriodSelect = styled(Select)`
  background: ${({ theme }) => theme.color.primaryLight};
  width: 13rem;
  height: 4rem;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.primaryLight};
  height: 4rem;
  width: 12rem;
`;

export const DatePickerLabel = styled.div`
  font-size: 0.7rem;
  color: gray;
  margin-left: 0.8rem;
  margin-top: 0.4rem;
`;

export const DatePicker = styled(DatePickerBase)`
  width: 8rem;
  border: 0;
  font-size: 1rem;
  font-family: inherit;
  padding-left: 0.8rem;
  margin-top: 0.4rem;
`;

export const SearchQuery = styled(TextField)`
  background: ${({ theme }) => theme.color.primaryLight};
  width: calc(100% - 40rem);
  height: 4rem;

  .MuiInputBase-input {
    margin-left: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
  }

  .MuiInputLabel-root {
    margin-left: 0.8rem;
    margin-top: 0.5rem;
  }

  .MuiInput-underline:before,  .MuiInput-underline:after {
    margin-bottom: -1rem;
  }
`;

export const TablePagination = styled(TablePaginationBase)`
  height: auto;
  min-height: 4rem;
  margin-top: 1rem;
  .MuiTablePagination-spacer {
    display: none;
  }
`;

export const QuoteList = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  overflow-y: auto;
  flex-grow: 1;
  min-height: 5rem;
`;

export const QuoteWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 2fr 1.5fr 0.75fr 0.75fr 1fr;
  padding: 1rem;
  background: ${({ theme }) => theme.color.primaryLight};
  color: ${({ theme }) => theme.color.primaryDark};
  border-radius: 0.5rem;
  align-items: center;
`;

export const QuoteDownloadWrapper = styled.div`
  padding-left: 1rem;
  margin-bottom: 0.5rem;
`;

export const DownloadButton = styled(Button)`
  width: 8rem;
  background-color: ${({ theme }) => theme.color.lightOrange};
  color: ${({ theme }) => theme.color.white};
  margin-right: 1rem;
`;

export const SelectCheckbox = styled(Checkbox)`
  margin-right: 1rem;
  color: ${({ theme }) => theme.color.black};
  
  &.Mui-checked {
    color: ${({ theme }) => theme.color.orange};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuoteMetadata = styled.div`
  color: gray;
  font-size: 1rem;
`;

export const Name = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`;

export const Address = styled.div`
  font-size: 1.2rem;
`;

export const Email = styled.div`
  font-size: 1rem;
`;

export const Telephone = styled.div`
  font-size: 1rem;
`;
