import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import MenuItem from '@mui/material/MenuItem';
import { LanguageContext } from '../../lib/Language';
import { SettingsContext } from '../../lib/Settings';
import Text from '../Text';
import sanityClient from '../../lib/sanityClient';
import * as S from './styles';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => builder.image(source);

const getUrlFromId = (ref) => {
  if (!ref) { return null; }
  const [, id, extension] = ref.split('-');
  return `https://cdn.sanity.io/files/${sanityClient.config().projectId}/${sanityClient.config().dataset}/${id}.${extension}`;
};

const RoofingTypeSelector = ({ roofingType, pageData, onChange, locked }) => {
  const { language } = useContext(LanguageContext);
  const settings = useContext(SettingsContext);
  const getBlock = (id) => pageData.blocks.find(({ slug }) => slug.current === id);

  const roofingTypeSelectorHeaderString = getBlock('roofing-type-selector-inner-header-string')
    .string[language]
    .replace(/%\w+%/g, ((variable) => (
      { '%PRODUCT%': roofingType?.title?.[language] }[variable] || variable
    )));

  return (
    <S.Container>
      <Text fontSize="xxl" bold>
        {getBlock('roofing-type-selector-header-string').string[language]}
      </Text>
      <S.RoofingInfoWrapper>
        <div>
          <S.SelectRoofing
            select
            variant="outlined"
            id="roofing-option"
            label={getBlock('roofing-type-option-list-label').string[language]}
            value={roofingType._key || ''}
            disabled={locked}
          >
            {
              settings.roofing.filter((roofing) => roofing.isActive)
                .map((_roofingType = {}) => (
                  <MenuItem
                    key={_roofingType._key}
                    label={_roofingType.title[language]}
                    value={_roofingType._key}
                    onClick={() => onChange(_roofingType)}
                  >
                    {_roofingType.title[language]}
                  </MenuItem>
                ))
            }
          </S.SelectRoofing>
          <S.RoofingInfoBanner>
            <S.RoofingInfoHeaderWrapper>
              <Text fontSize="lg" bold>
                {roofingTypeSelectorHeaderString}
              </Text>
            </S.RoofingInfoHeaderWrapper>
            <Text fontSize="md" bold>
              {roofingType?.productName}
            </Text>
            <BlockContent
              blocks={roofingType?.information?.[language]}
            />
            <div>
              {getBlock('roofing-type-selector-datasheet-string').string[language]}
              :
              &nbsp;
              <a href={getUrlFromId(roofingType.datasheet?.asset?._ref)} target="_blank" rel="noopener noreferrer">
                <S.PdfFileIcon />
              </a>
            </div>
          </S.RoofingInfoBanner>
        </div>
        <S.RoofingImageWrapper>
          <S.RoofingImage
            src={urlFor(roofingType.image).width(350).url()}
            alt={roofingType.productName}
          />
        </S.RoofingImageWrapper>
      </S.RoofingInfoWrapper>
    </S.Container>
  );
};

RoofingTypeSelector.defaultProps = {
  onChange: () => {},
  roofingType: {},
  locked: false,
};

RoofingTypeSelector.propTypes = {
  roofingType: PropTypes.shape({
    title: PropTypes.objectOf(PropTypes.string),
    _key: PropTypes.string,
    productName: PropTypes.string,
    datasheet: PropTypes.shape({
      asset: PropTypes.objectOf(PropTypes.string),
    }),
    information: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
        level: PropTypes.number,
        listItem: PropTypes.string,
        style: PropTypes.string,
        _key: PropTypes.string,
        _type: PropTypes.string,
        markDefs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
        children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      }))),
      PropTypes.shape({
        _type: PropTypes.string,
      }),
    ]),
    image: PropTypes.shape({
      asset: PropTypes.objectOf(PropTypes.string),
    }),
  }),
  pageData: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.objectOf(PropTypes.string),
      string: PropTypes.objectOf(PropTypes.string),
    })),
  }).isRequired,
  onChange: PropTypes.func,
  locked: PropTypes.bool,
};

export default RoofingTypeSelector;
