import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useUserInfo from '../../../hooks/useUserInfo';
import { SettingsContext } from '../../../lib/Settings';
import sanityClient from '../../../lib/sanityClient';
import portableTextToHtml from '../../../lib/email/blockToHtml';
import { DashboardContainerWithMenu } from '../../../components/dashboard';
import ProgressWithBackDrop from '../../../components/progressWithBackDrop/ProgressWithBackDrop';
import DashboardQuotePageLowerPanel from './DashboardQuotePageLowerPanel';
import DashboardQuotePageHeader from './DashboardQuotePageHeader';
import DashboardQuotePageUpperPanel from './DashboardQuotePageUpperPanel';
import DashboardQuotePageMiddlePanelSolcellekraft from './DashboardQuotePageMiddlePanelSolcellekraft';
import DashboardQuotePageMiddlePanelTakbytte from './DashboardQuotePageMiddlePanelTakbytte';
import { FREE_TEXT_INITAL_ARRAY } from './FreeTextField';
import * as S from './styles';

const wait = async (time) => new Promise((r) => {
  setTimeout(r, time);
});

const getQuote = async (uuid, accessToken) => {
  const quoteResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${uuid}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return quoteResponse.json();
};

const getMerchantList = async (merchantId, accessToken) => {
  if (merchantId) {
    const MerchantResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${merchantId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return [await MerchantResponse.json()];
  }
  const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch(console.error);

  return response?.json().catch(() => []);
};

const getEmailHtml = async (uuid, quote, settings) => {
  const sanityData = await sanityClient.fetch('*[_type == "offerEmail1"][0].emailTemplates') || [];
  return sanityData.map(({ emailSubject, templateName, text, _key }) => ({
    ...portableTextToHtml(text, emailSubject, quote, settings),
    templateName,
    key: _key,
  }));
};

const patchQuote = async (quote, settings, freeTextValues, accessToken) => {
  const body = {
    roofs: JSON.stringify(quote.roofs),
    options: JSON.stringify({ ...quote.options, freeTextValues }),
    settings: JSON.stringify(settings),
    countyNumber: quote.county_number,
    merchantId: quote.merchant_id,
    businessType: quote.business_type,
    saleStatus: quote.sale_status,
    locked: quote.locked,
  };

  const options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  };
  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/${quote.uuid}`;
  const res = await fetch(url, options);
  return res.ok ? res.json() : undefined;
};

const DashboardQuotePage = () => {
  const { uuid } = useParams();
  const [quote, setQuote] = useState(undefined);
  const emailTemples = useRef([]);
  const [merchantList, setMerchantList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [printingStatus, setPrintingStatus] = useState(0);
  const [upperMapHeight, setUpperMapHeight] = useState('20rem');
  const settings = useContext(SettingsContext);
  const saleStatus = (quote && settings?.saleStatus[quote.sale_status]) || '';

  const { userInfo, loading: userInfoLoading, getAccessTokenSilently } = useUserInfo();
  const isMerchantUser = userInfo.role === 'merchant_user';

  useEffect(async () => {
    if (!uuid || userInfoLoading) return;

    const accessToken = await getAccessTokenSilently();
    const [quoteData, merchantListData] = await Promise.all([
      getQuote(uuid, accessToken),
      getMerchantList(userInfo.merchantId, accessToken),
    ]).catch(console.error) || [];

    emailTemples.current = await getEmailHtml(uuid, quoteData, settings);
    setQuote(quoteData || undefined);

    setMerchantList(merchantListData || []);
    setLoading(false);
  }, [userInfoLoading, uuid, getAccessTokenSilently]);

  const onUpdateFreeTextValues = async (data, index) => {
    const freeTextValuesCopy = [...(quote.options.freeTextValues || FREE_TEXT_INITAL_ARRAY)];
    freeTextValuesCopy[index] = data;
    const accessToken = await getAccessTokenSilently();
    const quoteData = await patchQuote(quote, settings, freeTextValuesCopy, accessToken);

    if (quoteData) {
      setQuote(quoteData);
    }
    return quoteData.options.freeTextValues[index];
  };

  // This is done to center the maps in print.
  const onPrint = useCallback(async () => {
    setPrintingStatus(1);
    setUpperMapHeight('35rem');
    await wait(0);
    setPrintingStatus(2);
    await wait(0);
    setPrintingStatus(3);
    await wait(500);
    window.print();
    await wait(0);
    setPrintingStatus(4);
    setUpperMapHeight('20rem');
    await wait(0);
    setPrintingStatus(0);
  }, [setPrintingStatus]);

  return (
    <DashboardContainerWithMenu>
      <ProgressWithBackDrop loading={loading}>
        {
          printingStatus !== 2 && printingStatus !== 4
            ? (
              <S.Wrapper printingStatus={printingStatus}>
                <DashboardQuotePageHeader merchantList={merchantList} merchantId={quote?.merchant_id} id={quote?.id} />
                <DashboardQuotePageUpperPanel
                  quote={quote}
                  setQuote={setQuote}
                  merchantList={merchantList}
                  emailTemples={emailTemples.current}
                  onPrint={onPrint}
                  mapHeight={upperMapHeight}
                />
                {process.env.REACT_APP_SITE === 'TAKBYTTE'
                  ? (
                    <DashboardQuotePageMiddlePanelTakbytte
                      quote={quote}
                      onUpdateFreeTextValues={onUpdateFreeTextValues}
                      saleStatus={saleStatus}
                      isMerchantUser={isMerchantUser}
                    />
                  )
                  : (
                    <DashboardQuotePageMiddlePanelSolcellekraft
                      quote={quote}
                      onUpdateFreeTextValues={onUpdateFreeTextValues}
                      saleStatus={saleStatus}
                      isMerchantUser={isMerchantUser}
                    />
                  )}
                <DashboardQuotePageLowerPanel quote={quote} />
              </S.Wrapper>
            ) : null
        }
      </ProgressWithBackDrop>
    </DashboardContainerWithMenu>
  );
};

export default DashboardQuotePage;
