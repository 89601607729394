import React, { useContext, useState } from 'react';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import PropTypes from 'prop-types';
import sanityClient from '../../lib/sanityClient';
import { LanguageContext } from '../../lib/Language';
import { SettingsContext } from '../../lib/Settings';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import * as S from './styles';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => builder.image(source);

const OptionSelector = ({
  option, isBusiness, setQuoteData, updateQuote, selected, selectedLabel, selectLabel, locked,
}) => {
  const { language } = useContext(LanguageContext);
  const settings = useContext(SettingsContext);
  const [optionSelected, setOptionSelected] = useState(selected);

  const onClickButton = (event) => {
    let newState = null;
    setQuoteData((prevState) => {
      const additionalOptions = (prevState.options.additionalOptions || []).some((opt) => opt.slug.current === event.currentTarget.dataset.slug)
        ? prevState.options.additionalOptions.filter((opt) => opt.slug.current !== event.currentTarget.dataset.slug)
        : [
          ...(prevState.options.additionalOptions || []),
          settings.additional.find((o) => o.slug.current === event.currentTarget.dataset.slug),
        ];
      newState = { ...prevState, options: { ...prevState.options, additionalOptions } };
      return newState;
    });
    updateQuote(newState, settings)
      .then((updatedQuoteData) => setQuoteData(updatedQuoteData));
    setOptionSelected((current) => !current);
  };

  return (
    <S.AdditionalOptionWrapper>
      <S.OptionInnerWrapper>
        <S.OptionHeader>
          { option.title[language] }
        </S.OptionHeader>
        <S.AsideLeft>
          { option.image
            ? (
              <S.OptionImage
                src={urlFor(option.image).width(350).url()}
                alt={option.productName}
              />
            ) : <Logo style={{ width: '100%', maxWidth: '15rem' }} /> }
        </S.AsideLeft>
        <S.OptionInformation>
          <BlockContent
            blocks={option?.information?.[language]}
          />
        </S.OptionInformation>
        {isBusiness ? (
          <S.OptionPrice>
            {(new Intl.NumberFormat(language, { style: 'currency', currency: 'NOK', maximumFractionDigits: 1 })
              .format(Math.round(option.price))).slice(0, -1)}
            -
          </S.OptionPrice>
        ) : (
          <S.OptionPrice>
            {(new Intl.NumberFormat(language, { style: 'currency', currency: 'NOK', maximumFractionDigits: 1 })
              .format(Math.round(option.price * 1.25))).slice(0, -1)}
            -
          </S.OptionPrice>
        )}
        <S.AsideRight>
          <S.StandardButton
            selected={optionSelected ? 1 : 0}
            onClick={onClickButton}
            data-slug={option.slug.current}
            disabled={locked}
          >
            {optionSelected ? selectedLabel : selectLabel}
          </S.StandardButton>
        </S.AsideRight>
      </S.OptionInnerWrapper>

    </S.AdditionalOptionWrapper>
  );
};
OptionSelector.defaultProps = {
  option: {},
  setQuoteData: () => {},
  updateQuote: () => {},
  selected: false,
  selectedLabel: '',
  selectLabel: '',
  isBusiness: false,
  locked: false,
};

OptionSelector.propTypes = {
  setQuoteData: PropTypes.func,
  updateQuote: PropTypes.func,
  selected: PropTypes.bool,
  isBusiness: PropTypes.bool,
  selectLabel: PropTypes.string,
  selectedLabel: PropTypes.string,
  locked: PropTypes.bool,
  option: PropTypes.shape({
    title: PropTypes.objectOf(PropTypes.string),
    _key: PropTypes.string,
    productName: PropTypes.string,
    price: PropTypes.number,
    slug: PropTypes.objectOf(PropTypes.string),
    information: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
        style: PropTypes.string,
        _key: PropTypes.string,
        _type: PropTypes.string,
        markDefs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
        children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      }))),
      PropTypes.shape({
        _type: PropTypes.string,
      }),
    ]),
    image: PropTypes.shape({
      asset: PropTypes.objectOf(PropTypes.string),
    }),
  }),
};

export default OptionSelector;
