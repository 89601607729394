import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../../lib/sanityClient';
import { LanguageContext } from '../../lib/Language';
import * as S from './styles';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => builder.image(source);

const serializers = {
  marks: {
    link: ({ mark, children }) => (
      <S.BigSellingPointLink to={mark.href}>
        {children[0]}
      </S.BigSellingPointLink>
    ),
  },
};

const HomePageStaticElements = ({ getBlock }) => {
  const { language } = useContext(LanguageContext);

  return (
    <>
      <S.SaveMoneyAndEnvironment>
        {getBlock('save-money-and-environment-heading')?.string[language]}
        <img
          src={urlFor(getBlock('solar-power-separator-image')?.image).url()}
          alt={getBlock('solar-power-separator-image').alt[language]}
        />
      </S.SaveMoneyAndEnvironment>

      <S.SmallSellingPointWrapper>
        <BlockContent
          blocks={getBlock('search-by-address-text')?.blockContent[language]}
        />
        <BlockContent
          blocks={getBlock('quality-assurance-text')?.blockContent[language]}
        />
        <BlockContent
          blocks={getBlock('delivery-text')?.blockContent[language]}
        />
        <BlockContent
          blocks={getBlock('solar-panel-text')?.blockContent[language]}
        />
      </S.SmallSellingPointWrapper>

      <S.BigSellingPointWrapper>
        <S.BigSellingPointImage
          src={urlFor(getBlock('integrated-solar-panels-image')?.image).url()}
          alt={getBlock('integrated-solar-panels-image')?.alt[language]}
        />
        <BlockContent
          blocks={getBlock('integrated-solar-panels-text')?.blockContent[language]}
        />
      </S.BigSellingPointWrapper>

      <S.BigSellingPointWrapper $reverse>
        <S.BigSellingPointImage
          src={urlFor(getBlock('why-solar-panels-image')?.image).url()}
          alt={getBlock('why-solar-panels-image')?.alt[language]}
        />
        <BlockContent
          blocks={getBlock('why-solar-panels-text')?.blockContent[language]}
        />
      </S.BigSellingPointWrapper>

      <S.BigSellingPointWrapper>
        <S.BigSellingPointImage
          src={urlFor(getBlock('contribute-image')?.image).url()}
          alt={getBlock('contribute-image')?.alt[language]}
        />
        <BlockContent
          blocks={getBlock('contribute-text')?.blockContent[language]}
        />
      </S.BigSellingPointWrapper>

      <S.BigSellingPointWrapper $reverse>
        <S.BigSellingPointImage
          src={urlFor(getBlock('do-you-need-financing-image')?.image).url()}
          alt={getBlock('do-you-need-financing-image')?.alt[language]}
        />
        <BlockContent
          blocks={getBlock('do-you-need-financing-text')?.blockContent[language]}
          serializers={serializers}
        />
      </S.BigSellingPointWrapper>

      <S.BigSellingPointWrapper>
        <S.BigSellingPointImage
          src={urlFor(getBlock('cut-electricity-bill-image')?.image).url()}
          alt={getBlock('cut-electricity-bill-image')?.alt[language]}
        />
        <BlockContent
          blocks={getBlock('cut-electricity-bill-text')?.blockContent[language]}
        />
      </S.BigSellingPointWrapper>
    </>
  );
};

HomePageStaticElements.propTypes = {
  getBlock: PropTypes.func.isRequired,
};

export default HomePageStaticElements;
