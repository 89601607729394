import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import * as S from './styles';

const CustomerMerchantChangeDialog = ({ open, handleClose, merchantList, setSelectedMerchantId, changeMerchant }) => (
  <div>
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          top: 100,
          left: 300,
          position: 'absolute',
          border: 10,
        },
      }}
    >
      <DialogTitle>
        Endre forhandler
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Velg forhandler for å endre forhandlertilhørighet for valgte kunder
        </DialogContentText>
        <FormControl>
          <S.StandardSelect
            labelId="merchant-label"
            onChange={(e) => setSelectedMerchantId(e.target.value)}
            defaultValue=""
            displayEmpty
          >
            { merchantList.map(({ merchant_id: merchantId, name }) => (
              <MenuItem value={merchantId} key={merchantId}>{name}</MenuItem>
            ))}
          </S.StandardSelect>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <S.StandardButton
          onClick={() => {
            changeMerchant();
            handleClose();
            setSelectedMerchantId('');
          }}
        >
          Endre
        </S.StandardButton>
        <S.StandardButton
          onClick={() => {
            handleClose();
            setSelectedMerchantId('');
          }}
        >
          Avbryt
        </S.StandardButton>
      </DialogActions>
    </Dialog>
  </div>
);

CustomerMerchantChangeDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  merchantList: PropTypes.arrayOf(PropTypes.shape({
    merchant_id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  handleClose: PropTypes.func,
  setSelectedMerchantId: PropTypes.func.isRequired,
  changeMerchant: PropTypes.func,
};

CustomerMerchantChangeDialog.defaultProps = {
  handleClose: () => {},
  changeMerchant: () => {},
};

export default CustomerMerchantChangeDialog;
