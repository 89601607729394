import React from 'react';
import PropTypes from 'prop-types';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../../../lib/sanityClient';
import Page from '../../../components/Page';
import * as S from './styles';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => builder.image(source).width(800).auto('format');

const serializers = {
  marks: {
    color: ({ children, mark }) => (
      <S.TextColorWrapper
        $red={mark.rgb.r}
        $green={mark.rgb.g}
        $blue={mark.rgb.b}
        $alpha={mark.rgb.a}
      >
        {children[0]}
      </S.TextColorWrapper>
    ),
  },
};

const split = (block, language) => {
  const blockContent = block?.blockContent?.[language || 'no'] || [];

  const result = [];
  let texts = [];
  let images = [];
  let headers = [];

  blockContent.forEach((element) => {
    if (element.style === 'h1' || element.style === 'h2') {
      if (headers.length || texts.length || images.length) {
        result.push({
          header: [...headers],
          texts: [...texts],
          images: [...images],
          key: element._key,
        });
      }

      texts = [];
      images = [];
      headers = [element];
    } else if (element._type === 'image') {
      images.push(element);
    } else {
      texts.push(element);
    }
  });

  return [...result, { header: headers, texts: [...texts], images: [...images], key: 'last-element' }];
};

const ProductPageTakbytte = ({ pageData, language }) => {
  const blocks = pageData.blocks.map((block) => ({ blockContent: split(block, language), key: block._key }));

  return (
    <Page title={pageData.title?.[language] || ''}>
      {
        blocks.map(({ blockContent, key: blockKey }) => (
          <S.BodyWrapper key={blockKey}>
            {
              blockContent.map(({ header, texts, images, key }) => (
                <S.Block key={key}>
                  <S.BlockContentText
                    blocks={[...header, ...texts]}
                    serializers={serializers}
                    projectId={sanityClient.config().projectId}
                    dataset={sanityClient.config().dataset}
                    fullWidth={!images.length}
                  />
                  {
                    images.length
                      ? (
                        <span>
                          {
                            images.map((image) => (
                              <S.ImageWrapper key={image._key}>
                                <S.Image src={urlFor(image)} alt={image.alt} />
                                <S.ImageCaption>{image.caption}</S.ImageCaption>
                              </S.ImageWrapper>
                            ))
                          }
                        </span>
                      ) : null
                  }
                </S.Block>
              ))
            }
          </S.BodyWrapper>
        ))
      }
    </Page>
  );
};

ProductPageTakbytte.defaultProps = {
  pageData: {},
  language: 'no',
};

ProductPageTakbytte.propTypes = {
  pageData: PropTypes.shape({
    _key: PropTypes.string,
    title: PropTypes.shape({}),
    blocks: PropTypes.arrayOf(PropTypes.shape({
      blockContent: PropTypes.shape({}),
    })),
  }),
  language: PropTypes.string,
};

export default ProductPageTakbytte;
