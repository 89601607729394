import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LayersControl,
  FeatureGroup,
  TileLayer,
  WMSTileLayer,
  Polygon,
  useMapEvents,
  ZoomControl,
} from 'react-leaflet';
import sanityClient from '../../lib/sanityClient';
import { LanguageContext } from '../../lib/Language';
import { SettingsContext } from '../../lib/Settings';
import calculateAvailableSpace from '../../lib/calculateAvailableSpace';

import MapHomeButton from '../maphomebutton/MapHomeButton';
import MapAddRoofButton from '../mapaddroofbutton/MapAddRoofButton';

import fetchRoofByPosition from '../../lib/fetchRoofByPosition';
import isPositionInsidePolygon from '../../lib/isPointInsidePolygon';

import * as S from './styles';

const apiKeyQuery = `api_key=${process.env.REACT_APP_NORKART_API_KEY}`;

const PanelSummarySlider = ({
  roofs,
  index,
  onChangeCommitted,
  setRoofs,
  language,
  PanelSummaryText,
  solarPanel,
  locked,

}) => {
  const { isSelected, panelCountMin = 0, panelCountMax = 0 } = roofs[index] || {};
  const { peakPower, height, width } = solarPanel || {};
  const [sliderValue, setSliderValue] = useState(roofs[index]?.panelCount || 0);
  const panelCount = Math.round(sliderValue);

  const onChange = (event, value) => {
    setSliderValue(value);
  };

  const onSlideOver = () => {
    setSliderValue(panelCount);
    const copy = [...roofs];
    copy[index].panelCount = panelCount;
    setRoofs(copy);
    onChangeCommitted(copy);
  };

  const usablePanelCountMax = Math.max(panelCountMax, panelCountMin);
  const usablePanelCountMin = Math.min(panelCountMax, panelCountMin);
  const marks = useMemo(() => (
    Array.from(Array(usablePanelCountMax - usablePanelCountMin), (_, i) => ({ value: i + usablePanelCountMin }))
  ), [usablePanelCountMin, usablePanelCountMax]);

  return (
    <>
      <S.Slider
        disabled={!isSelected || locked}
        marks={marks}
        step={0.1}
        min={usablePanelCountMin}
        max={usablePanelCountMax}
        value={sliderValue}
        onChange={onChange}
        onChangeCommitted={onSlideOver}
      />
      <S.PanelSummary>
        {
          `${new Intl.NumberFormat(language).format(Math.round(panelCount * peakPower * 100) / 100)} kWp - \
                      ${panelCount} ${PanelSummaryText} \
                      (${new Intl.NumberFormat(language).format(Math.round(panelCount * height * width * 10) / 10)} m²)`
        }
      </S.PanelSummary>
    </>
  );
};

PanelSummarySlider.propTypes = {
  roofs: PropTypes.arrayOf(PropTypes.shape({
    panelCount: PropTypes.number,
    isSelected: PropTypes.bool,
    panelCountMin: PropTypes.number,
    panelCountMax: PropTypes.number,
  })),
  index: PropTypes.number.isRequired,
  onChangeCommitted: PropTypes.func,
  setRoofs: PropTypes.func,
  language: PropTypes.string,
  PanelSummaryText: PropTypes.string,
  solarPanel: PropTypes.shape({
    peakPower: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
  }),
  locked: PropTypes.bool,
};

PanelSummarySlider.defaultProps = {
  roofs: {
    panelCount: 0,
    isSelected: false,
    panelCountMin: 0,
    panelCountMax: 0,
  },
  onChangeCommitted: () => {},
  setRoofs: () => {},
  language: 'no',
  PanelSummaryText: '',
  solarPanel: { peakPower: 0, height: 0, width: 0 },
  locked: false,
};

const getPvgisData = (mapPosition, slope, azimuth, pvgisSystemLossPercentage) => (
  fetch(`\
${process.env.REACT_APP_BACKEND_URL}/api/pvgis/productiondata?\
latitude=${mapPosition.y}&\
longitude=${mapPosition.x}&\
slope=${slope}&\
azimuth=${azimuth}&\
loss=${pvgisSystemLossPercentage}`)
);

const RoofSelector = ({
  mapPosition,
  roofs,
  setRoofs,
  onChangeCommitted,
  newRoofs,
  setNewRoofs,
  solarPanel,
  addRoofButtonInMap,
  onAddNewRoof,
  isWhiteLabel,
  locked,
}) => {
  const [componentData, setComponentData] = useState();
  const getBlock = (id) => componentData.blocks.find(({ slug }) => slug.current === id);
  const [isReady, setReady] = useState(false);
  const { language } = useContext(LanguageContext);
  const settings = useContext(SettingsContext);
  const roofListRef = useRef();
  const [roofRefArray, setRoofRefArray] = useState();
  const [addRoofMode, setAddRoofMode] = useState(false);
  const [renderMap, setRenderMap] = useState(true);

  // Used to access roofRefArray inside setTimeout() in MapClickHandler
  const roofRefArrayRef = useRef(roofRefArray);
  roofRefArrayRef.current = roofRefArray;

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "component" && slug.current == "roof-selector"]{
        blocks
      }`)
      .then((data) => {
        setComponentData((data.length === 0) ? {} : data[0]);
        const roofsRefs = roofs.reduce((acc, roof) => {
          acc[roof.id] = React.createRef();
          return acc;
        }, {});
        setRoofRefArray(roofsRefs);
        setReady(true);
      })
      .catch(console.error);
  }, [setRoofRefArray]);

  useEffect(() => {
    const roofsRefs = roofs.reduce((acc, roof) => {
      acc[roof.id] = React.createRef();
      return acc;
    }, {});
    const newRoofsRefs = newRoofs?.reduce((acc, roof) => {
      acc[roof.id] = React.createRef();
      return acc;
    }, {});
    const array = {
      ...roofsRefs,
      ...newRoofsRefs,
    };
    setRoofRefArray(array);
  }, [roofs?.length, newRoofs?.length, setRoofRefArray]);

  const addRoofHandler = () => {
    onAddNewRoof();
    setTimeout(() => {
      roofListRef.current.scrollTo({
        top: roofListRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }, 250);
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        const position = {
          x: e.latlng.lng,
          y: e.latlng.lat,
        };
        if (addRoofMode) {
          fetchRoofByPosition(position, settings)
            .then((roof) => {
              // Check if the roof is the one that was clicked on, if it is already added to the list, and if it's large enough
              if (isPositionInsidePolygon(position, roof.geometry) && !roofs.some((_roof) => _roof.id === roof.id) && roof.gotSpaceForSolar) {
                const copy = [...roofs];
                const panelCountMin = settings.solar.solarPanelCountMinValue;
                let panelCountMax = Math.floor(
                  roof.availableSpace / (solarPanel.height * solarPanel.width),
                );
                if (panelCountMax < panelCountMin) {
                  panelCountMax = panelCountMin;
                }
                copy.push({
                  ...roof,
                  panelCountMin,
                  panelCountMax,
                  panelCount: panelCountMax,
                  isSelected: true,
                });
                setRoofs(copy);
                setTimeout(() => {
                  const roofRef = roofRefArrayRef.current[roof.id];
                  if (roofRef?.current) {
                    roofListRef.current.scrollTo({
                      top: roofRef.current.offsetTop - roofListRef.current.offsetTop,
                      behavior: 'smooth',
                    });
                  }
                }, 500);
              }
            });
          setAddRoofMode(false);
        }
      },
    });
    return null;
  };

  useEffect(() => {
    // TODO: Fix this...
    if (isWhiteLabel) {
      setTimeout(() => {
        setRenderMap(false);
      }, 1000);

      setTimeout(() => {
        setRenderMap(true);
      }, 1005);
    }
  }, []);

  if (!isReady || !solarPanel) return null;

  return (
    <S.Wrapper>
      <S.RoofList>
        <S.RoofListInnerWrapper ref={roofListRef}>
          {
            roofs.map((roof, index) => (
              <S.RoofWrapper
                key={roof.id}
                $isSelected={roofs[index].isSelected}
                ref={roofRefArray[roof.id]}
              >
                <S.RoofHeader>
                  <S.RoofTitle>
                    {
                        `${getBlock('heading-text')?.string[language]} ${index + 1} - \
                      ${new Intl.NumberFormat(language).format(
                          Math.round(roof.area3d * 10) / 10,
                        )} m²`
                      }
                  </S.RoofTitle>
                  <S.Switch
                    checked={roofs[index].isSelected}
                    $isSelected={roofs[index].isSelected}
                    disabled={locked}
                    onChange={() => {
                      const copy = [...roofs];
                      copy[index].isSelected = !copy[index].isSelected;
                      setRoofs(copy);
                      onChangeCommitted(copy);
                    }}
                  />
                </S.RoofHeader>
                <S.RoofBody>
                  <S.RoofDimensionWrapper>
                    <S.VerticalFlex>
                      <S.InnerHeading>
                        {getBlock('new-roof-length-text')?.string[language]}
                        :
                      </S.InnerHeading>
                      {new Intl.NumberFormat(language).format(Math.round(
                        (roof.length) * 10,
                      ) / 10)}
                        &nbsp;m
                    </S.VerticalFlex>
                    <S.VerticalFlex>
                      <S.InnerHeading>
                        {getBlock('new-roof-width-text')?.string[language]}
                        :
                      </S.InnerHeading>
                      {new Intl.NumberFormat(language).format(Math.round(
                        (roof.width) * 10,
                      ) / 10)}
                        &nbsp;m
                    </S.VerticalFlex>
                    <S.VerticalFlex>
                      <S.InnerHeading>
                        {getBlock('new-roof-slope-text')?.string[language]}
                        :
                      </S.InnerHeading>
                      {new Intl.NumberFormat(language).format(Math.round(
                        (roof.slope) * 10,
                      ) / 10)}
                      °
                    </S.VerticalFlex>
                  </S.RoofDimensionWrapper>
                  <S.HorizontalFlex>

                    <S.InnerHeading>
                      {getBlock('annual-production-per-kwp-text')?.string[language]}
                    </S.InnerHeading>
                      &nbsp;
                    {
                        roof.pvgisAnnualProductionPerKwp
                          ? `${roof.pvgisAnnualProductionPerKwp} kWh`
                          : `${settings.solar.defaultSolarProductionPerKpw} kWh`
                      }
                  </S.HorizontalFlex>
                  <S.HorizontalFlex>
                    <S.InnerHeading>
                      {getBlock('summary-heading-text')?.string[language]}
                    </S.InnerHeading>
                  </S.HorizontalFlex>
                  <PanelSummarySlider
                    roofs={roofs}
                    index={index}
                    onChangeCommitted={onChangeCommitted}
                    setRoofs={setRoofs}
                    language={language}
                    PanelSummaryText={getBlock('system-size-unit-text')?.string[language]}
                    solarPanel={solarPanel}
                    locked={locked}
                  />
                </S.RoofBody>
              </S.RoofWrapper>
            ))
          }
          {
              newRoofs && newRoofs.map((roof, index) => (
                <S.RoofWrapper
                  key={roof.id}
                  $isSelected={roof.isSelected}
                  ref={roofRefArray[roof.id]}
                >
                  <S.RoofHeader>
                    <S.RoofTitle>
                      {
                          `${getBlock('heading-text')?.string[language]} ${roofs.length + index + 1} - \
                      ${new Intl.NumberFormat(language).format(Math.round(roof.area3d * 10) / 10)} m²`
                        }
                    </S.RoofTitle>
                    <S.Switch
                      checked={newRoofs[index].isSelected}
                      $isSelected={newRoofs[index].isSelected}
                      onChange={() => {
                        const copy = [...newRoofs];
                        copy[index].isSelected = !copy[index].isSelected;
                        setNewRoofs(copy);
                      }}
                    />
                    <S.RemoveRoofButton
                      $isSelected={roof.isSelected}
                      onClick={() => {
                        setNewRoofs((array) => array.filter((r) => r.id !== roof.id));
                      }}
                    />
                  </S.RoofHeader>
                  <S.RoofBody>
                    <S.NewRoofInputWrapper>
                      <S.NewRoofInput
                        variant="outlined"
                        label={getBlock('new-roof-length-text')?.string[language]}
                        value={new Intl.NumberFormat(language).format(newRoofs[index].length)}
                        onFocus={(e) => e.target.select()}
                        onBlur={(e) => {
                          const copy = [...newRoofs];
                          const number = parseFloat(e.target.value.replace(',', '.'));
                          copy[index].length = !Number.isNaN(number) ? Math.min(Math.max(number, 0), 50) : 0;
                          copy[index].area3d = copy[index].width * copy[index].length;
                          copy[index].area2d = copy[index].area3d * Math.cos(copy[index].slope * (Math.PI / 180));
                          copy[index].availableSpace = calculateAvailableSpace(copy[index].area3d);
                          copy[index].panelCountMin = settings.solar.solarPanelCountMinValue;
                          let panelCountMax = Math.floor(
                            copy[index].availableSpace / (solarPanel.height * solarPanel.width),
                          );
                          if (panelCountMax < copy[index].panelCountMin) {
                            panelCountMax = copy[index].panelCountMin;
                          }
                          copy[index].panelCountMax = panelCountMax;
                          copy[index].panelCount = copy[index].panelCountMax;
                          setNewRoofs([...copy]);
                          if (newRoofs[index].area3d >= settings.solar.manuallyAddedRoofMinValue) {
                            return getPvgisData(
                              mapPosition,
                              copy[index].slope,
                              copy[index].azimuth,
                              settings.solar.pvgisSystemLossPercentage,
                            )
                              .then((response) => {
                                if (response.ok) {
                                  return response.json()
                                    .then((json) => {
                                      copy[index].pvgisAnnualProductionPerKwp = json.pvgisAnnualProductionPerKwp
                                        ? Math.round(json.pvgisAnnualProductionPerKwp)
                                        : json.pvgisAnnualProductionPerKwp;
                                      setNewRoofs(copy);
                                    });
                                }
                                return null;
                              });
                          }
                          copy[index].pvgisAnnualProductionPerKwp = undefined;
                          return null;
                        }}
                      />
                      <S.NewRoofInput
                        variant="outlined"
                        label={getBlock('new-roof-width-text')?.string[language]}
                        value={new Intl.NumberFormat(language).format(newRoofs[index].width)}
                        onFocus={(e) => e.target.select()}
                        onBlur={(e) => {
                          const copy = [...newRoofs];
                          const number = parseFloat(e.target.value.replace(',', '.'));
                          copy[index].width = !Number.isNaN(number) ? Math.min(Math.max(number, 0), 50) : 0;
                          copy[index].area3d = copy[index].width * copy[index].length;
                          copy[index].area2d = copy[index].area3d * Math.cos(copy[index].slope * (Math.PI / 180));
                          copy[index].availableSpace = calculateAvailableSpace(copy[index].area3d);
                          copy[index].panelCountMin = settings.solar.solarPanelCountMinValue;
                          let panelCountMax = Math.floor(
                            copy[index].availableSpace / (solarPanel.height * solarPanel.width),
                          );
                          if (panelCountMax < copy[index].panelCountMin) {
                            panelCountMax = copy[index].panelCountMin;
                          }
                          copy[index].panelCountMax = panelCountMax;
                          copy[index].panelCount = copy[index].panelCountMax;
                          setNewRoofs([...copy]);
                          if (newRoofs[index].area3d >= settings.solar.manuallyAddedRoofMinValue) {
                            return getPvgisData(
                              mapPosition,
                              copy[index].slope,
                              copy[index].azimuth,
                              settings.solar.pvgisSystemLossPercentage,
                            )
                              .then((response) => {
                                if (response.ok) {
                                  return response.json()
                                    .then((json) => {
                                      copy[index].pvgisAnnualProductionPerKwp = json.pvgisAnnualProductionPerKwp
                                        ? Math.round(json.pvgisAnnualProductionPerKwp)
                                        : json.pvgisAnnualProductionPerKwp;
                                      setNewRoofs(copy);
                                    });
                                }
                                return null;
                              });
                          }
                          copy[index].pvgisAnnualProductionPerKwp = undefined;
                          return null;
                        }}
                      />
                    </S.NewRoofInputWrapper>

                    {/* DISPLAY IF ROOF AREA SIZE IS ABOVE LOWER LIMIT */}
                    {newRoofs[index].area3d >= settings.solar.manuallyAddedRoofMinValue && (
                      <>
                        <S.NewRoofInputWrapper>
                          <S.NewRoofInput
                            variant="outlined"
                            label={getBlock('new-roof-slope-text')?.string[language]}
                            value={new Intl.NumberFormat(language).format(newRoofs[index].slope)}
                            onFocus={(e) => e.target.select()}
                            onBlur={(e) => {
                              const copy = [...newRoofs];
                              const number = parseFloat(e.target.value.replace(',', '.'));
                              if (number !== copy[index].slope) {
                                copy[index].slope = !Number.isNaN(number) ? Math.min(Math.max(number, 0), 90) : 0;
                                copy[index].area2d = copy[index].area3d * Math.cos(copy[index].slope * (Math.PI / 180));
                                copy[index].pvgisAnnualProductionPerKwp = undefined;
                                setNewRoofs([...copy]);
                                return getPvgisData(
                                  mapPosition,
                                  copy[index].slope,
                                  copy[index].azimuth,
                                  settings.solar.pvgisSystemLossPercentage,
                                )
                                  .then((response) => {
                                    if (response.ok) {
                                      return response.json()
                                        .then((json) => {
                                          copy[index].pvgisAnnualProductionPerKwp = json.pvgisAnnualProductionPerKwp
                                            ? Math.round(json.pvgisAnnualProductionPerKwp)
                                            : json.pvgisAnnualProductionPerKwp;
                                          setNewRoofs(copy);
                                        });
                                    }
                                    return null;
                                  });
                              }
                              return null;
                            }}
                          />
                          <S.NewRoofInput
                            variant="outlined"
                            label={getBlock('new-roof-azimuth-text')?.string[language]}
                            value={new Intl.NumberFormat(language).format(newRoofs[index].azimuth)}
                            onFocus={(e) => e.target.select()}
                            onBlur={(e) => {
                              const copy = [...newRoofs];
                              const number = parseFloat(e.target.value.replace(',', '.'));
                              if (number !== copy[index].azimuth) {
                                copy[index].azimuth = !Number.isNaN(number) ? ((number % 360) + 360) % 360 : 0;
                                copy[index].pvgisAnnualProductionPerKwp = undefined;
                                setNewRoofs([...copy]);
                                return getPvgisData(
                                  mapPosition,
                                  copy[index].slope,
                                  copy[index].azimuth,
                                  settings.solar.pvgisSystemLossPercentage,
                                )
                                  .then((response) => {
                                    if (response.ok) {
                                      return response.json()
                                        .then((json) => {
                                          copy[index].pvgisAnnualProductionPerKwp = json.pvgisAnnualProductionPerKwp
                                            ? Math.round(json.pvgisAnnualProductionPerKwp)
                                            : json.pvgisAnnualProductionPerKwp;
                                          setNewRoofs(copy);
                                        });
                                    }
                                    return null;
                                  });
                              }
                              return null;
                            }}
                          />
                        </S.NewRoofInputWrapper>
                        <S.HorizontalFlex>
                          <S.InnerHeading>
                            {getBlock('annual-production-per-kwp-text')?.string[language]}
                          </S.InnerHeading>
                              &nbsp;
                          {
                                /* eslint-disable-next-line no-nested-ternary */
                                roof.pvgisAnnualProductionPerKwp
                                  ? `${roof.pvgisAnnualProductionPerKwp} kWh`
                                  : roof.pvgisAnnualProductionPerKwp === null
                                    ? `${settings.solar.defaultSolarProductionPerKpw} kWh`
                                    : <S.PvgisCalculationProgressIcon size="1rem" />
                              }
                        </S.HorizontalFlex>
                        <S.HorizontalFlex>
                          <S.InnerHeading>
                            {getBlock('summary-heading-text')?.string[language]}
                          </S.InnerHeading>
                        </S.HorizontalFlex>
                        <PanelSummarySlider
                          roofs={newRoofs}
                          index={index}
                          onChangeCommitted={onChangeCommitted}
                          setRoofs={setRoofs}
                          language={language}
                          PanelSummaryText={getBlock('system-size-unit-text')?.string[language]}
                          solarPanel={solarPanel}
                        />
                      </>
                    )}

                    {/* DISPLAY IF ROOF AREA SIZE BELOW LOWER LIMIT */}
                    {newRoofs[index].area3d < settings.solar.manuallyAddedRoofMinValue && (
                      <S.Warning>
                        {getBlock('manually-added-roof-too-little-area-text')?.string[language]}
                            &nbsp;
                        {settings.solar.manuallyAddedRoofMinValue}
                            &nbsp;m²
                      </S.Warning>
                    )}
                  </S.RoofBody>
                </S.RoofWrapper>
              ))
          }
        </S.RoofListInnerWrapper>
        {
          newRoofs && (
            <S.AddRoofWrapper>
              <S.AddRoofButton onClick={addRoofHandler}>
                +
              </S.AddRoofButton>
              <S.AddRoofLink onClick={addRoofHandler}>
                Legg til ekstra takflate
              </S.AddRoofLink>
            </S.AddRoofWrapper>
          )
        }
      </S.RoofList>
      {
        renderMap
          ? (
            <S.MapContainer
              center={[mapPosition.y, mapPosition.x]}
              zoomControl={false}
              zoom={20}
              maxZoom={20}
              scrollWheelZoom={false}
              tap={false}
              preferCanvas
            >
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Flyfoto (med navn)">
                  <TileLayer
                    maxZoom={20}
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={`https://waapi.webatlas.no/maptiles/tiles/webatlas-standard-hybrid/wa_grid/{z}/{x}/{y}.jpeg?${apiKeyQuery}`}
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Flyfoto (uten navn)">
                  <TileLayer
                    maxZoom={20}
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={`https://waapi.webatlas.no/maptiles/tiles/webatlas-orto-newup/wa_grid/{z}/{x}/{y}.jpeg?${apiKeyQuery}`}
                  />
                </LayersControl.BaseLayer>

                <LayersControl.Overlay checked name="Takflater">
                  <WMSTileLayer
                    url={`https://waapi.webatlas.no/WMS-Takhelning/?service=WMS&request=GetMap&${apiKeyQuery}`}
                    layers="takhelning"
                    styles="takflate-solcelle-norkart"
                    transparent="true"
                    format="image/png"
                    maxZoom={20}
                  />
                </LayersControl.Overlay>

                <LayersControl.Overlay checked name="Polygoner">
                  <FeatureGroup>
                    {[...roofs]
                      .sort((a, b) => (
                        b.availableSpace - a.availableSpace
                      ))
                      .map((roof) => {
                        if (!roof.gotSpaceForSolar || !roof.geometry) return null;
                        const coords = roof.geometry.coordinates[0].map((coord) => (
                          [coord[1], coord[0]]
                        ));
                        return (
                          <Polygon
                            key={roof.id}
                            pathOptions={{
                              color: 'blue',
                              fillColor: roof.isSelected ? 'blue' : 'transparent',
                              opacity: roof.isSelected ? 1 : 0.4,
                            }}
                            positions={coords}
                            eventHandlers={!locked ? {
                              click: () => {
                                const copy = [...roofs];
                                const index = copy.findIndex((_roof) => _roof.id === roof.id);
                                copy[index].isSelected = !copy[index].isSelected;
                                setRoofs(copy);
                                onChangeCommitted(copy);
                                const roofRef = roofRefArray[roof.id];
                                if (copy[index].isSelected) {
                                  roofListRef.current.scrollTo({
                                    top: roofRef.current.offsetTop - roofListRef.current.offsetTop,
                                    behavior: 'smooth',
                                  });
                                }
                              },
                            } : null}
                          />
                        );
                      })}
                  </FeatureGroup>
                </LayersControl.Overlay>
                <MapHomeButton
                  mapPosition={mapPosition}
                  zoom={20}
                />

                {addRoofButtonInMap && (
                <MapAddRoofButton
                  onClick={() => {
                    if (!addRoofMode) {
                      setAddRoofMode(true);
                    }
                  }}
                  addRoofMode={addRoofMode}
                  locked={locked}
                />
                )}
                {!locked ? (
                  <S.TextOverlay className="leaflet-top leaflet-left">
                    {!addRoofMode ? (
                      getBlock('click-on-roofs-map-overlay-text')?.string[language]
                    ) : (
                      getBlock('click-a-roof-to-add-map-overlay-text')?.string[language]
                    )}
                  </S.TextOverlay>
                ) : null }

                <MapClickHandler />

              </LayersControl>

              <ZoomControl position="bottomleft" />
            </S.MapContainer>
          )
          : null
      }
    </S.Wrapper>
  );
};

RoofSelector.defaultProps = {
  mapPosition: undefined,
  newRoofs: null,
  setNewRoofs: () => {},
  onChangeCommitted: () => {},
  setRoofs: () => {},
  addRoofButtonInMap: false,
  onAddNewRoof: () => {},
  isWhiteLabel: false,
  locked: false,
};

RoofSelector.propTypes = {
  mapPosition: PropTypes.objectOf(PropTypes.number),
  roofs: PropTypes.arrayOf(PropTypes.shape({
    area2d: PropTypes.number,
    area3d: PropTypes.number,
    availableArea: PropTypes.number,
    availableSpace: PropTypes.number,
    azimuth: PropTypes.number,
    buildingId: PropTypes.string,
    buildingType: PropTypes.string,
    geometry: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))),
    }),
    gotSpaceForSolar: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isFlat: PropTypes.bool,
    isSelected: PropTypes.bool,
    length: PropTypes.number,
    objectType: PropTypes.string,
    panelCount: PropTypes.number,
    panelCountMax: PropTypes.number,
    panelCountMin: PropTypes.number,
    pvgisAnnualProductionPerKwp: PropTypes.number,
    slope: PropTypes.number,
    stdDeviation: PropTypes.number,
    width: PropTypes.number,
  })).isRequired,
  setRoofs: PropTypes.func,
  onChangeCommitted: PropTypes.func,
  newRoofs: PropTypes.arrayOf(PropTypes.shape({
    area2d: PropTypes.number,
    area3d: PropTypes.number,
    availableSpace: PropTypes.number,
    azimuth: PropTypes.number,
    id: PropTypes.string,
    isCustomRoof: PropTypes.bool,
    isSelected: PropTypes.bool,
    length: PropTypes.number,
    panelCount: PropTypes.number,
    panelCountMax: PropTypes.number,
    panelCountMin: PropTypes.number,
    pvgisAnnualProductionPerKwp: PropTypes.number,
    slope: PropTypes.number,
    width: PropTypes.number,
  })),
  setNewRoofs: PropTypes.func,
  solarPanel: PropTypes.shape({
    peakPower: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
  addRoofButtonInMap: PropTypes.bool,
  onAddNewRoof: PropTypes.func,
  isWhiteLabel: PropTypes.bool,
  locked: PropTypes.bool,
};

export default RoofSelector;
