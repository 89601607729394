import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CustomerServiceBanner = styled.div`
  height: 35rem;
  width: 100%;
  background-color: black;
  color: ${({ theme }) => theme.color.primaryLight};
  display: flex;
  align-items: center;
  padding-left: 4rem;
  padding-right: 40%;
  font-weight: 400;

  a {
    color: ${({ theme }) => theme.color.primaryLight};
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme.color.primaryHover};
    }
  }

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    padding-left: 2rem;
  }
`;

export const RandomQuestionPanel = styled.div`
  width: 100%;
  padding: 3rem 2rem 0 2rem;
  background-color: ${({ theme }) => theme.color.beige};
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    grid-template-columns: 1fr;
  }
`;

export const RandomQuestionContainer = styled.div`
  margin-bottom: 3rem;
`;

export const RandomQuestion = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.primaryDark};
  margin-bottom: 3rem;

  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

export const FaqAnswerContainer = styled.div`
  margin-top: 8rem;
  max-width: 50rem;
  p {
    font-size: ${({ theme }) => theme.fontSize.large.lg};
  }
`;

export const FaqHeading = styled.span`
  color: ${({ theme }) => theme.color.primaryBorder};
  font-size: ${({ theme }) => theme.fontSize.large.sm};
`;

export const ListWithoutBullets = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
`;

export const FaqContainer = styled.div`
  ${({ theme }) => theme.style.basePageStyle}
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  justify-content: space-between;
  grid-gap: 2rem;
`;

export const FaqLink = styled(Link)`
  color: black;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.primaryHover};
  }
`;

export const ContactBanner = styled.div`
  width: 100%;
  height: 15rem;
  background: linear-gradient(45deg, #f7a618, #f07f17);
  text-align: center;  
  position: relative;
  margin-top: 5rem;
`;

export const ContactBannerInnerContainer = styled.div`
  ${({ theme }) => theme.style.basePageStyle}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  text-align: left;
`;

export const ContactUsText = styled.h3`
  color: ${({ theme }) => theme.color.primaryLight};
`;

export const ContactInformation = styled.h5`
  font-weight: 400;
  a {
    color: ${({ theme }) => theme.color.primaryLight};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
