import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as AddRoofIconBase } from '../../assets/AddRoofIcon.svg';

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
};

const Container = styled.div`
  margin-top: 52.5px;
`;

const Button = styled.button`
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.$addRoofMode ? props.theme.color.lighterOrange : 'white')};
  color: black;
  border: 0;
  border-radius: 3px;
  padding: 0;
  cursor: pointer;
  
  :disabled{
    cursor: no-drop;
  }

  :hover {
    background: ${(props) => (props.$addRoofMode ? props.theme.color.lightOrange : '#f4f4f4')};
  }
`;

export const MapHomeButtonIcon = styled(AddRoofIconBase)`
  height: 32px;
  width: 32px;
`;

const MapAddRoofButton = ({ onClick, addRoofMode, locked }) => {
  const positionClass = POSITION_CLASSES.topleft;
  return (
    <Container className={positionClass}>
      <div className="leaflet-control leaflet-bar">
        <Button onClick={onClick} $addRoofMode={addRoofMode} disabled={locked}>
          <MapHomeButtonIcon />
        </Button>
      </div>
    </Container>
  );
};

MapAddRoofButton.defaultProps = {
  locked: false,
};

MapAddRoofButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  addRoofMode: PropTypes.bool.isRequired,
  locked: PropTypes.bool,
};

export default MapAddRoofButton;
