import styled from 'styled-components';
import Button from '@mui/material/Button';

export const AdditionalOptionWrapper = styled.div`
  margin-top: 1rem;
  background-color: #F0ECE8;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  padding: 2rem;
`;

export const OptionInnerWrapper = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.color.beige};
  grid-template-areas:
    'nav nav navRight'
    'asideLeft main asideRight';
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 3fr 8rem;
  grid-column-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-template-areas:
    'nav navRight'
    'asideLeft asideLeft'
    'main main'
    'asideRight asideRight';
    grid-template-columns: auto auto ;
  }
`;

export const OptionHeader = styled.h5`
  grid-area: nav;
`;

export const AsideLeft = styled.aside`
  grid-area: asideLeft;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

export const OptionInformation = styled.main`
  grid-area: main;

  p {
    margin:0;
  }
`;

export const OptionPrice = styled.nav`
  font-weight: bold;
  grid-area: navRight;
  text-align: right;
`;

export const OptionImage = styled.img`
  object-fit: contain;
  width: 100%;
  max-width: 15rem;
  height: auto;
`;

export const AsideRight = styled.aside`
  grid-area: asideRight;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  
`;

export const StandardButton = styled(Button)`
  //position: absolute;
  width: 10rem;
  margin-top: 5px;
  color: ${({ selected, theme }) => (selected ? `${theme.color.primaryDark}` : `${theme.color.primaryLight}`)};
  background-color: ${({ selected, theme }) => (selected ? `${theme.color.primaryLight}` : `${theme.color.primaryHover}`)};
  border: 2px solid ${({ selected, theme }) => (selected ? theme.color.primaryBorder : null)};
  
  &:hover {
    background: ${({ theme }) => `${theme.color.primaryHover}60`};
  }
`;
