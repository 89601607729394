import styled from 'styled-components';

export const BodyWrapper = styled.div`
  ${({ theme }) => theme.style.basePageStyle}
  margin-top: 8rem;
  margin-bottom: 4rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    padding-left: 2rem;
    padding-right: 2rem;  
  }
`;

export const DetailsHeader = styled.h2`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

export const DetailsWrapper = styled.div`
  display: grid;
  grid-template: auto / 1fr auto 1fr;
  width: 100%;
  max-width: 60rem;
  grid-gap: 5rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-gap: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    grid-template: auto auto auto / auto;
    grid-gap: 0;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DetailsString = styled.span`
  :first-letter {
    text-transform: capitalize;
  }
`;

export const VerticalLine = styled.div`
  border-left: 1px solid black;
  height: calc(100% + 2rem);
  margin-top: -1rem;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {
    display: none; 
  }
`;
