import React, { useContext, useRef, useEffect, useState } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Chart, registerables } from 'chart.js';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { DashboardContainerWithMenu, MerchantPriceAdjustTable, PriceDifferentiationTable } from '../../../components/dashboard';
import { SettingsContext } from '../../../lib/Settings';
import PriceAdjustTable from '../../../components/dashboard/priceadjusttable/PriceAdjustTable';
import getChartDataSolcellekraft from './chartDataSolcellekraft';
import getChartDataTakbytte from './chartDataTakbytte';
import useUserInfo from '../../../hooks/useUserInfo';
import ProgressWithBackDrop from '../../../components/progressWithBackDrop/ProgressWithBackDrop';
import useFetchData from '../../../hooks/useFetchData';
import * as S from './styles';

const DashboardPage = () => {
  const settings = useContext(SettingsContext);
  const [chartTotals, setChartTotals] = useState();
  const [quotesList, setQuotesList] = useState([]);

  const {
    userInfo,
    getAccessTokenSilently,
    loading: userInfoLoading,
  } = useUserInfo({ useSolMerchantId: true });

  const { data: priceDiffData } = useFetchData(
    `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${userInfo.merchantId}/countyPriceDifferent`,
    {
      enabled: !userInfoLoading,
    },
  );

  const [busy, setBusy] = useState(false);
  const chartContainer = useRef(null);
  const [origMerchant, setOrigMerchant] = useState();
  const [solcellekraftData, setSolcellekraftData] = useState();
  const [merchant, setMerchant] = useState();
  const [editMode, setEditMode] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    address_line1: '',
  });

  const fetchSolcellekraftData = async () => {
    if (userInfo.isSolUser) {
      const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_SOLCELLEKRAFT_ENDPOINT}`;
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });
      const data = await response.json();
      setSolcellekraftData(data);
    }
  };

  const fetchMerchant = async () => {
    if (userInfo.isMerchantUser) {
      const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${userInfo.merchantId}`;
      const merchantResponse = await fetch(url, {
        headers: {
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
      });

      setMerchant(await merchantResponse.json());
    }
  };

  const updateMerchant = async () => {
    setBusy(true);
    const body = {
      name: merchant.name,
      addressLine1: merchant.address_line1,
      addressLine2: merchant.address_line2,
      phone: merchant.phone,
      postalCode: merchant.postal_code,
      email: merchant.email,
      city: merchant.city,
      county: merchant.county,
      countryCode: merchant.country_code,
    };
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(body),
    };
    const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MERCHANTS_ENDPOINT}/${merchant.merchant_id}`;
    const updateResult = await fetch(url, requestOptions);
    await updateResult.json();
    await fetchMerchant();
  };

  const validate = async () => {
    if (!merchant.name || merchant.name.length === 0) {
      setErrors({ ...errors, name: 'Ugyldig navn' });
      return;
    }

    if (!merchant.address_line1 || merchant.address_line1.length === 0) {
      setErrors({ ...errors, address_line1: 'Ugyldig adresse' });
      return;
    }

    if (!merchant.phone || merchant.phone === 0) {
      setErrors({ ...errors, phone: 'Ugyldig telefonnummer' });
      return;
    }

    if (!merchant.county) {
      setErrors({ ...errors, county: 'Fylke ikke valgt' });
      return;
    }

    setEditMode(!editMode);
    await updateMerchant();
    setBusy(false);
  };

  useEffect(async () => {
    if (userInfoLoading) return;

    setBusy(true);
    const today = new Date();
    const dateMinus14days = moment(new Date().setDate(new Date().getDate() - 13));
    const accessToken = await getAccessTokenSilently();

    const merchantQuery = userInfo.isSolUser ? undefined : `merchantId=${userInfo.merchantId}`;

    // eslint-disable-next-line max-len
    let quotesListUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}?limit=15&page=0&startDate=${dateMinus14days.toISOString()}&endDate=${today.toISOString()}`;
    if (merchantQuery) quotesListUrl += `&${merchantQuery}`;

    const quotesListResponse = await fetch(quotesListUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (quotesListResponse.status === 200) {
      setQuotesList(await quotesListResponse.json());
    }

    let quotesStatsUrl = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_QUOTES_ENDPOINT}/stats`;
    if (merchantQuery) quotesStatsUrl += `?${merchantQuery}`;

    const statsResponse = await fetch(quotesStatsUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (statsResponse.status === 200) {
      Chart.register(...registerables);
      const response = await statsResponse.json();

      let maxCalculations = 0;
      Object.keys(response.perDay).forEach((day) => {
        const calcsDay = response.perDay[day].calculations;
        if (calcsDay > maxCalculations) maxCalculations = calcsDay;
      });

      setChartTotals({
        totalPower: response.totalPower,
        totalCalculations: response.totalCalculations,
        totalPanels: response.totalPanels,
        totalAddresses: response.totalAddresses,
        to: moment(today).format('DD.MM.YYYY'),
        from: moment(today.setDate(today.getDate() - 13)).format('DD.MM.YYYY'),
      });

      if (process.env.REACT_APP_SITE === 'TAKBYTTE') {
        const data = getChartDataTakbytte(maxCalculations);
        Object.keys(response.perDay).forEach((day) => {
          data.barData.data.labels.push(moment(parseInt(day, 10)).format('DD.MM'));
          data.barData.data.datasets[0].data.push(response.perDay[day].calculations);
          data.barData.data.datasets[1].data.push(response.perDay[day].addresses);
        });
        if (chartContainer && chartContainer.current) {
          // eslint-disable-next-line no-unused-vars
          const newChartInstance = new Chart(chartContainer.current, data.barData);
        }
      } else {
        const data = getChartDataSolcellekraft(maxCalculations);
        Object.keys(response.perDay).forEach((day) => {
          data.barData.data.labels.push(moment(parseInt(day, 10)).format('DD.MM'));
          data.barData.data.datasets[0].data.push(response.perDay[day].calculations);
          data.barData.data.datasets[1].data.push(response.perDay[day].addresses);
          data.barData.data.datasets[2].data.push(response.perDay[day].power);
        });
        if (chartContainer && chartContainer.current) {
          // eslint-disable-next-line no-unused-vars
          const newChartInstance = new Chart(chartContainer.current, data.barData);
        }
      }

      setBusy(false);
    }
  }, [userInfoLoading]);

  useEffect(async () => {
    if (userInfoLoading) return;

    if (userInfo.isSolUser) {
      await fetchSolcellekraftData();
    }

    if (userInfo.isMerchantUser) {
      await fetchMerchant();
    }
  }, [userInfoLoading]);

  return (
    <DashboardContainerWithMenu>
      <ProgressWithBackDrop loading={userInfoLoading || busy}>
        <div style={{ width: busy ? 'calc(100% - 4rem)' : 'calc(100% - 1rem)' }}>
          <div style={{ marginTop: '5px', marginLeft: '5px' }}>
            {`Innlogget som ${userInfo.name} (${userInfo.role})`}
          </div>
          <hr />
          <div style={{ marginLeft: '20px' }}>
            <div>
              <h1>{`Hei, ${userInfo.name}`}</h1>
            </div>
            <div style={{ display: 'flex' }}>
              {/* eslint-disable-next-line max-len */}
              <div style={{ width: (userInfo.isMerchantUser) ? '50%' : '100%' }}>
                <div style={{ backgroundColor: 'white', padding: '10px 10px 10px 20px' }}><b>Beregninger per dag</b></div>
                <hr style={{ marginTop: '0px', marginBottom: '0px', border: '1px solid transparent' }} />
                <div style={{ backgroundColor: 'white', padding: '20px' }}>
                  { chartTotals && <div style={{ fontWeight: 300 }}>{`${chartTotals.from} - ${chartTotals.to}`}</div>}
                  <table style={{ width: '100%', marginBottom: '20px' }}>
                    <tbody>
                      <tr>
                        <S.TotalsColumn>Beregninger</S.TotalsColumn>
                        <S.TotalsColumn>
                          { chartTotals && chartTotals.totalCalculations }
                        </S.TotalsColumn>
                        <S.TotalsColumn>Adresser</S.TotalsColumn>
                        <S.TotalsColumn>
                          { chartTotals && chartTotals.totalAddresses }
                        </S.TotalsColumn>
                      </tr>
                      {process.env.REACT_APP_SITE === 'TAKBYTTE' ? null : (
                        <tr>
                          <S.TotalsColumn>Effekt</S.TotalsColumn>
                          <S.TotalsColumn>
                            { chartTotals && chartTotals.totalPower && `${chartTotals.totalPower.toFixed(1)}kW`}
                          </S.TotalsColumn>
                          <S.TotalsColumn>Paneler</S.TotalsColumn>
                          <S.TotalsColumn>
                            { chartTotals && chartTotals.totalPanels }
                          </S.TotalsColumn>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <canvas width={800} height={500} style={{ backgroundColor: 'white' }} ref={chartContainer} />
                </div>
              </div>
              { userInfo.isMerchantUser && merchant
                ? (
                  <div style={{ width: '50%' }}>
                    <div style={{ display: editMode ? 'none' : '' }}>
                      <S.MerchantWrapper>
                        <S.MerchantName>
                          <h3>{merchant.name}</h3>
                        </S.MerchantName>
                        {userInfo.role === 'merchant_admin'
                          ? (
                            <IconButton
                              size="large"
                              onClick={() => {
                                setEditMode(!editMode);
                                setOrigMerchant(merchant);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          )
                          : null}
                      </S.MerchantWrapper>
                      <div style={{ marginLeft: '42px' }}>
                        <S.Column>
                          <S.MerchantField>{merchant.address_line1}</S.MerchantField>
                          <S.MerchantField>{merchant.address_line2}</S.MerchantField>
                          <S.MerchantField>{`${merchant.postal_code} ${merchant.city}`}</S.MerchantField>
                          <S.MerchantField style={{ marginTop: '10px' }}>{`Epost: ${merchant.email}`}</S.MerchantField>
                          <S.MerchantField>{`Telefon: ${merchant.phone}`}</S.MerchantField>
                        </S.Column>
                      </div>
                    </div>
                    <div style={{ display: !editMode ? 'none' : '' }}>
                      <div style={{ paddingLeft: '30px' }}>
                        <S.StandardTextField
                          label="Navn"
                          placeholder="Navn"
                          value={merchant.name}
                          InputProps={{ inputProps: { maxLength: 100 } }}
                          onChange={(e) => {
                            setErrors({ ...errors, name: '' });
                            setMerchant({ ...merchant, name: e.target.value });
                          }}
                          helperText={errors.name}
                          error={!!errors.name.length > 0}
                        />
                      </div>
                      <S.MerchantWrapper>
                        <S.Column>
                          <S.StandardTextField
                            label="Epost"
                            placeholder="Epost"
                            InputProps={{ inputProps: { maxLength: 100 } }}
                            value={merchant.email}
                            onChange={(e) => {
                              setMerchant({ ...merchant, email: e.target.value });
                            }}
                          />
                          <S.StandardTextField
                            label="Adresse 1"
                            placeholder="Adresse 1"
                            InputProps={{ inputProps: { maxLength: 100 } }}
                            value={merchant.address_line1}
                            onChange={(e) => {
                              setErrors({ ...errors, address_line1: '' });
                              setMerchant({ ...merchant, address_line1: e.target.value });
                            }}
                            helperText={errors.address_line1}
                            error={!!errors.address_line1.length > 0}
                          />
                          <S.StandardTextField
                            label="Adresse 2"
                            placeholder="Adresse 2"
                            InputProps={{ inputProps: { maxLength: 100 } }}
                            value={merchant.address_line2}
                            onChange={(e) => {
                              setMerchant({ ...merchant, address_line2: e.target.value });
                            }}
                          />
                          <S.FormControl
                            variant="standard"
                            error={!!errors.county}
                          >
                            <S.InputLabel>Fylke</S.InputLabel>
                            <S.Select
                              id="language-selector"
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                              value={merchant.county}
                              onChange={(e) => {
                                setErrors({ ...errors, county: '' });
                                setMerchant({ ...merchant, county: e.target.value });
                              }}
                            >
                              {settings?.priceDifferentiation.counties.map((county) => (
                                <MenuItem
                                  key={county._key}
                                  value={county.slug.current}
                                >
                                  {county.name}
                                </MenuItem>
                              ))}
                            </S.Select>
                            {!!errors.county && (
                              <S.FormHelperText>{errors.county}</S.FormHelperText>
                            )}
                          </S.FormControl>
                        </S.Column>
                        <S.Column>
                          <S.StandardTextField
                            label="Postnummer"
                            placeholder="Postnummer"
                            InputProps={{ inputProps: { maxLength: 10 } }}
                            value={merchant.postal_code}
                            onChange={(e) => {
                              setMerchant({ ...merchant, postal_code: e.target.value });
                            }}
                          />
                          <S.StandardTextField
                            label="Poststed"
                            placeholder="Poststed"
                            value={merchant.city}
                            InputProps={{ inputProps: { maxLength: 100 } }}
                            onChange={(e) => {
                              setMerchant({ ...merchant, city: e.target.value });
                            }}
                          />
                          <S.StandardTextField
                            label="Telefon"
                            placeholder="Telefon"
                            value={merchant.phone}
                            type="number"
                            onChange={(e) => {
                              setErrors({ ...errors, phone: '' });
                              setMerchant({ ...merchant, phone: e.target.value });
                            }}
                            helperText={errors.phone}
                            error={!!errors.phone.length > 0}
                          />
                        </S.Column>
                      </S.MerchantWrapper>
                      <S.EditButtonWrapper>
                        <S.Column>
                          <S.StandardButton
                            onClick={() => validate()}
                          >
                            Endre
                          </S.StandardButton>
                        </S.Column>
                        <S.Column>
                          <S.StandardButton
                            onClick={() => {
                              setEditMode(!editMode);
                              setMerchant(origMerchant);
                            }}
                          >
                            Avbryt
                          </S.StandardButton>
                        </S.Column>
                      </S.EditButtonWrapper>
                    </div>
                    <div>
                      <div style={{ marginTop: '50px', marginLeft: '50px', marginBottom: '10px' }}>
                        <h6>Kopier og lim inn denne kodesnutten og søk opp adresser fra din side</h6>
                      </div>
                      <S.WhitelabelFrame>
                        {`<iframe src="${window.location.origin}/whitelabel/${userInfo.merchantId}" style="border-width:0" allowtransparency="true"></iframe>`}
                      </S.WhitelabelFrame>
                    </div>
                  </div>
                ) : null }
            </div>

            <div style={{ marginTop: '20px' }}>
              {
                userInfo.isSolUser
                  ? <PriceAdjustTable solcellekraftData={solcellekraftData} user={userInfo} onSave={fetchSolcellekraftData} />
                  : <MerchantPriceAdjustTable merchant={merchant} user={userInfo} onSave={fetchMerchant} />
              }
            </div>

            <div style={{ marginTop: '20px' }}>
              <PriceDifferentiationTable
                merchantId={userInfoLoading ? '' : userInfo.merchantId}
                canEdit={userInfo.role === 'superuser' || userInfo.role === 'admin' || userInfo.role === 'merchant_admin'}
                countyPriceDifferent={priceDiffData}
              />
            </div>

            <S.QuoteListContainer>
              { quotesList && quotesList.quotes
            && (
            <div>
              <div style={{ backgroundColor: 'white', padding: '10px 10px 10px 20px' }}><b>Siste 15 beregninger</b></div>
              <hr style={{ marginTop: '0px', marginBottom: '0px', border: '1px solid transparent' }} />
              <div style={{ backgroundColor: 'white', padding: '20px' }}>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr style={{ borderBottom: '1px solid transparent' }}>
                      <th style={{ textAlign: 'left' }}>Dato</th>
                      <th style={{ textAlign: 'left' }}>Navn</th>
                      <th style={{ textAlign: 'left' }}>Adresse</th>
                      <th style={{ textAlign: 'left' }}>Detaljer</th>
                      <th style={{ textAlign: 'left' }}>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    { quotesList.quotes.map((q) => (
                      <tr key={q.uuid}>
                        <S.QuoteColumn>
                          <Link key={q.id} to={`/quote/${q.uuid}`}>
                            {moment(q.timestamp).format('DD.MM.YYYY HH:mm')}
                          </Link>
                        </S.QuoteColumn>
                        <S.QuoteColumn>
                          <div><b>{q.name}</b></div>
                          <div>{q.email}</div>
                          <div>{q.telephone}</div>
                        </S.QuoteColumn>
                        <S.QuoteColumn>
                          {`${q.address}, ${q.postal_code} ${q.city}`}
                        </S.QuoteColumn>
                        <S.QuoteColumn>
                          <div>
                            <b>
                              {new Intl.NumberFormat('nb').format(Math.round((q.calculations?.production?.peakPower ?? 0) * 100) / 100)}
                            </b>
                            &nbsp;kWp
                          </div>
                          <div>
                            <b>
                              {q.calculations.production?.panelCount || 0}
                            </b>
                            &nbsp;paneler
                          </div>
                          <div>
                            <b>
                              {new Intl.NumberFormat('nb').format(
                                process.env.REACT_APP_SITE === 'TAKBYTTE' ? q.calculations.roofArea : q.calculations.production.roofArea,
                              )}
                            </b>
                            &nbsp;m²
                          </div>
                        </S.QuoteColumn>
                        <S.QuoteColumn>
                          {settings.saleStatus[q.sale_status]}
                        </S.QuoteColumn>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            )}
            </S.QuoteListContainer>
          </div>
        </div>
      </ProgressWithBackDrop>
    </DashboardContainerWithMenu>
  );
};

export default DashboardPage;
