const fetchRoofByPosition = (position, settings) => {
  const body = {
    position,
    settings,
  };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_ADDRESSDATA_ENDPOINT}/roofbyposition`;
  return fetch(url, requestOptions)
    .then((response) => {
      if (response.ok) {
        return response.json()
          .then((json) => json);
      }
      return null;
    });
};

export default fetchRoofByPosition;
