import styled from 'styled-components';
import { Link } from 'react-router-dom';
import AddressSearchInputBase from '../../components/addresssearchinput/AddressSearchInput';

export const BannerImageWrapper = styled.div`
  position: relative;
  height: 25rem;
  width: 100%;
`;

export const BannerImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const BannerImageTextOverlay = styled.h1`
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: white;
`;

export const AddressSearchBanner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.beige};
  padding: 5rem 0;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    padding: 2rem 1rem;
  }
`;

export const AddressSearchInput = styled(AddressSearchInputBase)`
  margin-top: 2rem;
`;

export const SaveMoneyAndEnvironment = styled.h2`
  font-weight: 700;
  ${({ theme }) => theme.style.basePageStyle}
  display: grid;
  grid-gap: 2rem;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
`;

export const SmallSellingPointWrapper = styled.div`
  ${({ theme }) => theme.style.basePageStyle}
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  margin-bottom: 5rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
`;

export const BigSellingPointWrapper = styled.div`
  ${({ theme }) => theme.style.basePageStyle}
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
  grid-gap: 5rem;
  margin-bottom: 5rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {    
    grid-gap: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    flex-direction: column;
  }
`;

export const BigSellingPointImage = styled.img`
  width: 40%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.screen.sm}) {    
    width: 100%;
  }
`;

export const BigSellingPointLink = styled(Link)`
  color: orange;
  font-weight: 700;
  text-decoration: none;
  &:hover { color: darkorange; }
`;
