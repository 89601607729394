import styled from 'styled-components';
import TextFieldBase from '@mui/material/TextField';
import { ReactComponent as PdfFileIconBase } from '../../assets/PdfFileIcon.svg';

export const Container = styled.div`
  margin: 4rem 0;
`;

export const RoofingInfoWrapper = styled.div`
  display: grid;
  grid-template: auto / 4fr 3fr;
  grid-gap: 1rem;

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    grid-template: auto auto / auto;
  }
`;

export const RoofingInfoBanner = styled.div`
  background: ${({ theme }) => theme.color.lighterGray};
  padding: 2rem;
`;

export const RoofingInfoHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
`;

export const PdfFileIcon = styled(PdfFileIconBase)`
  width: auto;
  height: 2.5rem;
`;

export const RoofingImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const RoofingImage = styled.img`
  object-fit: contain;
  width: auto;
  max-width: 100%;
  max-height: calc(100% - 5rem);

  @media (max-width: ${({ theme }) => theme.screen.md}) {
    max-height: 25rem;
  }
`;

export const SelectRoofing = styled(TextFieldBase)`
  background: ${({ theme }) => theme.color.white};
  border-radius: 1rem;
  font-weight: 700;
  text-align: left;
  width: 100%;
  margin: 1rem 0 1rem;

  & fieldset {
    border-radius: 1rem;
    border: ${({ theme }) => `2px solid ${theme.color.black}`};
  }

  & select {
    background: ${({ theme }) => theme.color.white};
  }
  
  .MuiSelect-selectMenu {
    background: ${({ theme }) => theme.color.white};
    border-radius: 1rem;
  }
`;
