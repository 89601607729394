import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from '@sanity/block-content-to-react';
import MenuItem from '@mui/material/MenuItem';
import { LanguageContext } from '../../lib/Language';
import { SettingsContext } from '../../lib/Settings';
import Text from '../Text';
import sanityClient from '../../lib/sanityClient';
import * as S from './styles';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => builder.image(source);

const getUrlFromId = (ref) => {
  if (!ref) { return null; }
  const [, id, extension] = ref.split('-');
  return `https://cdn.sanity.io/files/${sanityClient.config().projectId}/${sanityClient.config().dataset}/${id}.${extension}`;
};

const SolarPanelSelector = ({ solarPanel, pageData, onChange, locked }) => {
  const { language } = useContext(LanguageContext);
  const settings = useContext(SettingsContext);
  const getBlock = (id) => pageData.blocks.find(({ slug }) => slug.current === id);

  const solarPanelSelectorHeaderString = getBlock('solar-panel-selector-inner-header-string')
    .string[language]
    .replace(/%\w+%/g, ((variable) => (
      { '%SOLARPANEL%': solarPanel?.title?.[language] }[variable] || variable
    )));

  return (
    <S.Container>
      <Text fontSize="xxl" bold>
        {getBlock('solar-panel-selector-header-string').string[language]}
      </Text>
      <S.PanelInfoWrapper>
        <div>
          <S.SelectPanel
            select
            disabled={locked}
            variant="outlined"
            id="roofing-option"
            label={getBlock('solar-panel-option-list-label').string[language]}
            value={solarPanel._key || ''}
          >
            {
            settings.solar.solarPanels.filter((panel) => panel.isActive)
              .map((_solarPanel = {}) => (
                <MenuItem
                  key={_solarPanel._key}
                  label={_solarPanel.title[language]}
                  value={_solarPanel._key}
                  onClick={() => onChange(_solarPanel)}
                >
                  {_solarPanel.title[language]}
                </MenuItem>
              ))
          }
          </S.SelectPanel>
          <S.PanelInfoBanner>
            <S.PanelInfoHeaderWrapper>
              <S.ArrowCircleIcon />
              <Text fontSize="lg" bold>
                {solarPanelSelectorHeaderString}
              </Text>
            </S.PanelInfoHeaderWrapper>
            <Text fontSize="md" bold>
              {solarPanel?.productName}
            </Text>
            <BlockContent
              blocks={solarPanel?.information?.[language]}
            />
            <div>
              {getBlock('solar-panel-selector-datasheet-string').string[language]}
              :
              &nbsp;
              <a href={getUrlFromId(solarPanel.datasheet?.asset?._ref)} target="_blank" rel="noopener noreferrer">
                <S.PdfFileIcon />
              </a>
            </div>
          </S.PanelInfoBanner>
        </div>
        <S.PanelImageWrapper>
          <S.PanelImage
            src={urlFor(solarPanel.image).width(350).url()}
            alt={solarPanel.productName}
          />
        </S.PanelImageWrapper>
      </S.PanelInfoWrapper>
    </S.Container>
  );
};

SolarPanelSelector.defaultProps = {
  onChange: () => {},
  solarPanel: {},
  locked: false,
};

SolarPanelSelector.propTypes = {
  solarPanel: PropTypes.shape({
    title: PropTypes.objectOf(PropTypes.string),
    _key: PropTypes.string,
    productName: PropTypes.string,
    datasheet: PropTypes.shape({
      asset: PropTypes.objectOf(PropTypes.string),
    }),
    information: PropTypes.oneOfType([
      PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
        level: PropTypes.number,
        listItem: PropTypes.string,
        style: PropTypes.string,
        _key: PropTypes.string,
        _type: PropTypes.string,
        markDefs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
        children: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      }))),
      PropTypes.shape({
        _type: PropTypes.string,
      }),
    ]),
    image: PropTypes.shape({
      asset: PropTypes.objectOf(PropTypes.string),
    }),
  }),
  pageData: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.objectOf(PropTypes.string),
      string: PropTypes.objectOf(PropTypes.string),
    })),
  }).isRequired,
  onChange: PropTypes.func,
  locked: PropTypes.bool,
};

export default SolarPanelSelector;
