import styled from 'styled-components';

export const BodyWrapper = styled.div`
  ${({ theme }) => theme.style.basePageStyle}
  margin-top: 8rem;
  display: flex;
  flex-direction: column;
`;

export const ImageWrapper = styled.div`
  background: ${({ theme }) => theme.color.beige};
  padding: 0.5rem;
`;

export const Image = styled.img`
  width: 100%;
`;

export const TextColorWrapper = styled.span`
  color: ${({
    $red,
    $green,
    $blue,
    $alpha,
  }) => `rgba(${$red}, ${$green}, ${$blue}, ${$alpha})`};
`;

export const ImageCaption = styled.h6`
  text-align: center;
  margin: 0;
  font-weight: 400;
`;
