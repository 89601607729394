import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { LanguageContext } from '../../lib/Language';
import * as S from './styles';

const QuoteStatusBar = ({
  pageData,
  paymentMethod,
  monthlyPayment,
  price,
  onButtonClick,
  onPaymentMethodChanged,
}) => {
  const { language } = useContext(LanguageContext);
  const getBlock = (id) => pageData.blocks.find(({ slug }) => slug.current === id);
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 300,
    target: window || undefined,
  });

  if (!pageData || !paymentMethod) return null;

  return (
    <Slide appear={false} direction="up" in={scrollTrigger}>
      <S.StyledAppBar $scrollTrigger={scrollTrigger}>
        <S.InnerWrapper>
          <FormControl component="fieldset">
            {/* <FormLabel component="legend">Gender</FormLabel> */}
            <S.PaymentSelectorRadioGroup
              aria-label="gender"
              name="radio-buttons-group"
              value={paymentMethod}
              onChange={onPaymentMethodChanged}
            >
              <FormControlLabel
                value="loan"
                control={<S.PaymentSelectorRadioButton />}
                label={(
                  <S.PaymentSelectorLabel>
                    {getBlock('cost-overview-loan-button-text').string[language]}
                  </S.PaymentSelectorLabel>
                )}
              />
              <FormControlLabel
                value="cash"
                control={<S.PaymentSelectorRadioButton />}
                label={(
                  <S.PaymentSelectorLabel>
                    {getBlock('cost-overview-cash-button-text').string[language]}
                  </S.PaymentSelectorLabel>
                )}
              />
            </S.PaymentSelectorRadioGroup>
          </FormControl>
          {
              paymentMethod === 'loan'
                ? (
                  <S.PaymentInfoWrapper>
                    {getBlock('status-bar-monthy-payment-text').string[language]}
                    <S.PaymentSumWrapper>
                      {(new Intl.NumberFormat(language, { style: 'currency', currency: 'NOK', maximumFractionDigits: 1 })
                        .format(Math.round(monthlyPayment))).slice(0, -1)}
                      -
                    </S.PaymentSumWrapper>
                  </S.PaymentInfoWrapper>
                ) : (
                  <S.PaymentInfoWrapper>
                    {`${getBlock('status-bar-cash-payment-text').string[language]} `}
                    <S.PaymentSumWrapper>
                      {(new Intl.NumberFormat(language, { style: 'currency', currency: 'NOK', maximumFractionDigits: 1 })
                        .format(Math.round(price))).slice(0, -1)}
                      -
                    </S.PaymentSumWrapper>
                  </S.PaymentInfoWrapper>
                )
            }
          <S.ConfirmQuoteButton onClick={onButtonClick}>
            <S.ButtonNormalText>
              {getBlock('status-bar-button-text').string[language]}
            </S.ButtonNormalText>
            <S.ButtonShortText>
              {getBlock('status-bar-button-short-text').string[language]}
            </S.ButtonShortText>
          </S.ConfirmQuoteButton>
        </S.InnerWrapper>
      </S.StyledAppBar>
    </Slide>
  );
};

QuoteStatusBar.defaultProps = {
  pageData: undefined,
  onButtonClick: () => {},
  onPaymentMethodChanged: () => {},
  paymentMethod: '',
  monthlyPayment: 0,
  price: 0,
};

QuoteStatusBar.propTypes = {
  pageData: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.objectOf(PropTypes.string),
      string: PropTypes.objectOf(PropTypes.string),
    })),
  }),
  paymentMethod: PropTypes.string,
  monthlyPayment: PropTypes.number,
  price: PropTypes.number,
  onButtonClick: PropTypes.func,
  onPaymentMethodChanged: PropTypes.func,
};

export default QuoteStatusBar;
