import React, { useContext, useEffect, useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import sanityClient from '../../lib/sanityClient';

import { LanguageContext } from '../../lib/Language';
import Page from '../../components/Page';

import * as S from './styles';

const serializers = {
  marks: {
    // eslint-disable-next-line react/prop-types
    link: ({ children, mark }) => (
      // eslint-disable-next-line react/prop-types
      <S.Link to={mark.path}>
        {children[0]}
      </S.Link>
    ),
    // eslint-disable-next-line react/prop-types
    color: ({ children, mark }) => (
      <S.TextColorWrapper
        // eslint-disable-next-line react/prop-types
        $red={mark.rgb.r}
        // eslint-disable-next-line react/prop-types
        $green={mark.rgb.g}
        // eslint-disable-next-line react/prop-types
        $blue={mark.rgb.b}
        // eslint-disable-next-line react/prop-types
        $alpha={mark.rgb.a}
      >
        {children[0]}
      </S.TextColorWrapper>
    ),
  },
};

const PrivacyPage = () => {
  const [pageData, setPageData] = useState(null);
  const { language } = useContext(LanguageContext);
  const getBlock = (id) => pageData.blocks.find(({ slug }) => slug.current === id);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "page" && slug.current == "privacy"]{
        title,
        blocks
      }`)
      .then((data) => {
        setPageData((data.length === 0) ? {} : data[0]);
      })
      .catch(console.error);
  }, []);

  if (!pageData) return <div>Loading...</div>;
  if (Object.keys(pageData).length === 0) return <div>Page data not found...</div>;

  return (
    <Page title={pageData.title[language]}>
      <S.BodyWrapper>
        <BlockContent
          blocks={getBlock('body')?.blockContent[language]}
          serializers={serializers}
        />
      </S.BodyWrapper>
    </Page>
  );
};

export default PrivacyPage;
