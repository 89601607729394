import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import imageUrlBuilder from '@sanity/image-url';
import sanityClient from '../../lib/sanityClient';
import { LanguageContext } from '../../lib/Language';
import * as S from './styles';

const builder = imageUrlBuilder(sanityClient);
const urlFor = (source) => builder.image(source);

const HomePageBanner = ({ pageData }) => {
  const { language } = useContext(LanguageContext);
  const { image = {} } = pageData.blocks?.find(({ slug }) => slug.current === 'banner-image') || {};
  const bannerText = pageData.blocks?.find(({ slug }) => slug.current === 'banner-image-text-overlay') || {};

  return (
    <S.BannerImageWrapper>
      <S.BannerImage src={urlFor(image).url()} alt="banner" />
      <S.BannerImageTextOverlay>
        {bannerText.string[language]}
      </S.BannerImageTextOverlay>
    </S.BannerImageWrapper>
  );
};

HomePageBanner.defaultProps = {
  pageData: {},
};

HomePageBanner.propTypes = {
  pageData: PropTypes.shape({
    blocks: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.shape({ current: PropTypes.string }),
    })),
  }),
};

export default HomePageBanner;
