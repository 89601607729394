import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SettingsContext } from '../../lib/Settings';

const roofMappingSolcellekraft = (buildings = [], settings) => {
  if (!settings) return [];

  const defaultSolarPanel = settings.solar.solarPanels[0];

  return buildings
    .flatMap((building) => building.roofs)
    .filter((roof) => roof.gotSpaceForSolar)
    .sort((a, b) => (b.pvgisAnnualProductionPerKwp - a.pvgisAnnualProductionPerKwp))
    .map((roof, index) => {
      const panelCountMax = Math.max(
        Math.floor(roof.availableSpace / (defaultSolarPanel.height * defaultSolarPanel.width)),
        settings.solar.solarPanelCountMinValue,
      );

      return {
        ...roof,
        isSelected: index === 0,
        panelCountMin: settings.solar.solarPanelCountMinValue,
        panelCountMax,
        panelCount: panelCountMax,
      };
    });
};

const fetchAddressDetailedInformation = async (settings, addressId, roadAddressId) => {
  const body = {
    settings,
    addressId,
    roadAddressId,
  };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  const url = `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_ADDRESSDATA_ENDPOINT}`;
  const response = await fetch(url, requestOptions);
  if (!response.ok) {
    throw new Error(`${response.status}: ${response.statusText}`);
  }
  return response?.json();
};

const roofMappingTakbytte = (buildings = []) => buildings
  .flatMap((building) => building.roofs)
  .map((roof) => ({ ...roof, isSelected: true }));

const useAddressData = (errorMsg) => {
  const settings = useContext(SettingsContext);
  const { id = '' } = useParams();
  const history = useHistory();

  const isLoaded = useRef(true);
  const merchantId = useRef(undefined);
  const [addressData, setAddressData] = useState({});
  const [roofs, setRoofs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(undefined);

  const onCloseAlert = () => {
    history.push(merchantId.current ? `/whitelabel/${merchantId.current}` : '/');
    setAlertMessage(undefined);
  };

  const onError = (error) => {
    console.error(error);
    setAlertMessage(errorMsg || error.message);
  };

  useEffect(() => {
    const [addressId, roadAddressId, ...merchantIdArr] = id.split('-');

    if (merchantIdArr.length > 1) merchantId.current = merchantIdArr.join('-');
    if (!addressId || !roadAddressId) {
      history.push('/');
    }

    if (settings && addressId && roadAddressId) {
      setLoading(true);
      fetchAddressDetailedInformation(settings, addressId, roadAddressId)
        .then((res = {}) => {
          if (isLoaded.current) {
            if (res.addressData.AdresseId) {
              setAddressData(res.addressData ?? {});
              setRoofs(process.env.REACT_APP_SITE === 'TAKBYTTE'
                ? roofMappingTakbytte(res.buildings)
                : roofMappingSolcellekraft(res.buildings, settings));

              setLoading(false);
            } else {
              onError(new Error('missing data'));
            }
          }
        })
        .catch(onError)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, !settings]);

  useEffect(() => () => {
    isLoaded.current = false;
  }, []);

  return {
    merchantId: merchantId.current,
    addressData,
    roofs,
    setRoofs,
    loading,
    alertMessage,
    onCloseAlert,
  };
};

export default useAddressData;
