import React, { useContext, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import CachedIcon from '@mui/icons-material/Cached';
import DoneIcon from '@mui/icons-material/Done';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { getValuesFromObj, replaceTagsWithValue } from '../../../lib/email/blockToHtml';
import { SettingsContext } from '../../../lib/Settings';
import * as S from './styles';

const BLANK_EMAIL = {
  key: '',
  templateName: '',
  emailSubject: '',
  emailHtml: '',
};

const SENDING_STATUS_ENUM = {
  NONE: 'NONE',
  SENDING: 'SENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const SendingButtonIcon = ({ sendingStatus }) => {
  if (sendingStatus === SENDING_STATUS_ENUM.SENDING) return <CachedIcon className="rotate" />;
  if (sendingStatus === SENDING_STATUS_ENUM.SUCCESS) return <DoneIcon />;
  return null;
};

SendingButtonIcon.propTypes = {
  sendingStatus: PropTypes.string.isRequired,
};

const SendOfferPopup = ({ isOpen, handleClose, emailTemplates = [], emailAddress, quote }) => {
  const settings = useContext(SettingsContext);
  const [, reRender] = useState(0);
  const localEmailTemplates = useRef(JSON.parse(JSON.stringify(emailTemplates)));
  const [selectedTextKey, setSelectedTextKey] = useState(localEmailTemplates.current[0]?.key ?? '');
  const [sendingStatus, setSendingStatus] = useState(SENDING_STATUS_ENUM.NONE);
  const [emailAddressLocal, setEmailAddressLocal] = useState(emailAddress);
  const selectedEmail = localEmailTemplates.current.find(({ key }) => key === selectedTextKey) || BLANK_EMAIL;
  const replacementValues = useMemo(() => getValuesFromObj(quote, settings), []);

  const onSelectChange = ({ target }) => {
    setSelectedTextKey(target.value);
  };

  const onSendEmail = async () => {
    setSendingStatus(SENDING_STATUS_ENUM.SENDING);
    const { key, emailSubject = '', emailHtml = '' } = selectedEmail || BLANK_EMAIL;

    if (!key) return;

    const mailBody = {
      toAddress: emailAddressLocal,
      subject: emailSubject,
      text: '',
      html: emailHtml,
    };

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MAIL_ENDPOINT}/send`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(mailBody),
    })
      .catch(console.error);

    if (response?.status === 200) {
      setSendingStatus(SENDING_STATUS_ENUM.SUCCESS);
      setTimeout(handleClose, 2000);
    } else {
      console.error('Feil under mailutsending');
      setSendingStatus(SENDING_STATUS_ENUM.ERROR);
    }
  };

  const onEmailSubjectChange = ({ target }) => {
    selectedEmail.emailSubject = replaceTagsWithValue(target.value, replacementValues);
    reRender((prev) => prev + 1);
  };

  const disableSendButton = sendingStatus === SENDING_STATUS_ENUM.SENDING
    || sendingStatus === SENDING_STATUS_ENUM.SUCCESS
    || !selectedEmail.key;

  return (
    <S.Dialog
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      {
        sendingStatus === SENDING_STATUS_ENUM.ERROR
          ? (
            <S.ErrorText>
              <div>Det oppsto en feil under sending av epost</div>
              <div>Vennligst prøv igjen.</div>
              <div>Kontakt IT-administrasjonen hvis problemet vedvarer.</div>
            </S.ErrorText>
          ) : null
      }
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <DialogTitle style={{ width: '10rem' }}>Send Tilbud</DialogTitle>
        <S.DialogActions style={{ marginRight: '.5rem' }}>
          <S.StandardButton onClick={handleClose}>Avbryt</S.StandardButton>
          <S.StandardButton
            disabled={disableSendButton}
            onClick={onSendEmail}
          >
            Send Tilbud
            <SendingButtonIcon sendingStatus={sendingStatus} />
          </S.StandardButton>
        </S.DialogActions>
      </div>
      <S.EmailForm>
        <span>
          <div style={{ opacity: '0.7', fontSize: '0.8rem', marginBottom: '-12px', marginTop: 0 }}>
            Velg en forhåndskrevet epost
          </div>
          <S.Select
            onChange={onSelectChange}
            style={{ width: '100%' }}
            value={selectedTextKey}
            variant="standard"
          >
            {
              localEmailTemplates.current.map(({ key, templateName }) => (
                <MenuItem key={key} value={key}>{templateName}</MenuItem>
              ))
            }
          </S.Select>
        </span>
        <TextField
          label="E-post tittel"
          value={selectedEmail.emailSubject}
          onChange={onEmailSubjectChange}
          variant="standard"
        />
        <TextField
          label="Epost Adresse"
          value={emailAddressLocal}
          onChange={({ target }) => setEmailAddressLocal(target.value || '')}
          variant="standard"
        />
      </S.EmailForm>
      <S.PopUpContainer>
        <S.Iframe title="email preview" srcDoc={selectedEmail.emailHtml} />
      </S.PopUpContainer>
    </S.Dialog>
  );
};

SendOfferPopup.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  emailTemplates: [],
  quote: {},
};

SendOfferPopup.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  emailTemplates: PropTypes.arrayOf(PropTypes.shape({
    emailSubject: PropTypes.string,
    templateName: PropTypes.string,
    html: PropTypes.string,
    key: PropTypes.string.isRequired,
  })),
  emailAddress: PropTypes.string.isRequired,
  quote: PropTypes.shape({}),
};

export default SendOfferPopup;
