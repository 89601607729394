import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SelectBase from '@mui/material/Select';
import FormControlBase from '@mui/material/FormControl';
import InputLabelBase from '@mui/material/InputLabel';
import FormHelperTextBase from '@mui/material/FormHelperText';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.color.beige};
  color: ${({ theme }) => theme.color.primaryDark};
`;

export const TotalsColumn = styled.td`
  font-weight: 300;
  font-size: 1.1rem;
`;

export const QuoteColumn = styled.td`
  border-top: 1px solid #f0ece8;
  vertical-align: baseline;
  font-weight: 300;
  font-size: 1.1rem;
`;

export const QuoteListContainer = styled.div`
  margin-top: 20px;
`;

export const MerchantName = styled.div`
  padding-left: 30px;
`;

export const MerchantWrapper = styled.div`
  padding: 20px;
  display: flex;
`;

export const MerchantField = styled.div`
  font-size: 1rem;
`;

export const EditButtonWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  padding-left: 75px;
`;

export const StandardTextField = styled(TextField)`
  background: ${({ theme }) => theme.color.beige};
  width: 15rem;
  height: 4rem;
  margin-left: 5px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  .MuiInputBase-input {
    margin-left: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: -0.5rem;
  }

  .MuiInputLabel-root {
    margin-left: 0.8rem;
    margin-top: 0.5rem;
  }

  .MuiInput-underline:before,  .MuiInput-underline:after {
    margin-bottom: -1rem;
  }
  
  .MuiFormHelperText-root{
    line-height: 0;
  }
`;

export const FormControl = styled(FormControlBase)`
  margin-top: 0.5rem;
  width: 15rem;
  margin-left: 5px;
`;

export const InputLabel = styled(InputLabelBase)`
  padding-left: 0.8rem;
`;

export const Select = styled(SelectBase)`
  font-family: 'BrandonText', serif;
  height: 31px;
  margin-top: 0.6rem;
  padding-left: 0.8rem;
`;

export const FormHelperText = styled(FormHelperTextBase)`
  margin-top: -1.1rem;  
`;

export const StandardButton = styled(Button)`
  width: 12rem;
  background-color: #f7a618;
  margin-top: 5px;
  color: white;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

export const WhitelabelFrame = styled.div`
  margin-left: 50px;
  border: 1px solid black;
  padding: 10px;
  font-size: 1rem;
`;
